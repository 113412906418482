import { OKTA_ENV } from 'features/auth/components/OktaRouter';
import env from 'utils/env';

const ENV_MAP = {
  P: 'prod',
  Q: 'int',
  D: 'dev'
};

export const NEWSPRESS_PUB_MAP = {
  WSJC: 'wsjcommerce',
  BAR: 'barrons',
  MKTW: 'mw',
  PENTAB: 'penta',
  MG: 'mansionglobal',
  WSJ: 'wsj',
  FN: 'fn',
  DJRJ: 'rn' // to match newspress origin
};

const PUB_MAP = {
  WSJC: 'wsjcommerce',
  BAR: 'barrons',
  MKTW: 'mw',
  PENTAB: 'penta',
  MG: 'mansionglobal',
  WSJ: 'wsj',
  FN: 'fn'
};

const WORDPRESS_PROD_MAP = {
  FN: 'https://cms.fnlondon.com/wp-admin/post.php',
  PN: 'https://cms.penews.com/wp-admin/post.php'
};

const WORDPRESS_NONPROD_MAP = {
  FN: 'https://int-qa-fnlondon-wordpress-cms.vir-dev.onservo.com/wp-admin/post.php',
  PN: 'https://int-qa-penews-wordpress-cms.vir-dev.onservo.com/wp-admin/post.php'
};

const getWordpressUrl = (id: string) => {
  const prefix = id.match(/^(PN|FN)/) as Array<string>;
  const postId = id.replace(/^(PN|FN)/, '');
  const baseUrl =
    env.OKTA_ENV === 'prd'
      ? WORDPRESS_PROD_MAP[prefix[0] as keyof typeof WORDPRESS_PROD_MAP]
      : WORDPRESS_NONPROD_MAP[prefix[0] as keyof typeof WORDPRESS_NONPROD_MAP];
  return `${baseUrl}?post=${postId}&action=edit`;
};

export const getArticleEnvFromId = (id: string) => {
  const envPrefix = id.split('-')[0];
  return ENV_MAP[envPrefix[1] as keyof typeof ENV_MAP];
};

export const getPublicationFromId = (id: string, map: Record<string, string>) => {
  const publicationAbbr = id.split('-')[1];
  return map[publicationAbbr];
};

const buildArticleNewspressUrl = (id?: string) => {
  if (id?.match(/W[PQD]-/)) {
    const longPostId = id.split('-')[2];
    const publication = getPublicationFromId(id, NEWSPRESS_PUB_MAP);
    const articleEnv = getArticleEnvFromId(id);
    const postId = Number(longPostId);
    if (!publication || !articleEnv || !postId) {
      return null;
    }

    if (articleEnv === ENV_MAP.P && env.OKTA_ENV !== 'prd') {
      return null;
    }

    return `https://newspress${
      articleEnv === ENV_MAP.P ? '' : `.${articleEnv}`
    }.dowjones.io/${publication}/wp-admin/post.php?post=${postId}&action=edit&readonly=true`;
  }

  if (id?.match(/^(PN|FN)/)) return getWordpressUrl(id);

  return null;
};

const NP_PC_ID_PATTERN = {
  BUYSIDE: 'CP-WSJ-'
};

// TODO: make compatible with other publications
const buildProductCardNewspressUrl = (id?: string) => {
  if (id?.match(NP_PC_ID_PATTERN.BUYSIDE)) {
    const pcId = id.replace(NP_PC_ID_PATTERN.BUYSIDE, '');
    return `https://commerce-products.${env.OKTA_ENV === 'prd' ? '' : 'dev.'}dowjones.io/buyside/${pcId}`;
  }
  console.warn('Product card ID is invalid. Please adjust the function to support non-buyside publications.');

  return null;
};

// strip IM- from the narrator id (IM-3d850f07-635c-4b3c-b4b1-8bafd89d7052)
const buildNarratorUrl = (id?: string) => `https://narrator.wsj.dowjones.io/project/${id?.substring(3)}`;

export const buildNewspressUrl = (id?: string, contentType?: string) => {
  if (id?.startsWith('IM-')) return buildNarratorUrl(id);
  switch (contentType) {
    case 'commerceproduct':
      return buildProductCardNewspressUrl(id);
    default:
      return buildArticleNewspressUrl(id);
  }
};

export const buildNewsgridUrl = (id: string) => {
  const articleEnv = getArticleEnvFromId(id);
  const publication = getPublicationFromId(id, PUB_MAP);
  if (!publication || !articleEnv || (env.OKTA_ENV !== 'prd' && articleEnv === ENV_MAP.P)) {
    return null;
  }

  return `https://newsgrid${articleEnv === 'prod' ? '' : `.${articleEnv}`}.dowjones.io/article/${id}`;
};
export const getLCArticleEnv = (sourceUrl: string) => {
  let articleUrl: URL;
  try {
    articleUrl = new URL(sourceUrl);
  } catch (e) {
    console.warn(`Invalid URL ${sourceUrl}, cannot determine article environment.`);
    return null;
  }
  let articleEnv = 'prod';
  if (articleUrl.origin.toLowerCase().includes('dev')) {
    articleEnv = 'dev';
  }
  if (articleUrl.origin.toLowerCase().includes('int')) {
    articleEnv = 'int';
  }
  return articleEnv;
};

export const buildLiveCoverageBaseUrl = (sourceUrl: string, source: string) => {
  const articleEnv = getLCArticleEnv(sourceUrl);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (!sourceUrl || !articleEnv || (env.OKTA_ENV !== OKTA_ENV.PRD && articleEnv === ENV_MAP.P)) {
    return null;
  }
  return `https://livecoverage${articleEnv === 'prod' ? '' : `.${articleEnv}`}.dowjones.io/${source}/news/event/`;
};

export const getLCBrand = (product: string) => {
  let brand;
  if (product.toLowerCase().includes('wsj')) {
    brand = 'wsj';
  } else if (product.toLowerCase().includes('marketwatch')) {
    brand = 'marketwatch';
  } else if (product.toLowerCase().includes('barrons')) {
    brand = 'barrons';
  } else if (product.toLowerCase().includes('djrj')) {
    brand = 'djriskjournal';
  } else {
    brand = '';
  }
  return brand;
};

export const buildLiveCoverageUrl = (sourceUrl: string, product: string) => {
  const brand = getLCBrand(product);
  const baseUrl = buildLiveCoverageBaseUrl(sourceUrl, brand);
  if (!baseUrl) return null;
  const eventId = sourceUrl.split('/')[sourceUrl.split('/').length - 1];
  return `${baseUrl}${eventId}`;
};

export const buildLiveCoverageUrlSettings = (sourceUrl: string, product: string) => {
  const liveCoverageUrl = buildLiveCoverageUrl(sourceUrl, product);

  if (liveCoverageUrl) {
    return `${liveCoverageUrl}/settings`;
  }
  return null;
};

export const appEnv = env.OKTA_ENV;
