import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { BannerDto, GetAllBannersQuery } from 'data/generated/graphql';
import { useElementWidth } from 'hooks/dom';
import styles from './BannerSearch.module.scss';
import Card from './Card';

interface ContentListProps {
  pages: GetAllBannersQuery[];
  nextPageProps: {
    fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<GetAllBannersQuery>>;
    hasNextPage?: boolean;
    isFetchingNextPage: boolean;
  };
}

const ContentList = ({ pages, nextPageProps }: ContentListProps) => {
  const { elementRef, elementWidth } = useElementWidth();

  if (pages.length === 0 || pages[0].getBanners.edges.length === 0) {
    return <NoSearchResults title="Banners" bodyText="" />;
  }

  const bannersList = pages.reduce((acc: BannerDto[], curr: GetAllBannersQuery) => {
    const bannerNode = curr.getBanners.edges.map((edges) => {
      const { node } = edges;
      return node as BannerDto;
    });

    return acc.concat(bannerNode);
  }, []);

  return (
    <div ref={elementRef} className={styles.list} data-testid="banner-list">
      {bannersList.map((banner) => (
        <Card key={banner.metadata.allessehId} banner={banner} containerWidth={elementWidth} />
      ))}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
    </div>
  );
};
export default ContentList;
