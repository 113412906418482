import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconPlusCircle, Typography, Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { useAlert } from 'contexts/alert/useAlert';
import { PagesInput, useCreateWsjSectionFrontPageMutation, useInfinitePagesDtoQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { useIsAdmin } from 'hooks/roles';
import { PagesList, PagesSearchBar } from './components';
import { AddSectionPageInput, AddSectionPageModal } from './components/add-section-page-modal/AddSectionPageModal';

const PER_PAGE = 20;

export const Pages = () => {
  const currentProperty = useConvertedProperty();
  const { alertError } = useAlert();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const [pagesInput, setPagesInput] = useState<PagesInput>({
    publicationKey: ''
  });
  const [isNewSectionPageModalOpen, setIsNewSectionPageModalOpen] = useState(false);
  const [newSectionPageState, setNewSectionPageState] = useState<AddSectionPageInput>({ name: '', routeKey: '' });

  const {
    data,
    isLoading: isLoad,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    fetchStatus
  } = useInfinitePagesDtoQuery(
    {
      pagesInput,
      paginationParams: { first: PER_PAGE, after: null }
    },
    {
      enabled: !!pagesInput.publicationKey && pagesInput.publicationKey.length > 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.pagesDTO.pageInfo.hasNextPage) return false;
        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.pagesDTO.pageInfo.endCursor
          }
        };
      }
    }
  );
  const isLoading = isLoad && fetchStatus !== 'idle';

  const { mutateAsync: createWsjSectionMutateAsync, isLoading: isCreatingSection } =
    useCreateWsjSectionFrontPageMutation();

  useEffect(() => {
    if (!currentProperty) {
      return;
    }

    setPagesInput((pagesInput) => ({ ...pagesInput, publicationKey: currentProperty }));
  }, [currentProperty]);

  const handlePartialPagesInputChange = (newSearchParams: Partial<PagesInput>) => {
    setPagesInput((pagesInput) => ({ ...pagesInput, ...newSearchParams }));
  };

  const handleAddSectionPage = () => {
    setIsNewSectionPageModalOpen(true);
  };

  const handleSectionPageCreate = async () => {
    if (!currentProperty) return;

    try {
      const {
        createWSJSectionFrontPage: {
          metadata: { allessehId }
        }
      } = await createWsjSectionMutateAsync({
        wsjSectionFrontInput: {
          publicationKey: currentProperty,
          name: newSectionPageState.name,
          routeKey: newSectionPageState.routeKey
        }
      });

      await refetch();

      setNewSectionPageState({ name: '', routeKey: '' });
      navigate(`/${currentProperty}/pages/${allessehId}`);
    } catch (e: unknown) {
      const error = e as Error;
      alertError(error.message, { autoDismiss: true });
    }
  };

  const nextPageProps = { fetchNextPage, hasNextPage, isFetchingNextPage };

  return (
    <Wrapper margin={{ top: 'lg' }}>
      <Typography variant="header2" data-testid="pages-heading">
        All Pages
      </Typography>
      <PagesSearchBar pagesInput={pagesInput} onPartialPagesInputChange={handlePartialPagesInputChange} />
      {isAdmin && currentProperty === 'wsj' && (
        <Button tertiary icon={IconPlusCircle as SvgComponent} onClick={handleAddSectionPage}>
          Add Section Page
        </Button>
      )}
      {isLoading || !data ? <ResponsiveLoader /> : <PagesList pagePages={data.pages} />}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
      {(isNewSectionPageModalOpen || isCreatingSection) && (
        <AddSectionPageModal
          sectionPageData={newSectionPageState}
          setSectionPageData={setNewSectionPageState}
          isCreatingSection={isCreatingSection}
          onCreate={handleSectionPageCreate}
          onDismiss={() => setIsNewSectionPageModalOpen(false)}
        />
      )}
    </Wrapper>
  );
};
