/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Combobox, IconGear, Wrapper } from '@screentone/core';

import { useContentIdTracking } from 'contexts/content-id-tracking/useContentIdTracking';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { PublicationSettingSearchableContentType, QueryItem, TreatmentTypeSetting } from 'data/generated/graphql';
import { QueryModal } from 'features/section-page-edit/components/page-draft-section/components/query-modal/QueryModal';
import { usePublicationSettings } from 'hooks/publication-settings';
import {
  AllessehContent,
  AllessehContentQueryBody,
  AllessehQueryRuleTerm,
  AllessehQueryRuleTerms,
  DEFAULT_TOPICS_QUERY,
  useAllessehContentQuery
} from 'hooks/useAllessehContentQuery';
import { TopicsResponse, useTopicsQuery } from 'hooks/useTopicsQuery';
import { BaseQueryOrigin, getBaseQuery } from 'utils/contentType';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import { safelyParseContent } from 'utils/temp';
import styles from './Feed.module.scss';
import { QueryProps } from '../Query';

enum BarronsTerms {
  SubjectCode = 'SubjectCode',
  BarronsDisplayBrand = 'BarronsDisplayBrand'
}

const DEFAULT_TOPIC = {
  url: '',
  seoDisplayName: '',
  djmlKeyword: '',
  subject: false,
  brand: false
};

interface FeedProps extends QueryProps {
  hierarchyId: string;
  isHistory: boolean;
  treatmentTypeSetting?: TreatmentTypeSetting;
  // modifyEntity: (hierarchyId: string, callback: (entity: QueryItem) => void) => void;
}

// TODO: this component is very barrons specific, when more feeds are added, this should be refactored
export const Feed = ({ data: query, hierarchyId, isHistory, onContentLoaded, treatmentTypeSetting }: FeedProps) => {
  const parsedQuery: AllessehContentQueryBody = query.attributes.query;
  const [filteredOptions, setFilteredOptions] = useState<TopicsResponse[]>([]);
  const [option, setOption] = useState<TopicsResponse>(DEFAULT_TOPIC);
  const { data: topicsData } = useTopicsQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { modifyEntity } = useDataModelContext();
  const { data: publicationSettingsResp } = usePublicationSettings();
  const baseQuery = getBaseQuery({
    contentTypes: [PublicationSettingSearchableContentType.Article],
    publicationSettings: publicationSettingsResp?.publicationSetting,
    origin: BaseQueryOrigin.QUERY_BUILDER
  });

  const fullAllessehQuery = mergeAllessehQueryBodies(baseQuery, JSON.stringify(query.attributes.query));
  const { data: queryResult } = useAllessehContentQuery(fullAllessehQuery, { enabled: true });

  const queryIds = useMemo(
    () =>
      queryResult
        ? queryResult.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x): x is AllessehContent => !!x)
            .map((allessehContent) => allessehContent.data.id)
        : [],
    [queryResult]
  );

  const { trackContentIds, ignoreContentIds } = useContentIdTracking();

  useEffect(() => {
    trackContentIds(hierarchyId, queryIds);

    return () => {
      ignoreContentIds(hierarchyId, queryIds);
    };
  }, [hierarchyId, queryIds, trackContentIds, ignoreContentIds]);

  // const hierarchyId = generateHierarchyId(query, parentHierarchyId, index);
  useEffect(() => {
    if (topicsData) {
      setFilteredOptions(topicsData);
      const option = parsedQuery.query?.and?.find((item) => {
        const termsItem = item as AllessehQueryRuleTerms;
        const termItem = item as AllessehQueryRuleTerm;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (termItem.term) {
          return [BarronsTerms.SubjectCode, BarronsTerms.BarronsDisplayBrand].includes(
            termItem.term.key as BarronsTerms
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (termsItem.terms) {
          return [BarronsTerms.SubjectCode, BarronsTerms.BarronsDisplayBrand].includes(
            termsItem.terms.key as BarronsTerms
          );
        }
        return null;
      });
      setOption(
        topicsData.find((topic) => {
          if (option) {
            if ('term' in option) {
              return option.term.value === topic.djmlKeyword;
            }
            if ('terms' in option) {
              return option.terms.value.join(',') === topic.djmlKeyword;
            }
          }
          return null;
        }) ?? topicsData[0]
      );
    }
  }, [parsedQuery, parsedQuery.query?.and, topicsData]);

  const handleChange = (selectedItem: { id: string; value: string; label: string }) => {
    const selectedOption = filteredOptions.find(
      (option) => option.djmlKeyword === selectedItem.value
    ) as TopicsResponse;
    setOption(selectedOption);
    const parsedTopicsQuery: AllessehContentQueryBody = safelyParseContent(DEFAULT_TOPICS_QUERY);
    const termKey = selectedOption.brand ? BarronsTerms.BarronsDisplayBrand : BarronsTerms.SubjectCode;
    parsedTopicsQuery.query?.and?.push({ terms: { key: termKey, value: selectedItem.value.split(',') } });
    const newJsonQueryStr = JSON.stringify(parsedTopicsQuery);
    modifyEntity(hierarchyId, (entity) => {
      const query = entity as QueryItem;
      query.attributes.isFeed = true;
      query.attributes.feedTitle = selectedOption.seoDisplayName;
      query.attributes.feedUrl = selectedOption.url;
      query.attributes.query = JSON.parse(newJsonQueryStr) as Record<string, unknown>;
      // default count to 5 as we don't have a way rn to determine the count of items in a feed
      query.attributes.query.count = 5;
    });
  };

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(0, treatmentTypeSetting?.maxRequiredContentItems ?? 0);
    }
  }, [onContentLoaded, treatmentTypeSetting?.maxRequiredContentItems]);

  const allessehJsonQuery = useMemo(
    () =>
      typeof query.attributes.query === 'string' ? query.attributes.query : JSON.stringify(query.attributes.query),
    [query.attributes.query]
  );

  const handleChangeJsonQueryStr = useCallback(
    (newJsonQueryStr: string) => {
      modifyEntity(hierarchyId, (entity) => {
        const query = entity as QueryItem;
        query.attributes.query = JSON.parse(newJsonQueryStr) as Record<string, unknown>;
      });
    },
    [hierarchyId, modifyEntity]
  );

  const items = useMemo(
    () =>
      filteredOptions.map((option) => ({
        id: option.djmlKeyword,
        value: option.djmlKeyword,
        label: option.seoDisplayName
      })),
    [filteredOptions]
  );

  return (
    <>
      <Box data-model-hierarchy-id={hierarchyId} className={styles.cardContainer}>
        <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader}>
          {items.length > 0 && (
            <Combobox
              placeholder="Select a Feed"
              selectedItem={{ id: option.djmlKeyword, value: option.djmlKeyword, label: option.seoDisplayName }}
              onSelectedItemChange={handleChange}
              disabled={isHistory || items.length === 0}
              items={items}
              showReset={false}
              status={items.length > 0 ? 'ready' : 'loading'}
            />
          )}
          {items.length === 0 && option.seoDisplayName}
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            tertiary
            margin={{ left: 'sm' }}
            icon={IconGear as SvgComponent}
          />
        </Wrapper>
      </Box>
      {isModalOpen && (
        <QueryModal
          queryItemHierarchyId={hierarchyId}
          allessehJsonQuery={allessehJsonQuery}
          excludePageContentIdsQuery=""
          onDismiss={() => setIsModalOpen(false)}
          onChangeJsonQueryStr={handleChangeJsonQueryStr}
          count={5}
          baseQuery={baseQuery}
          queryRuleDisabledFlags={{ all: true, sort: true }}
          queryRuleHiddenFlags={{ any: true, sort: true }}
          queryRuleFilterKey={{ not: ['UpstreamOriginId'] }}
          viewOnly={isHistory}
        />
      )}
    </>
  );
};
