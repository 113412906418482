import { useAppInfiniteQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';

export const DEFAULT_QUERY_JSON = '{ "sort": [{"key":"PublishedDate","order":"desc"}] }';

export const DEFAULT_TOPICS_QUERY = '{ "query": {"and": []},"sort": [{"key":"PublishedDate","order":"desc"}] }';

export enum AllessehQuerySortKeyTypes {
  Relevance = 'Relevance',
  RawRelevance = 'RawRelevance',
  LiveDate = 'LiveDate',
  PrintPublicationDate = 'PrintPublicationDate',
  PublishedDate = 'PublishedDate',
  UpdatedDate = 'UpdatedDate',
  EmbargoDate = 'EmbargoDate'
}

export enum AllessehQuerySortOrderType {
  ASC = 'asc',
  DESC = 'desc'
}

export interface AllessehQuerySortRule {
  key: AllessehQuerySortKeyTypes;
  order: AllessehQuerySortOrderType;
}

export interface AllessehContentItemAttributes {
  type: string;
  page: string;
  section_name: string;
  section_type: string;
  type_display_name: string;
  headline?: { text: string };
  associations?: {
    root_id?: string;
    slug?: string;
  };
  first_paragraph?: {
    content: { text: string }[];
  };
  standfirst?: {
    type?: string;
    content?: { text: string }[];
  };
  summary?: {
    content?: { content?: { text: string }[] }[];
  };
  alt_summaries?: {
    variant?: string;
    properties?: {};
    content?: {
      type: string;
      ref?: string;
      hed_type?: string;
      inset_type?: string;
      content?: {
        type?: string;
        inset_type?: string;
        text?: string;
        content?: { text: string; inset_type?: string; ref?: string }[];
      }[];
      properties?: {
        'reuse-type'?: string;
        url?: string;
        location?: string;
        fallback_image?: {
          display_variants: {
            default: string;
            narrow: {
              url: string;
            };
            full: {
              url: string;
            };
          };
        };
      };
    }[];
  }[];
  published_datetime: string;
  updated_datetime_utc?: string;
  embargo_datetime_utc?: string;
  source_url: string;
  product: string;
  seo_id: string;
  base_doc_id: string;
  canonical_url: string;
  content_status: string;
  upstream_origin?: string;
}

export interface AllessehContentData {
  id: string;
  type: string;
  attributes: AllessehContentItemAttributes;
}

export interface AllessehContentErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}

export interface AllessehContent {
  data: AllessehContentData;
  links?: {
    related?: {
      id?: string;
      name?: string;
      properties?: {
        'reuse-type'?: string;
        location?: string;
        fallback?: {
          type: string;
          link_url: string;
          media: {
            size: number;
          };
          url: string;
        };
        responsive?: {};
        fallback_image?: {
          display_variants: {
            default: string;
            narrow: {
              url: string;
            };
            full: {
              url: string;
            };
          };
        };
      };
      type: string;
      uri?: string;
      media_type?: string;
      inset_type?: string;
    }[];
  };
  response?: AllessehContentErrorResponse;
}

export interface AllessehContentQueryResponse {
  data?: {
    attributes: AllessehContent[];
    type: string;
  };
  links?: {
    next?: string;
    total: number;
  };
  meta?: {
    aggregations: { term: string; values: { value: string; count: number }[] }[];
  };
}

export interface AllessehQueryRuleTerm {
  term: {
    key: string;
    value: string;
  };
}
export interface AllessehQueryRuleTerms {
  terms: {
    key: string;
    value: string[];
  };
}
interface AllessehQueryRuleDate {
  date: {
    key: string;
    value: string;
    operand: string;
  };
}
interface AllessehQueryRuleGroup {
  group: {
    name: string;
  };
}
export enum AllessehFullTextNameTypes {
  Body = 'Body',
  Headline = 'Headline',
  Keywords = 'Keywords',
  Byline = 'Byline'
}

export interface AllessehQueryRuleFullText {
  full_text: {
    value: string;
    parameters: { property: AllessehFullTextNameTypes }[];
  };
}
// TODO: add support for query, numeric_term, prefix, ticker, code_with_significance
export type AllessehQueryRule =
  | AllessehQueryRuleTerm
  | AllessehQueryRuleTerms
  | AllessehQueryRuleDate
  | AllessehQueryRuleGroup
  | AllessehQueryRuleFullText;

export interface AllessehQueryRules {
  and?: AllessehQueryRule[];
  or?: AllessehQueryRule[];
  not?: AllessehQueryRule[];
}

export interface AllessehContentQueryBody {
  count?: number;
  query?: AllessehQueryRules;
  sort?: AllessehQuerySortRule[];
  aggregations?: string[];
}

const getAllessehContentQuery = (
  authToken: string,
  allessehContentQueryBody: AllessehContentQueryBody,
  page: number,
  ignoreEmbargo?: boolean
) =>
  restClient.post<AllessehContentQueryResponse>('allesseh/content', allessehContentQueryBody, {
    headers: {
      Authorization: authToken
    },
    params: {
      page,
      ignoreEmbargo
    }
  });

export const useAllessehContentQuery = (
  allessehContentQueryBody: AllessehContentQueryBody,
  options?: { enabled: boolean; ignoreEmbargoCheck?: boolean; refreshTimestamp?: number }
) => {
  const authToken = useAuthToken();

  return useAppInfiniteQuery(
    [allessehContentQueryBody, options?.refreshTimestamp],
    ({ pageParam = 0 }: { pageParam?: number }) =>
      getAllessehContentQuery(authToken, allessehContentQueryBody, pageParam, options?.ignoreEmbargoCheck),
    {
      ...options,
      getNextPageParam: (data: AllessehContentQueryResponse) => {
        const { links } = data;

        if (links?.next) {
          const pageNumber = links.next.split('=').pop();
          if (pageNumber) {
            return parseInt(pageNumber, 10);
          }
        }

        return undefined;
      }
    }
  );
};
