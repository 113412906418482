import { useState } from 'react';
import { Tabs as TabsComponent, Wrapper } from '@screentone/core';

import { PageContentSearch } from 'features/section-page-edit/components/page-search-section/page-content-search/PageContentSearch';
import styles from './ItpMultitabSection.module.scss';
import ItpPagesList from './ItpPagesList';
import ItpSettings from './ItpSettings';

enum Tabs {
  Sections,
  Settings,
  ContentSearch
}

export const ItpMultitabSection = () => {
  const [tabIndex, setTabIndex] = useState(Tabs.Sections);

  return (
    <>
      <Wrapper>
        <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
          <TabsComponent.Item
            role="tab"
            id="tab-id0"
            aria-selected={tabIndex === Tabs.Sections ? 'true' : 'false'}
            aria-controls="tabpanel-id0"
            data-testid="itp-section-tab"
          >
            Sections
          </TabsComponent.Item>
          <TabsComponent.Item
            role="tab"
            id="tab-id1"
            aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
            aria-controls="tabpanel-id1"
            data-testid="itp-settings-tab"
          >
            Settings
          </TabsComponent.Item>
          <TabsComponent.Item
            role="tab"
            id="tab-id2"
            aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
            aria-controls="tabpanel-id2"
            data-testid="itp-content-search-tab"
          >
            Content Search
          </TabsComponent.Item>
        </TabsComponent>
        {tabIndex === Tabs.Sections && (
          <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
            <ItpPagesList />
          </Wrapper>
        )}
        {tabIndex === Tabs.Settings && (
          <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
            <ItpSettings />
          </Wrapper>
        )}
        {tabIndex === Tabs.ContentSearch && (
          <Wrapper id="tabpanel-id2" role="tabpanel" aria-labelledby="tab-id2" padding={{ top: 'sm' }}>
            <PageContentSearch
              resultListClassName={styles.searchResultScrollable}
              showFilters={{ author: true, contentType: true, date: true, status: false }}
            />
          </Wrapper>
        )}
      </Wrapper>
    </>
  );
};
