import { useMemo } from 'react';
import { Divider, Wrapper } from '@screentone/core';

import { WidePageWrapper } from 'components';
import { CopyPublishedURL } from 'components/context-menu-actions/copy-published-url/CopyPublishedURL';
import { EditAltSumm } from 'components/context-menu-actions/edit-alt-summ/EditAltSumm';
import ExternalLink, { ExternalLinkContentType } from 'components/context-menu-actions/external-link/ExternalLink';
import { OpenInNewsGrid } from 'components/context-menu-actions/open-in-newsgrid/OpenInNewsGrid';
import { OpenInNewsPress } from 'components/context-menu-actions/open-in-newspress/OpenInNewsPress';
import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useItp } from 'contexts/itp/useItp';
import { ArticleItem, IssueContainer, ItpDto } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { usePrompt } from 'hooks/useBeforeLeavePage';
import { safelyParseContent } from 'utils/temp';
import { ItpMultitabSection } from './components/itp-multitab-section/ItpMultitabSection';
import { ItpTitleHeader } from './components/itp-title-header/ItpTitleHeader';
import styles from './ItpEdit.module.scss';

export const ItpEdit = () => {
  const { getDTO, readHierarchyId, renderEntity, root } = useDataModelContext<IssueContainer>();
  const { hasAltSummChanged, hasItpDTOChanged } = useItp();

  const contextActions = useMemo<MenuActions>(
    () => ({
      [MenuActionsKeys.DraftArticle]: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      [MenuActionsKeys.DraftModule]: [
        {
          component: ({ hierarchyId }) => (
            // delete '_module' suffix of hierarchyId to delete the page, not the module
            <RemoveEntity hierarchyId={hierarchyId!.replace(/_module-\d$/, '')} text="Remove module" />
          ),
          showIf: ({ hierarchyId }) => {
            if (!hierarchyId) return false;

            const [, section] = readHierarchyId(hierarchyId);
            const ItpDTO = getDTO() as ItpDto;
            const numberOfPagesInCurrentSection = ItpDTO.root.collection[section.index].collection.length;

            return numberOfPagesInCurrentSection > 1;
          },
          withDivider: 'top'
        }
      ],
      [MenuActionsKeys.ArticleSearch]: [
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ]
    }),
    [getDTO, readHierarchyId]
  );

  usePrompt(
    `You have ${hasAltSummChanged ? 'unpublished ITP Alt summ' : 'unsaved'} changes. Are you sure you want to leave?`,
    hasItpDTOChanged
  );

  const draftSection = renderEntity(root, { hierarchyId: '' });

  return (
    <PageRootContainer>
      <WidePageWrapper padding={{ all: 'md' }}>
        <ContextMenuActionsProvider actions={contextActions}>
          <ItpTitleHeader data-testid="itp-title" />
          <Divider className={styles.horizontalDivider} />
          <Wrapper margin={{ top: 'sm' }}>
            <div className={styles.pageEditRow}>
              <div className={styles.pageEditDraft}>
                <Wrapper className={styles.draftContainer}>{draftSection}</Wrapper>
              </div>
              <div className={styles.pageDivider} />
              <div className={styles.pageEditSearch}>
                <ItpMultitabSection />
              </div>
            </div>
          </Wrapper>
        </ContextMenuActionsProvider>
      </WidePageWrapper>
    </PageRootContainer>
  );
};
