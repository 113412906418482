import { Button, IconTrash, Wrapper } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { addExcludedContentToQuery } from 'utils/collection';

interface ExcludeFromQueryProps {
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const ExcludeFromQuery = ({ jsonQueryStr, allessehContent, setJsonQueryStr }: ExcludeFromQueryProps) => {
  const handleExclude = () => {
    if (jsonQueryStr) {
      const parsedJsonQuery = addExcludedContentToQuery(allessehContent.data.id, jsonQueryStr);
      setJsonQueryStr(JSON.stringify(parsedJsonQuery));
    }
  };

  return (
    <Wrapper>
      <Button
        tertiary
        icon={IconTrash as SvgComponent}
        color="lava"
        onClick={handleExclude}
        data-testid="page-content-card-exclude-query-button"
      >
        Exclude from Query
      </Button>
    </Wrapper>
  );
};
