import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { ItpFeed } from 'features/itp/ItpFeed';

const ItpFeedPage = () => (
  <PageRootContainer>
    <NarrowPageWrapper>
      <ItpFeed />
    </NarrowPageWrapper>
  </PageRootContainer>
);

export default ItpFeedPage;
