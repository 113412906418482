import { ReactNode } from 'react';
import { Button, Dialog, FormHelperText, FormLabel, Input, Loader, useModalPortal } from '@screentone/core';
import cloneDeep from 'lodash/cloneDeep';

import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useItp } from 'contexts/itp/useItp';
import { IssueSectionContainer, ItpDto } from 'data/generated/graphql';
import styles from './NameChangeDialog.module.scss';

interface NameChangeDialogProps {
  closeModal: () => void;
  open: boolean;
  section: IssueSectionContainer;
  sectionLabel: string | null | undefined;
  setSectionLabel: (newItpPageLabel: string | null | undefined) => void;
}

export const NameChangeDialog = ({
  closeModal,
  open,
  section,
  sectionLabel,
  setSectionLabel
}: NameChangeDialogProps) => {
  const { alertSuccess, alertError } = useAlert();
  const { root } = useDataModelContext();
  const { isSaveLoading, saveIssue } = useItp();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { renderNode }: { renderNode: ReactNode } = useModalPortal(); // eslint-disable-line @typescript-eslint/no-unsafe-call

  const itpRoot = root as ItpDto['root'];

  const labelIsValid = !!sectionLabel;

  return (
    <Dialog
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDesc"
      onDismiss={closeModal}
      renderNode={renderNode}
      status={open ? 'open' : 'closed'}
    >
      <Dialog.Title id="dialogTitle">Change ITP Page Display Name</Dialog.Title>
      <Dialog.Content id="dialogDesc">
        <FormLabel label="Display Name" className={styles.formLabel} data-testid="itp-display-name-label">
          <Input
            type="text"
            value={sectionLabel}
            error={!labelIsValid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSectionLabel(e.target.value)}
            data-testid="itp-display-name-input"
          />
        </FormLabel>
        {!labelIsValid && <FormHelperText error>The Display Name must be at least one character long.</FormHelperText>}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          secondary
          onClick={() => {
            setSectionLabel(section.attributes.sectionLabel);
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={async () => {
            if (!sectionLabel) {
              alertError('Page Label cannot be blank');
              return;
            }

            const updatedRoot = cloneDeep(itpRoot);
            updatedRoot.collection.forEach((sect) => {
              if (sect.attributes.sectionLabel === section.attributes.sectionLabel) {
                /* eslint-disable no-param-reassign */
                sect.attributes.sectionLabel = sectionLabel;
              }
            });

            await saveIssue({ root: updatedRoot })
              .then(() => alertSuccess('Update successful'))
              .catch((err) => {
                console.error(err);
                alertError('Something went wrong. Name not updated');
              })
              .finally(closeModal);
          }}
          disabled={isSaveLoading || !labelIsValid}
          data-testid="itp-change-name-save"
        >
          {isSaveLoading ? <Loader size="md" /> : 'Save'}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
