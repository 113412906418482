import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { cloneDeep } from 'lodash';

import { IssueError } from 'contexts/alert/AlertContext';
import { formatAlertMessage, useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useHighlight } from 'contexts/highlight/useHighlight';
import {
  BaseMetadata,
  ItpDto,
  useDefaultItpIssueStateQuery,
  useDeleteItpDtoMutation,
  usePreviewItpDtoLinkMutation,
  usePublishItpDtoMutation,
  useSaveItpDtoMutation
} from 'data/generated/graphql';
import { DISPLAY_KEY } from './itpSortOrder';
import { formatRequestBody } from './mutationUtils';

const noop = async () => {
  // do nothing
};

type IssueInput = { metadata?: ItpDto['metadata']; root?: ItpDto['root'] };

type UpdateOptions = {
  updatedUtc?: boolean;
  updateRevisorUser?: boolean;
};

interface ItpContextState {
  clearErrorState: () => void;
  deleteIssue: () => Promise<void>;
  handlePreviewPage: () => void;
  defaultItpIssueState: ItpDto | null;
  defaultItpIssueStateError: unknown;
  hasAltSummChanged: boolean;
  hasItpDTOChanged: boolean;
  isDeleteLoading: boolean;
  isPreviewLoading: boolean;
  isPublishLoading: boolean;
  isSaveLoading: boolean;
  previewIds: string[];
  publishIssue: () => Promise<void>;
  saveIssue: (updatedIssue?: IssueInput) => Promise<void>;
  setHasAltSummChanged: (hasChanged: boolean) => void;
  setHasItpDTOChanged: (hasChanged: boolean) => void;
  setPreviewIds: (ids: string[]) => void;
  updateIssue: (issue: IssueInput, updateOptions?: UpdateOptions) => void;
}

const DEFAULT_ITP_STATE: ItpContextState = {
  clearErrorState: noop,
  deleteIssue: noop,
  handlePreviewPage: noop,
  defaultItpIssueState: null,
  defaultItpIssueStateError: '',
  hasAltSummChanged: false,
  hasItpDTOChanged: false,
  isDeleteLoading: false,
  isPreviewLoading: false,
  isPublishLoading: false,
  isSaveLoading: false,
  previewIds: [],
  publishIssue: noop,
  saveIssue: noop,
  setHasAltSummChanged: noop,
  setHasItpDTOChanged: noop,
  setPreviewIds: noop,
  updateIssue: noop
};

export const ItpContext = createContext<ItpContextState>(DEFAULT_ITP_STATE);

export { DISPLAY_KEY };

interface ItpProviderProps {
  children: React.ReactNode;
  hasItpDTOChanged: boolean;
  setHasItpDTOChanged(hasChanged: boolean): void;
}

const sortIdsByOrder = (ids: string[]) => {
  const sectionNameRegex = /UCS(D|P)_[A-Z_]+_ITP_ITP\d{8}_(.*?)_$/;

  const defaultOrder = 100;
  return ids.sort((id1, id2) => {
    const sectionName1 = (id1.match(sectionNameRegex) ?? [])[2];
    const sectionName2 = (id2.match(sectionNameRegex) ?? [])[2];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const order1 = DISPLAY_KEY[sectionName1] ? DISPLAY_KEY[sectionName1].order : defaultOrder;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const order2 = DISPLAY_KEY[sectionName2] ? DISPLAY_KEY[sectionName2].order : defaultOrder;
    return order1 - order2;
  });
};

export const ItpProvider = ({ children, hasItpDTOChanged, setHasItpDTOChanged }: ItpProviderProps) => {
  const [hasAltSummChanged, setHasAltSummChanged] = useState(false);
  const [previewIds, setPreviewIds] = useState<string[]>([]);
  const [defaultItpIssueState, setDefaultItpIssueState] = useState<ItpDto | null>(null);
  const [defaultItpIssueStateError, setDefaultItpIssueStateError] = useState<unknown>(null);

  const { currentProperty, user } = useAuth();
  const { alertSuccess, alertError, removeAlert, alertIssueModules } = useAlert();
  const { getDTO, setNewMetadata, setNewRoot } = useDataModelContext();
  const { clearItems } = useHighlight();
  const navigate = useNavigate();

  const itpDto = getDTO() as ItpDto;

  const {
    isLoading: defaultItpStateDataLoading,
    data: defaultItpStateData,
    error: defaultItpStateDataError,
    fetchStatus: defaultItpStateDataFetchStatus
  } = useDefaultItpIssueStateQuery({
    publicationKey: currentProperty ?? 'wsj',
    issueDate: itpDto.root.attributes.issueDate
  });
  const { mutateAsync: deleteItpDtoAsync, isLoading: isDeleteLoading } = useDeleteItpDtoMutation();
  const { mutateAsync: previewItpDtoAsync, isLoading: isPreviewLoading } = usePreviewItpDtoLinkMutation();
  const { mutateAsync: publishItpDtoAsync, isLoading: isPublishLoading } = usePublishItpDtoMutation();
  const { mutateAsync: saveItpDtoAsync, isLoading: isSaveLoading } = useSaveItpDtoMutation();

  useEffect(() => {
    if (!defaultItpStateDataLoading && defaultItpStateDataFetchStatus === 'idle') {
      if (defaultItpStateDataError || !defaultItpStateData?.getDefaultITPIssueState) {
        setDefaultItpIssueStateError(defaultItpStateDataError ?? '500 - Failed to fetch default pages.');
      } else {
        const defaultState = defaultItpStateData.getDefaultITPIssueState as ItpDto;
        setDefaultItpIssueState(defaultState);
      }
    }
  }, [defaultItpStateDataLoading, defaultItpStateDataFetchStatus, defaultItpStateData, defaultItpStateDataError]);

  const updateIssue = useCallback(
    (updatedIssue: IssueInput, updateOptions?: UpdateOptions) => {
      const updatedIssueCopy = cloneDeep(updatedIssue);

      if (updatedIssueCopy.root) {
        // sort sections
        updatedIssueCopy.root.collection.sort((a, b) => {
          const aOrder = a.attributes.sectionKey ? DISPLAY_KEY[a.attributes.sectionKey].order : -1;
          const bOrder = b.attributes.sectionKey ? DISPLAY_KEY[b.attributes.sectionKey].order : -1;
          return aOrder - bOrder;
        });
      }

      if (updatedIssueCopy.metadata) {
        updatedIssueCopy.metadata.updatedUtc = updateOptions?.updatedUtc
          ? Date.now()
          : updatedIssueCopy.metadata.updatedUtc;

        if (updateOptions?.updateRevisorUser && user?.dj_user_id) {
          updatedIssueCopy.metadata.revisorUser = { djUserId: user.dj_user_id as string };
        } else if (updatedIssueCopy.metadata.revisorUser) {
          updatedIssueCopy.metadata.revisorUser = {
            djUserId: updatedIssueCopy.metadata.revisorUser.djUserId
          };
        } else if (itpDto.metadata.revisorUser?.djUserId) {
          updatedIssueCopy.metadata.revisorUser = {
            djUserId: itpDto.metadata.revisorUser.djUserId
          };
        }
      }

      if (updatedIssueCopy.metadata) setNewMetadata(updatedIssueCopy.metadata as BaseMetadata);
      if (updatedIssueCopy.root) setNewRoot(updatedIssueCopy.root);
      setHasItpDTOChanged(true);
    },
    [itpDto.metadata.revisorUser, setHasItpDTOChanged, setNewMetadata, setNewRoot, user?.dj_user_id]
  );

  const clearErrorState = useCallback(() => {
    removeAlert();

    // TODO: create util to make this suck less
    updateIssue({
      root: {
        ...itpDto.root,
        collection: itpDto.root.collection.map((section) => ({
          ...section,
          collection: section.collection.map((page) => ({
            ...page,
            collection: page.collection.map((module) => ({
              ...module,
              attributes: {
                ...module.attributes,
                pageModule: {
                  ...module.attributes.pageModule,
                  hasValidationError: false
                }
              }
            }))
          }))
        }))
      } as ItpDto['root']
    });
  }, [itpDto.root, removeAlert, updateIssue]);

  const saveOrPublishIssue = useCallback(
    async (isSave: boolean, updatedIssue: IssueInput = {}) => {
      const issueToSave = { ...itpDto, ...updatedIssue };

      clearItems(); // Clear highlighted items.
      clearErrorState();

      const requestBody = formatRequestBody(issueToSave, currentProperty);

      try {
        const response = await (isSave ? saveItpDtoAsync(requestBody) : publishItpDtoAsync(requestBody));
        const itpResponse = ('saveItpDTO' in response ? response.saveItpDTO : response.publishItpDTO) as ItpDto;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        itpResponse.metadata.revisorUser = { djUserId: user?.dj_user_id };
        itpResponse.root.collection.forEach((section) => {
          section.collection.forEach((page) => {
            page.collection.forEach((module) => {
              if (module.attributes.pageModule) {
                // eslint-disable-next-line no-param-reassign
                module.attributes.pageModule.hasValidationError = false;
              }
            });
          });
        });

        updateIssue(itpResponse, { updatedUtc: true });

        if (isSave) alertSuccess('Draft successfully saved');
        else alertSuccess('Issue successfully published');

        setHasItpDTOChanged(false);
      } catch (e) {
        const ERROR_MESSAGE = 'Could not publish the issue.';
        if (e instanceof Error) {
          if (e.message.startsWith('ISSUE_VALIDATION_ERROR#')) {
            const errorJson = e.message.replace('ISSUE_VALIDATION_ERROR#', '');
            const pageError = JSON.parse(errorJson) as IssueError;
            alertError(`${formatAlertMessage(pageError)}`, { wrapNewlines: true });
            alertIssueModules(pageError.validationError!);
          } else {
            alertError(`${ERROR_MESSAGE} ${e.message}`);
          }
        } else {
          alertError(ERROR_MESSAGE);
        }
      }
      setHasItpDTOChanged(false);
    },
    [
      alertError,
      alertIssueModules,
      alertSuccess,
      clearErrorState,
      clearItems,
      currentProperty,
      itpDto,
      publishItpDtoAsync,
      saveItpDtoAsync,
      setHasItpDTOChanged,
      updateIssue,
      user?.dj_user_id
    ]
  );

  // either save the issue we currently have on state, or provide and updated issue to be saved
  const saveIssue = useCallback(
    async (updatedIssue?: IssueInput) => {
      await saveOrPublishIssue(true, updatedIssue);
    },
    [saveOrPublishIssue]
  );

  const publishIssue = useCallback(async () => {
    await saveOrPublishIssue(false);
    setHasAltSummChanged(false);
  }, [saveOrPublishIssue]);

  const deleteIssue = useCallback(async () => {
    const deleteResponse = await deleteItpDtoAsync({
      allessehId: itpDto.metadata.allessehId,
      publicationKey: currentProperty ?? 'wsj'
    });

    const isDeleted = deleteResponse.deleteItpDTO;

    if (isDeleted) {
      navigate(`/${currentProperty}/issues`);
      alertSuccess('Issue deleted successfully');
    } else {
      throw new Error('Failed to delete issue');
    }
    setHasItpDTOChanged(false);
  }, [alertSuccess, currentProperty, deleteItpDtoAsync, itpDto.metadata.allessehId, navigate, setHasItpDTOChanged]);

  const handlePreviewPage = useCallback(async () => {
    const ERROR_MESSAGE = 'Could not set up previewing for the issue.';

    try {
      const requestBody = formatRequestBody(itpDto, currentProperty);
      const response = await previewItpDtoAsync(requestBody);

      if (response.previewItpDTOLink) {
        const sortedIds = sortIdsByOrder(response.previewItpDTOLink);

        setPreviewIds(sortedIds);
        setHasItpDTOChanged(false);
        updateIssue(itpDto, { updatedUtc: true, updateRevisorUser: true });
      } else {
        // if no preview link is returned, throw an error to go into the catch block
        throw new Error();
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('ISSUE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('ISSUE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as IssueError;
          alertError(`${formatAlertMessage(pageError)}`, { wrapNewlines: true });
          alertIssueModules(pageError.validationError!);
        } else {
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }

      setPreviewIds([]);
    }
  }, [alertError, alertIssueModules, currentProperty, itpDto, previewItpDtoAsync, setHasItpDTOChanged, updateIssue]);

  const value: ItpContextState = {
    clearErrorState,
    deleteIssue,
    handlePreviewPage,
    defaultItpIssueState,
    defaultItpIssueStateError,
    hasAltSummChanged,
    hasItpDTOChanged,
    isDeleteLoading,
    isPreviewLoading,
    isPublishLoading,
    isSaveLoading,
    previewIds,
    publishIssue,
    saveIssue,
    setHasAltSummChanged,
    setHasItpDTOChanged,
    setPreviewIds,
    updateIssue
  };

  return <ItpContext.Provider value={value}>{children}</ItpContext.Provider>;
};
