import { Button, IconArrowDown, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { removeExcludedContentFromQuery } from 'utils/collection';

interface MoveManualArticleToQueryArticleProps {
  hierarchyId: string;
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const MoveManualArticleToQueryArticle = ({
  hierarchyId,
  jsonQueryStr,
  allessehContent,
  setJsonQueryStr
}: MoveManualArticleToQueryArticleProps) => {
  const { removeEntity } = useDataModelContext();

  const handleReinclude = () => {
    const parsedJsonQuery = removeExcludedContentFromQuery(allessehContent.data.id, jsonQueryStr);
    setJsonQueryStr(JSON.stringify(parsedJsonQuery));
  };

  const handleMove = () => {
    handleReinclude();
    removeEntity(hierarchyId);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconArrowDown as SvgComponent} onClick={handleMove}>
        Remove from Top
      </Button>
    </Wrapper>
  );
};
