/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  Button,
  Dropdown,
  Group,
  IconArticle,
  IconRefresh,
  IconThreeDotsVer,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { OffPlatformListContainer } from 'data/generated/graphql';
import { useElementDimensions } from 'hooks/dom/useElementDimensions';
import styles from './OffPlatform.module.scss';

interface OffPlatformProps extends CommonEntityProps {
  data: OffPlatformListContainer;
  isHistory: boolean;
}

const sumBeforeIndex = (items: number[], index: number) =>
  items.reduce(
    (acc: number, curr: number, numContentItemsIndex: number) => (numContentItemsIndex < index ? acc + curr : acc),
    0
  );

const OffPlatform = ({ data, parentHierarchyId, index, isHistory }: OffPlatformProps) => {
  const [isOverflown, setIsOverflown] = useState(false);
  const [articlesPerItem, setArticlesPerItem] = useState<number[]>(() => data.collection.map(() => 1));

  const { generateHierarchyId, renderEntity, setNewRoot } = useDataModelContext();
  const { renderActions } = useContextMenuActions();

  const { elementRef, dimensions } = useElementDimensions<HTMLDivElement>();

  const hierarchyId = generateHierarchyId(data, parentHierarchyId, index);
  const numTotalItemsUsed = useMemo(
    () => sumBeforeIndex(articlesPerItem, data.collection.length),
    [articlesPerItem, data.collection]
  );

  useEffect(() => {
    if (!elementRef.current) return;
    const maxHeightStr = getComputedStyle(elementRef.current).maxHeight.replace('px', '');
    const maxHeight = Number(maxHeightStr);
    setIsOverflown(dimensions.scrollHeight > maxHeight);
  }, [dimensions.scrollHeight, elementRef]);

  const handleContentLoad = useCallback((index: number, contentLength: number) => {
    setArticlesPerItem((prevItems) => {
      const newLength = [...prevItems];
      newLength[index] = contentLength;
      return newLength;
    });
  }, []);

  return (
    <>
      {!isHistory && (
        <Typography variant="header3" margin={{ bottom: 'md' }} data-testid="off-platform-new-header">
          New
        </Typography>
      )}
      <Wrapper margin={{ bottom: 'md' }} data-testid="off-platform-container">
        <div
          data-testid="off-platform-items-scrollable"
          className={classnames(styles.itemsScrollable, {
            [styles.draftItems]: !isHistory,
            [styles.wrapperOverflow]: isOverflown
          })}
          ref={elementRef}
        >
          <Group margin={{ bottom: 'xs' }} align="space-between">
            <Typography weight="bold" size="sm" margin={{ bottom: 'sm' }} data-testid="off-platform-display-name">
              {data.attributes.moduleDisplayName}
            </Typography>
            {!isHistory && (
              <Dropdown padding={{ all: 'none' }} position="right" trigger={<IconThreeDotsVer color="asphalt" />}>
                {renderActions(MenuActionsKeys.DraftModule, {
                  entity: data,
                  index,
                  extraProperties: { numTotalItemsUsed, maxAllowedItems: data.attributes.maxItems }
                })}
              </Dropdown>
            )}
          </Group>
          <Droppable
            droppableId={(isHistory ? DROPPABLE_ID_PREFIXES.HISTORY : DROPPABLE_ID_PREFIXES.OFF_PLATFORM) + hierarchyId}
            isDropDisabled={isHistory}
          >
            {(droppableProvided) => (
              <div
                className={classnames(styles.itemsList, {
                  [styles.draftItemsList]: !isHistory
                })}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {data.collection.map((item, mapIndex) => {
                  const articleHierarchyId = generateHierarchyId(item, hierarchyId, mapIndex);
                  const availableContentSlots = data.attributes.maxItems - sumBeforeIndex(articlesPerItem, mapIndex);

                  return (
                    <div key={mapIndex}>
                      {renderEntity(item, {
                        hierarchyId,
                        index: mapIndex,
                        isHistory,
                        extraProps: {
                          content: { availableContentSlots, onContentLoaded: handleContentLoad, isDraft: !isHistory },
                          article: {
                            renderActions: () =>
                              renderActions(isHistory ? MenuActionsKeys.HistoryArticle : MenuActionsKeys.DraftArticle, {
                                hierarchyId: articleHierarchyId,
                                index: mapIndex,
                                entity: item
                              })
                          }
                        }
                      })}
                    </div>
                  );
                })}
                {droppableProvided.placeholder}
                {numTotalItemsUsed !== data.attributes.maxItems && !isHistory && (
                  <Wrapper
                    className={classnames(styles.itemsNumber, {
                      [styles.itemsNumberWithContent]: numTotalItemsUsed > 0
                    })}
                    padding={{ all: 'md' }}
                  >
                    <IconArticle color="asphalt" margin={{ right: 'xs' }} />
                    <Typography variant="note" data-testid="off-platform-items-count">
                      {numTotalItemsUsed}/{data.attributes.maxItems} articles
                    </Typography>
                  </Wrapper>
                )}
              </div>
            )}
          </Droppable>
        </div>
      </Wrapper>
      {!isHistory && (
        <Group align="end">
          <Button
            data-testid="off-platform-reset-to-default"
            tertiary
            icon={IconRefresh as SvgComponent}
            color="lava"
            onClick={() => {
              setNewRoot({ ...data, collection: [] });
            }}
          >
            Reset to default
          </Button>
        </Group>
      )}
    </>
  );
};

export default OffPlatform;
