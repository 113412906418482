import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar, { calendarHooks } from '@screentone/addon-calendar';
import { Box, Button, Group, IconPlusCircle, Typography, Wrapper } from '@screentone/core';
import { addDays, isAfter, isSameDay, isSunday } from 'date-fns';

import { ResponsiveLoader } from 'components';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { useAlert } from 'contexts/alert/useAlert';
import { ItpDto, useCreateItpDtoMutation, useLastSevenItpDtOsQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { itpIssueDateToJSDate, jsDateToITPIssueDate } from 'utils/dates';
import { IssueList, ItpDtoFeed } from './components/issue-list/IssueList';

function calculateNextQualifiedDate(date: Date): Date {
  let nextDate = addDays(date, 1); // add one day

  if (isSunday(nextDate)) {
    // add another day if next date is a sunday
    nextDate = addDays(nextDate, 1);
  }

  return nextDate;
}

function calculateMinDate(date: Date): Date {
  if (isSunday(date)) {
    return addDays(date, 1);
  }
  return date;
}

export const ItpFeed = () => {
  const minDate = useRef(calculateMinDate(new Date(Date.now())));

  const [issues, setIssues] = useState<ItpDtoFeed[]>([]);
  const [nextQualifiedDate, setNextQualifiedDate] = useState(minDate.current);

  const { alertError, removeAlert } = useAlert();
  const currentProperty = useConvertedProperty();
  const { mutateAsync: issueCreateAsync } = useCreateItpDtoMutation();
  const { onSelect, selectedDate } = calendarHooks.useDate();
  const { data, isLoading: isLoad, fetchStatus } = useLastSevenItpDtOsQuery({ publicationKey: currentProperty ?? '' });
  const navigate = useNavigate();

  useLayoutEffect(() => {
    onSelect(nextQualifiedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQualifiedDate]);

  useEffect(() => {
    if (data?.lastSevenItpDTOs) {
      const latestIssueDate = itpIssueDateToJSDate(data.lastSevenItpDTOs[0]?.root.attributes.issueDate);

      setIssues(data.lastSevenItpDTOs as ItpDto[]);

      if (isAfter(latestIssueDate, minDate.current) || isSameDay(latestIssueDate, minDate.current)) {
        // Only set next qualified date if latest issue date is after or equal to the min date
        setNextQualifiedDate(calculateNextQualifiedDate(latestIssueDate));
      }
    }
  }, [data?.lastSevenItpDTOs]);

  if (currentProperty !== 'wsj') {
    return (
      <NoSearchResults
        title="ITP Issues"
        bodyText="This is a WSJ feature that is unavailable for other publications."
      />
    );
  }

  const handleDateSelect = (date: Date) => {
    removeAlert();
    onSelect(date);
  };

  const handleAddNewIssueClick = async () => {
    if (!selectedDate) return;

    const issueDate = jsDateToITPIssueDate(selectedDate);
    const itpDTOCreateInput = { issueDate };

    try {
      // First, create the ITP issue
      const { createItpDTO: newIssue } = await issueCreateAsync({
        itpDTOCreateInput,
        publicationKey: currentProperty
      });

      setIssues((prev) => [{ ...newIssue, isNew: true } as ItpDtoFeed, ...prev]);
      navigate(`/${currentProperty}/issues/${issueDate}`);
    } catch (e: unknown) {
      const error = e as Error;
      alertError(error.message);
    }
  };

  const isLoading = isLoad && fetchStatus !== 'idle';
  if (isLoading) {
    return <ResponsiveLoader />;
  }

  return (
    <Wrapper margin={{ top: 'lg' }}>
      <Typography variant="header2">ITP Issues</Typography>
      <Box padding={{ all: 'sm' }} margin={{ bottom: 'mlg' }}>
        <Group align="space-between" valign="end">
          <Calendar
            align="left"
            onSelect={handleDateSelect}
            selectedDate={selectedDate}
            placeholder="Select an issue date"
            dateFormat="MMMM dd, yyyy"
          />
          <Button
            icon={IconPlusCircle as SvgComponent}
            margin={{ bottom: 'sm' }}
            onClick={handleAddNewIssueClick}
            tertiary
            data-testid="itp-add-new-issue-button"
          >
            Add new issue
          </Button>
        </Group>
      </Box>
      {issues.length > 0 ? (
        <IssueList issues={issues} />
      ) : (
        <NoSearchResults title="ITP Issues" bodyText="No issues have been created yet." />
      )}
    </Wrapper>
  );
};
