import { useEffect, useState } from 'react';
import { Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { GetBannersDtoInput, useInfiniteGetAllBannersQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import styles from './BannerSearch.module.scss';
import ContentList from './ContentList';

const PER_PAGE = 10;

const BannerSearch = () => {
  const [bannersDTOInput, setBannersDTOInput] = useState<GetBannersDtoInput>({ publicationKey: '' });

  const currentProperty = useConvertedProperty();

  useEffect(() => {
    if (currentProperty) {
      setBannersDTOInput({ publicationKey: currentProperty });
    }
  }, [currentProperty]);

  const { data, isLoading, fetchStatus, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteGetAllBannersQuery(
      {
        bannersDTOInput,
        paginationParams: { first: PER_PAGE, after: null }
      },
      {
        enabled: !!bannersDTOInput.publicationKey,
        getNextPageParam: (lastPage) => {
          if (!lastPage.getBanners.pageInfo.hasNextPage) {
            return false;
          }

          return {
            paginationParams: {
              first: PER_PAGE,
              after: lastPage.getBanners.pageInfo.endCursor
            }
          };
        }
      }
    );

  return (
    <Wrapper className={styles.bannerWrapper} data-testid="banner-list-container">
      {isLoading && fetchStatus !== 'idle' ? (
        <ResponsiveLoader />
      ) : (
        <ContentList pages={data?.pages ?? []} nextPageProps={{ fetchNextPage, hasNextPage, isFetchingNextPage }} />
      )}
    </Wrapper>
  );
};

export default BannerSearch;
