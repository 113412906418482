import { useMemo, useState } from 'react';
import { Alert, Button, IconError, IconTrash, Loader, Token, Typography, Wrapper } from '@screentone/core';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';

import { ConfirmationDialog } from 'components';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useItp } from 'contexts/itp/useItp';
import { IssueContainer, ItpIssueStatus } from 'data/generated/graphql';
import { ITP_STATUS_COLOR } from 'utils/constants';
import { itpIssueDateToJSDate } from 'utils/dates';
import { getItpCurrentStatus } from 'utils/itp';
import { ItpPreviewDropdown } from './ItpPreviewDropdown';
import styles from './ItpTitleHeader.module.scss';

export const ItpTitleHeader = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const { root, metadata } = useDataModelContext<IssueContainer>();
  const {
    deleteIssue,
    hasItpDTOChanged,
    hasAltSummChanged,
    isDeleteLoading,
    isPublishLoading,
    isSaveLoading,
    publishIssue,
    saveIssue
  } = useItp();

  const { allessehId, publishUtc } = metadata;
  const { issueDate, status } = root.attributes;

  const issueStatus = status as ItpIssueStatus;
  const isPublished = !!publishUtc;

  let reformattedIssueDate = '';

  if (issueDate) {
    const issueDateAsDateObj = itpIssueDateToJSDate(issueDate);
    reformattedIssueDate = format(issueDateAsDateObj, 'MMMM dd, yyyy');
  }

  const currentIssueStatus = useMemo(() => getItpCurrentStatus(issueStatus), [issueStatus]);

  return (
    <>
      <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.titleHeader}>
        <div className={styles.row}>
          <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="itp-issue-date-label">
            {reformattedIssueDate}
          </Typography>
          <Token color={ITP_STATUS_COLOR[currentIssueStatus]}>{capitalize(currentIssueStatus)}</Token>
        </div>
        <div className={styles.row}>
          <ExternalLink contentId={allessehId} isPublished={isPublished} type={ExternalLinkContentType.COLLECTION} />
          {issueStatus === ItpIssueStatus.Changed && (
            <Alert type="warning" icon={IconError as SvgComponent} inline data-testid="itp-unpublished-changes-label">
              Unpublished {hasAltSummChanged && 'Alt Summ'} changes
            </Alert>
          )}
          <ItpPreviewDropdown />
          {!isPublished && (
            <Button
              color="lava"
              data-testid="itp-delete-button"
              icon={IconTrash as SvgComponent}
              onClick={() => setShowDeleteModal(true)}
              tertiary
            >
              Delete
            </Button>
          )}
          <Button
            data-testid="itp-save-draft-button"
            disabled={isSaveLoading || isPublishLoading || !hasItpDTOChanged}
            onClick={() => saveIssue()}
            secondary
          >
            {isSaveLoading ? <Loader size="md" /> : 'Save Draft'}
          </Button>
          <Button
            data-testid="itp-publish-button"
            disabled={isPublishLoading || isSaveLoading}
            onClick={() => setShowPublishModal(true)}
            primary
          >
            {isPublishLoading ? <Loader size="md" /> : 'Publish'}
          </Button>
        </div>
      </Wrapper>
      {showDeleteModal && (
        <ConfirmationDialog
          description="Are you sure you want to delete this issue?"
          isLoading={isDeleteLoading}
          onClickFunc={async () => {
            await deleteIssue();
            setShowDeleteModal(false);
          }}
          showDialog={setShowDeleteModal}
          title="Delete issue"
        />
      )}
      {showPublishModal && (
        <ConfirmationDialog
          description="Are you sure you're ready to publish to the platform?"
          isLoading={isPublishLoading}
          onClickFunc={async () => {
            await publishIssue();
            setShowPublishModal(false);
          }}
          showDialog={setShowPublishModal}
          title="Publish issue"
        />
      )}
    </>
  );
};
