/* eslint-disable import/no-cycle */
import { Wrapper } from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { IssueContainer } from 'data/generated/graphql';

interface IssueProps extends CommonEntityProps {
  data: IssueContainer;
}

export const Issue = ({ data: issue, parentHierarchyId, index }: IssueProps) => {
  const { generateHierarchyId, renderEntity } = useDataModelContext();
  const hierarchyId = generateHierarchyId(issue, parentHierarchyId, index);

  return (
    <Wrapper data-model-hierarchy-id={hierarchyId} padding={{ top: 'md' }}>
      {issue.collection.map((collection, i) => {
        if (collection.attributes.isHidden) return null;

        return <div key={i}>{renderEntity(collection, { hierarchyId, index: i })}</div>;
      })}
    </Wrapper>
  );
};
