import { useState } from 'react';
import { calendarHooks } from '@screentone/addon-calendar';
import { Button, Wrapper } from '@screentone/core';
import isEqual from 'lodash.isequal';

import { FilterDate } from 'components';
import { AuthorFilter } from 'components/search-content/components/search-content-filters/components/author-filter/AuthorFilter';
import { ContentTypeFilterType } from 'components/search-content/components/search-content-filters/components/content-type-filter/ContentTypeFilter';
import { InputMaybe, Scalars } from 'data/generated/graphql';
import { ContentTypeFilterContainer } from './components/content-type-filter-container/ContentTypeFilterContainer';
import { ContentStatusFilterType, StatusFilter } from './components/status-filter/StatusFilter';
import styles from './SearchContentFiltersContainer.module.scss';

export type DateType = InputMaybe<Scalars['Float']>;

export type ShowFilterOptions = { contentType?: boolean; author?: boolean; date?: boolean; status?: boolean };

export interface SearchContentFilters {
  [key: string]: any;
  contentTypes: ContentTypeFilterType[];
  author: string;
  embargo?: boolean;
  publishDates: {
    afterUtc?: DateType;
    beforeUtc?: DateType;
  };
  contentStatuses: Set<ContentStatusFilterType>;
  excludedContentIds?: string[];
  sectionType?: string;
}

interface SearchContentFiltersProps {
  defaultFilterState: SearchContentFilters;
  filters: SearchContentFilters;
  onChangeFilters: (filters: Partial<SearchContentFilters>) => void;
  showFilters?: ShowFilterOptions;
}

const SearchContentFiltersContainer = ({
  defaultFilterState,
  filters,
  onChangeFilters,
  showFilters
}: SearchContentFiltersProps) => {
  const { startDate, endDate, onSelect } = calendarHooks.useDateRange();
  const [filtersSet, setFiltersSet] = useState(false);

  const showContentTypeFilter = showFilters?.contentType ?? true;
  const showAuthorFilter = showFilters?.author ?? true;
  const showDateFilter = showFilters?.date ?? true;
  const showStatusFilter = showFilters?.status ?? true;

  const handleChangeContentType = (contentTypes: SearchContentFilters['contentTypes']) => {
    onChangeFilters({ contentTypes });
  };

  const handleOwnerChange = (author: SearchContentFilters['author']) => {
    onChangeFilters({ author });
    setFiltersSet(true);
  };

  const handleDatesChange = (afterUtc?: DateType | null, beforeUtc?: DateType | null) => {
    onChangeFilters({ publishDates: { afterUtc, beforeUtc } });
    setFiltersSet(true);
  };

  const handleDateFilterClear = () => {
    onSelect({ endDate: null, startDate: null });
    handleDatesChange(null, null);
    if (!filters.author) {
      setFiltersSet(false);
    }
  };

  const handleChangeContentStatus = (contentStatus: ContentStatusFilterType) => {
    if (filters.contentStatuses.has(contentStatus)) {
      filters.contentStatuses.delete(contentStatus);
    } else {
      filters.contentStatuses.add(contentStatus);
    }

    onChangeFilters({ contentStatuses: new Set(filters.contentStatuses) });
  };

  const filtersAreAtDefaultState = isEqual(filters, defaultFilterState);
  return (
    <Wrapper
      data-testid="search-content-filters-container"
      padding={{ top: 'sm', bottom: 'md' }}
      className={styles.container}
    >
      {showContentTypeFilter && (
        <ContentTypeFilterContainer
          onChange={handleChangeContentType}
          setFiltersSet={setFiltersSet}
          values={filters.contentTypes}
        />
      )}
      {showAuthorFilter && <AuthorFilter onChange={handleOwnerChange} author={filters.author} />}
      {showDateFilter && (
        <FilterDate
          startDate={startDate}
          endDate={endDate}
          label="Date"
          onSelect={onSelect}
          onChange={handleDatesChange}
          handleDateFilterClear={handleDateFilterClear}
        />
      )}
      {showStatusFilter && (
        <StatusFilter
          values={filters.contentStatuses}
          handleChange={handleChangeContentStatus}
          isActive={
            (filters.contentStatuses.size === 1 && !filters.contentStatuses.has('PUBLISHED')) ||
            filters.contentStatuses.size > 1
          }
        />
      )}
      {filtersSet && !filtersAreAtDefaultState && (
        <Button
          data-testid="search-content-filters-reset-button"
          active
          tertiary
          color="gray"
          onClick={() => {
            onChangeFilters(defaultFilterState);
            setFiltersSet(false);
            onSelect({ endDate: null, startDate: null });
          }}
        >
          Reset
        </Button>
      )}
    </Wrapper>
  );
};

export default SearchContentFiltersContainer;
