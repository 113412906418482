/* eslint-disable import/no-cycle */
import { ReactElement, useCallback } from 'react';
import { Button, Dropdown, Group, IconCaretDown, Typography, useDropdownState } from '@screentone/core';
import classnames from 'classnames';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { BannerModuleContainer, ModuleContainer, PageModule, PageTypeSetting } from 'data/generated/graphql';
import {
  getModuleFieldKey,
  getSubsetTreatmentTypeSettings,
  getTreatmentType,
  useModuleTreatmentTypes
} from 'utils/modules';
import styles from './TreatmentTypeDropdown.module.scss';

interface TreatmentDropdownProps {
  hierarchyId: string;
  module: ModuleContainer | BannerModuleContainer;
  pageModule: PageModule;
  showCurrentTreatmentTypeAtTheTop?: boolean;
  viewOnly?: boolean;
  pageTypeSetting?: PageTypeSetting;
}

export const TreatmentTypeDropdown = ({
  module,
  pageModule,
  hierarchyId,
  showCurrentTreatmentTypeAtTheTop,
  viewOnly,
  pageTypeSetting
}: TreatmentDropdownProps) => {
  const treatmentTypeSettings = pageTypeSetting?.treatmentTypeSettings;
  const { changeEntity } = useDataModelContext();

  const onChangeNewModule = useCallback(
    (newModule: ModuleContainer | BannerModuleContainer) => {
      changeEntity(hierarchyId, () => newModule);
    },
    [hierarchyId, changeEntity]
  );

  const moduleFieldKey = getModuleFieldKey(pageModule.uiModuleType);
  const subsetTreatmentTypeSettings = getSubsetTreatmentTypeSettings(pageModule.uiModuleType, treatmentTypeSettings);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { open, setOpen, componentRef }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();

  const { handleChangeTreatmentByValue, allowedTreatmentTypeSettings } = useModuleTreatmentTypes({
    moduleFieldKey,
    onChangeNewModule,
    moduleContainer: module,
    subsetTreatmentTypeSettings
  });

  const treatmentType = getTreatmentType(pageModule);

  const treatmentTypeSetting = treatmentTypeSettings?.filter(
    (t) => t.treatmentType === treatmentType || t.treatmentTypeKey === treatmentType
  )[0];

  if (showCurrentTreatmentTypeAtTheTop) {
    const currentTreatmentType = pageModule.uiModuleFields[moduleFieldKey]?.treatmentType;

    const currTreatmentTypeIndex = allowedTreatmentTypeSettings?.findIndex(
      (treatmentTypeSetting) =>
        (treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType) ===
        (currentTreatmentType as string)
    );

    allowedTreatmentTypeSettings?.unshift(allowedTreatmentTypeSettings[currTreatmentTypeIndex!]);
    allowedTreatmentTypeSettings?.splice(currTreatmentTypeIndex! + 1, 1);
  }
  const disabled = allowedTreatmentTypeSettings?.length === 1 || viewOnly;
  const handleDropdownOpenToggle = () => {
    if (!disabled) setOpen(!open);
  };

  return disabled ? (
    <Typography variant="label3" margin={{ bottom: 'none' }}>
      {treatmentTypeSetting?.label}
    </Typography>
  ) : (
    <Dropdown
      open={open}
      onToggle={handleDropdownOpenToggle}
      componentRef={componentRef}
      data-testid="treatment-type-label"
    >
      <Dropdown.Trigger>
        <Group
          direction="row"
          gap="xs"
          className={classnames(`st_focusable ${styles.trigger}}`)}
          componentEl="button"
          data-testid="treatment-type-dropdown"
        >
          <Typography color="blurple" variant="note">
            {treatmentTypeSetting?.label}
          </Typography>
          <IconCaretDown color="blurple" />
        </Group>
      </Dropdown.Trigger>
      (
      <Dropdown.Content padding={{ all: 'sm' }}>
        <Group direction="column" gap="xs">
          {allowedTreatmentTypeSettings?.map((treatmentTypeSetting) => (
            <Button
              fullWidth
              tertiary
              key={treatmentTypeSetting.treatmentType}
              active={
                treatmentTypeSetting.treatmentTypeKey === treatmentType ||
                treatmentTypeSetting.treatmentType === treatmentType
              }
              disabled={
                treatmentTypeSetting.treatmentTypeKey === treatmentType ||
                treatmentTypeSetting.treatmentType === treatmentType
              }
              onClick={() => {
                setOpen(false);
                handleChangeTreatmentByValue(
                  treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType
                );
              }}
            >
              {treatmentTypeSetting.label}
            </Button>
          ))}
        </Group>
      </Dropdown.Content>
      )
    </Dropdown>
  );
};
