import { ItpDto, PageModule } from 'data/generated/graphql';

export const formatRequestBody = (issue: ItpDto, currentProperty: string | null) => ({
  itpDTOSaveInput: {
    allessehId: issue.metadata.allessehId,
    issueDate: issue.root.attributes.issueDate,
    refreshedUtc: issue.metadata.refreshedUtc,
    sections: issue.root.collection.map((section) => ({
      adPlacementType: section.attributes.adPlacementType,
      adZone: section.attributes.adZone,
      isHidden: section.attributes.isHidden,
      issueDate: section.attributes.issueDate ?? issue.root.attributes.issueDate,
      pageType: section.attributes.pageType,
      routeKey: section.attributes.routeKey,
      sectionKey: section.attributes.sectionKey,
      sectionLabel: section.attributes.sectionLabel,
      summaryPriority: section.attributes.summaryPriority,
      upstreamAllessehCollectionId: section.attributes.upstreamAllessehCollectionId ?? '',
      pages: section.collection.map((page) => ({
        pageModules: page.collection.map(
          (module) =>
            ({
              moduleItems: module.collection.map((moduleItem) => ({
                itemType: 'Content',
                itemFields: { contentItem: moduleItem.attributes }
              })),
              uiModuleFields: module.attributes.pageModule!.uiModuleFields,
              uiModuleType: module.attributes.pageModule!.uiModuleType
            } as PageModule)
        )
      }))
    }))
  },
  publicationKey: currentProperty ?? 'wsj'
});
