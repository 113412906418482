import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import {
  AllessehContentType,
  ArticleItem,
  ArticleItemInput,
  NewsletterDto,
  NewsletterInput,
  NewsletterPageContainer,
  PublicationSetting,
  ScheduledContent,
  ScheduledContentCancelInput,
  ScheduledContentCreateInput,
  ScheduledContentRescheduleInput,
  useCancelScheduledContentMutation,
  useCreateNewsletterMutation,
  useCreatePreviewNewsletterMutation,
  useRescheduleContentMutation,
  useScheduleContentMutation
} from 'data/generated/graphql';
import { prepContentItems } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { useConvertedProperty } from 'hooks';
import { formatInTimeZone } from 'utils/dates';
import { adjustToUtcTime } from 'utils/newsletters';

export const useNewsletterPublish = (publicationSettings?: PublicationSetting) => {
  const { alertError, removeAlert, alertSuccess } = useAlert();
  const currentProperty = useConvertedProperty();
  const { root: data, metadata, getDTO } = useDataModelContext<NewsletterPageContainer>();

  const { mutateAsync: sendTestAsync } = useCreatePreviewNewsletterMutation();
  const { mutateAsync: sendNowAsync } = useCreateNewsletterMutation();
  const { mutateAsync: scheduleAsync } = useScheduleContentMutation();
  const { mutateAsync: rescheduleAsync } = useRescheduleContentMutation();
  const { mutateAsync: cancelScheduleAsync } = useCancelScheduledContentMutation();

  const handleSendTest = async (emails: string) => {
    removeAlert();

    const newsletterInput: NewsletterInput = {
      allessehId: metadata.allessehId,
      attributes: { ...data.attributes, recipients: emails },
      collection: data.collection[0].collection.map<ArticleItemInput>((item) => prepContentItems(item as ArticleItem)),
      name: metadata.name,
      publicationKey: metadata.publicationKey,
      moduleAttributes: data.collection[0].attributes
    };

    try {
      await sendTestAsync({ newsletterInput });

      alertSuccess(
        `Preview campaign ${newsletterInput.allessehId} successfully published to Allesseh for recipient(s) ${emails
          .split(',')
          .join(', ')}.`
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not update the newsletter. ${e.message}`);
      } else {
        alertError('Could not update the newsletter.');
      }
    }
  };

  const handleSendNow = async (): Promise<NewsletterDto | null> => {
    removeAlert();

    const newsletterInput: NewsletterInput = {
      allessehId: metadata.allessehId,
      attributes: data.attributes,
      collection: data.collection[0].collection.map<ArticleItemInput>((item) => prepContentItems(item as ArticleItem)),
      name: metadata.name,
      publicationKey: metadata.publicationKey,
      moduleAttributes: data.collection[0].attributes
    };

    try {
      const { createNewsletter } = await sendNowAsync({ newsletterInput });

      alertSuccess(
        `Campaign successfully sent on ${formatInTimeZone(
          createNewsletter.metadata.createdUtc,
          publicationSettings?.preferredTimezone,
          'MMMM dd, yyyy',
          { showTimezoneAbbreviation: false }
        )} at ${formatInTimeZone(
          createNewsletter.metadata.createdUtc,
          publicationSettings?.preferredTimezone,
          'h:mm aaaa',
          {
            showTimezoneAbbreviation: true
          }
        )}.`
      );

      return createNewsletter as NewsletterDto;
    } catch (e: unknown) {
      alertError(`Campaign failed with the following error: ${e as string}`);
    }

    return null;
  };

  const handleSchedule = async (publishUtc: number): Promise<ScheduledContent | null> => {
    const newsletter = getDTO(true);
    const publishUtcAdjusted = adjustToUtcTime(publishUtc, publicationSettings);
    newsletter.metadata.publishUtc = publishUtcAdjusted;

    removeAlert();

    const scheduleInput: ScheduledContentCreateInput = {
      allessehId: metadata.allessehId,
      contentType: AllessehContentType.Newsletter,
      publishUtc: publishUtcAdjusted,
      body: newsletter
    };

    try {
      const { scheduleContent } = await scheduleAsync({
        scheduledContentCreateInput: scheduleInput,
        publicationKey: currentProperty ?? ''
      });

      alertSuccess(
        `Campaign successfully scheduled for ${formatInTimeZone(
          scheduleContent.publishUtc,
          publicationSettings?.preferredTimezone,
          'MMMM dd, yyyy',
          { showTimezoneAbbreviation: false }
        )} at ${formatInTimeZone(scheduleContent.publishUtc, publicationSettings?.preferredTimezone, 'h:mm aaaa', {
          showTimezoneAbbreviation: true
        })}.`
      );

      return scheduleContent as ScheduledContent;
    } catch (e: unknown) {
      alertError(`Campaign scheduling failed with the following error: ${e as string}`);
    }

    return null;
  };

  const handleReschedule = async (publishUtc: number): Promise<ScheduledContent | null> => {
    removeAlert();

    const newsletter = getDTO(true);

    const previousPublishUtc = newsletter.metadata.publishUtc!;
    const publishUtcAdjusted = adjustToUtcTime(publishUtc, publicationSettings);
    newsletter.metadata.publishUtc = publishUtcAdjusted;

    const input: ScheduledContentRescheduleInput = {
      allessehId: newsletter.metadata.allessehId,
      contentType: AllessehContentType.Newsletter,
      previousPublishUtc,
      newPublishUtc: publishUtcAdjusted,
      body: newsletter
    };

    try {
      const { rescheduleContent } = await rescheduleAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentRescheduleInput: input
      });

      alertSuccess('Rescheduled newsletter module has been updated.');

      return rescheduleContent as ScheduledContent;
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not update the schedule the newsletter module. ${e.message}`);
      } else {
        alertError('Could not update the schedule the newsletter module.');
      }
    }

    return null;
  };

  const handleDeleteSchedule = async () => {
    const newsletter = getDTO(true);

    const input: ScheduledContentCancelInput = {
      allessehId: newsletter.metadata.allessehId,
      publishUtc: newsletter.metadata.publishUtc!
    };

    try {
      await cancelScheduleAsync({ publicationKey: currentProperty ?? '', scheduledContentCancelInput: input });

      alertSuccess('Campaign successfully canceled.');
    } catch (e: unknown) {
      alertError(`Cancel campaign failed with the following error: ${e as string}`);
    }
  };

  const handleSendSchedule = async () => {
    removeAlert();

    const newsletter = getDTO(true);

    const newsletterInput: NewsletterInput = {
      allessehId: metadata.allessehId,
      attributes: data.attributes,
      collection: data.collection[0].collection.map<ArticleItemInput>((item) => prepContentItems(item as ArticleItem)),
      name: metadata.name,
      publicationKey: metadata.publicationKey,
      moduleAttributes: data.collection[0].attributes
    };

    const input: ScheduledContentCancelInput = {
      allessehId: newsletter.metadata.allessehId,
      publishUtc: newsletter.metadata.publishUtc!
    };

    try {
      await cancelScheduleAsync({ publicationKey: currentProperty ?? '', scheduledContentCancelInput: input });

      const { createNewsletter } = await sendNowAsync({ newsletterInput });

      alertSuccess(
        `Campaign successfully sent on ${formatInTimeZone(
          createNewsletter.metadata.createdUtc,
          publicationSettings?.preferredTimezone,
          'MMMM dd, yyyy',
          { showTimezoneAbbreviation: false }
        )} at ${formatInTimeZone(
          createNewsletter.metadata.createdUtc,
          publicationSettings?.preferredTimezone,
          'h:mm aaaa',
          { showTimezoneAbbreviation: true }
        )}.`
      );
    } catch (e: unknown) {
      alertError(`Send campaign failed with the following error: ${e as string}`);
    }
  };

  return {
    handleSendTest,
    handleSendNow,
    handleSchedule,
    handleReschedule,
    handleSendSchedule,
    handleDeleteSchedule
  };
};
