import { useState } from 'react';
import { Button, Group, IconTrash } from '@screentone/core';

import Countdown from 'components/countdown/Countdown';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { BannerModuleContainer } from 'data/generated/graphql';

interface PublishingButtonsProps {
  soonToBePublishedScheduledContentPublishUtc: number | null;
  hasBannerChanged: boolean;
  isPerformingAction: boolean;
  isInEditMode: boolean;
  onPublish: (publishUtc: number) => Promise<void>;
  onSchedule: (publishUtc: number) => Promise<void>;
  onCancelEdit: () => void;
  onDelete: () => Promise<void>;
}

const PublishingButtons = ({
  soonToBePublishedScheduledContentPublishUtc,
  hasBannerChanged,
  isPerformingAction,
  isInEditMode,
  onPublish,
  onSchedule,
  onCancelEdit,
  onDelete
}: PublishingButtonsProps) => {
  const { metadata } = useDataModelContext<BannerModuleContainer>();
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const toggleScheduleDialog = () => setShowScheduleModal((prev) => !prev);

  const handleSchedule = async (publishUtc: number) => {
    await onSchedule(publishUtc);
    setShowScheduleModal(false);
  };

  const handleDeleteScheduled = async () => {
    await onDelete();
  };

  const handlePublish = async (publishUtc: number) => {
    await onPublish(publishUtc);
  };

  const areButtonsDisabled = isPerformingAction || !hasBannerChanged;

  return (
    <>
      {showScheduleModal && (
        <ScheduleDialog dialogTitle="Schedule module" onSchedule={handleSchedule} onCancel={toggleScheduleDialog} />
      )}
      <Group align="center" gap="md" wrap={false}>
        {soonToBePublishedScheduledContentPublishUtc && (
          <Countdown
            timeInMilliseconds={soonToBePublishedScheduledContentPublishUtc}
            timerMessage="Next Scheduled Publish in"
            atZeroMessage="Scheduled Publish should be happening any moment now..."
          />
        )}
        <ExternalLink
          contentId={metadata.allessehId}
          type={ExternalLinkContentType.COLLECTION}
          isPublished={!!metadata.updatedUtc}
        />
        {isInEditMode && (
          <>
            <Button
              tertiary
              disabled={isPerformingAction}
              onClick={handleDeleteScheduled}
              margin={{ right: 'sm' }}
              color="lava"
              icon={IconTrash as SvgComponent}
              data-testid="banner-page-delete-schedule-button"
            >
              Delete Scheduled publish
            </Button>
            <Button
              secondary
              onClick={onCancelEdit}
              disabled={isPerformingAction}
              margin={{ right: 'sm' }}
              data-testid="banner-page-cancel-edit-button"
            >
              Cancel Edit
            </Button>
          </>
        )}
        <Button
          secondary
          onClick={toggleScheduleDialog}
          disabled={areButtonsDisabled}
          data-testid="page-schedule-button"
        >
          {isInEditMode ? 'Reschedule' : 'Schedule'}
        </Button>
        <Button primary onClick={handlePublish} disabled={areButtonsDisabled} data-testid="page-publish-button">
          {isInEditMode ? 'Save' : 'Publish'}
        </Button>
      </Group>
    </>
  );
};

export default PublishingButtons;
