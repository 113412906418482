/* eslint-disable import/no-cycle */
import { Droppable } from 'react-beautiful-dnd';
import { Wrapper } from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES, DROPPABLE_TYPES } from 'contexts/drag-and-drop/dragUtils';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { PageContainer } from 'data/generated/graphql';
import styles from './Page.module.scss';
import TopOptionalToggle from '../Module/components/top-optional-toggle/TopOptionalToggle';

interface PageProps extends CommonEntityProps {
  data: PageContainer;
}

export const Page = ({ data: page, parentHierarchyId, index, isHistory }: PageProps) => {
  const { generateHierarchyId, renderEntity, insertEntity, removeEntity } = useDataModelContext();
  const { pageTypeSetting, availableRequiredLayoutModules } = usePageTypeSettingsContext();

  const hierarchyId = generateHierarchyId(page, parentHierarchyId, index);
  const currentLayout = pageTypeSetting?.layouts.find((l) => l.id === page.attributes.layoutId);

  const childrenElements = page.collection.map((collection, i) => (
    <div key={i}>
      {renderEntity(collection, {
        hierarchyId,
        index: i,
        isHistory,
        extraProps: {
          module: {
            layout: currentLayout
          }
        }
      })}
    </div>
  ));

  return (
    <Wrapper
      padding={{ top: 'sm', right: 'md', bottom: 'md', left: 'md' }}
      className={styles.pageWrapper}
      data-model-hierarchy-id={hierarchyId}
    >
      {!isHistory && (
        <TopOptionalToggle
          page={page}
          availableRequiredLayoutModules={availableRequiredLayoutModules}
          insertEntity={insertEntity}
          removeEntity={removeEntity}
        />
      )}
      <Wrapper className={styles.pageBackground} />

      {!isHistory ? (
        /* Wrap children in Droppable only for draft section */
        <Droppable droppableId={DROPPABLE_ID_PREFIXES.MODULES + hierarchyId} type={DROPPABLE_TYPES.MODULE}>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              data-testid="page-draft-modules"
            >
              {childrenElements}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        childrenElements
      )}
    </Wrapper>
  );
};
