import { useCallback, useEffect } from 'react';
import { Button, IconEdit, Wrapper } from '@screentone/core';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ContentUnion, ModuleContainer, PageModule } from 'data/generated/graphql';
import { PageModuleModal } from 'features/section-page-edit/components/page-draft-section/components/page-module-modal/PageModuleModal';

interface EditModuleProps {
  hierarchyId: string;
  module: ModuleContainer;
  viewOnly?: boolean;
}

export const EditModule = ({ hierarchyId, viewOnly, module }: EditModuleProps) => {
  const { modifyEntity } = useDataModelContext();
  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();

  const handleDismissEditModule = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const onPageModuleChange = useCallback(
    (newPageModule: PageModule, collection?: ContentUnion[]) => {
      modifyEntity(hierarchyId, (entity) => {
        const module = entity as ModuleContainer;
        module.attributes.pageModule = newPageModule;
        if (collection) module.collection = collection;
      });
    },
    [hierarchyId, modifyEntity]
  );

  const handleEditModule = () => {
    setIsModalOpen(true);

    setActionModalComponent(
      <PageModuleModal
        onChange={onPageModuleChange}
        pageModule={module.attributes.pageModule as PageModule}
        onDismiss={handleDismissEditModule}
        viewOnly={viewOnly}
      />
    );
  };

  useEffect(() => {
    setActionModalComponent(
      <PageModuleModal
        onChange={onPageModuleChange}
        pageModule={module.attributes.pageModule as PageModule}
        onDismiss={handleDismissEditModule}
        viewOnly={viewOnly}
      />
    );
  }, [handleDismissEditModule, module.attributes.pageModule, onPageModuleChange, setActionModalComponent, viewOnly]);

  return (
    <Wrapper>
      <Button tertiary icon={IconEdit as SvgComponent} onClick={handleEditModule}>
        {viewOnly ? 'View' : 'Edit'} module
      </Button>
    </Wrapper>
  );
};
