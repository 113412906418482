/* eslint-disable import/no-cycle */
import { useCallback, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Dropdown, Group, IconThreeDotsVer, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useAlert } from 'contexts/alert/useAlert';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import {
  ArticleItem,
  BannerModuleContainer,
  ExternalCollectionItem,
  PageModule,
  QueryItem
} from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import styles from './BannerModule.module.scss';
import { ModuleItemCount } from '../components/module-item-count/ModuleItemCount';
import { TreatmentTypeDropdown } from '../components/treatment-type-dropdown/TreatmentTypeDropdown';
import { UiModuleFieldsHeader } from '../components/ui-module-fields-header/UiModuleFieldsHeader';

interface ModuleProps extends CommonEntityProps {
  data: BannerModuleContainer;
}

export const BannerModule = ({ data: module, parentHierarchyId, index, isHistory }: ModuleProps) => {
  const { generateHierarchyId, renderEntity } = useDataModelContext();
  const { renderActions: renderMenuActions, hasRenderActions } = useContextMenuActions();
  const hierarchyId = generateHierarchyId(module, parentHierarchyId, index);

  const { alertState } = useAlert();
  const moduleHasValidationError =
    !isHistory && alertState.pageValidationError?.moduleErrors.some((error) => error.pathToModule === hierarchyId);

  const { getTreatmentTypeSetting, currentLayout, pageTypeSetting } = usePageTypeSettingsContext();
  const treatmentTypeSetting = getTreatmentTypeSetting(module.attributes.pageModule as PageModule);

  const [articlesPerContentItem, setArticlesPerContentItem] = useState<number[]>(
    module.collection.map((contentItem) => {
      if (contentItem.type === 'Article') {
        return 1;
      }
      if (contentItem.type === 'Collection') {
        const item = contentItem as ExternalCollectionItem;
        return item.contentItems.length;
      }
      if (contentItem.type === 'Query') {
        const item = contentItem as QueryItem;
        if (item.attributes.isFeed) {
          return 1;
        }
        return 0;
      }

      return 0;
    })
  );

  const handleContentLoad = useCallback((index: number, contentLength: number) => {
    setArticlesPerContentItem((prev) => {
      const newLength = [...prev];
      newLength[index] = contentLength;
      return newLength;
    });
  }, []);

  const getNumTotalItemsBeforeIndex = (index: number) =>
    articlesPerContentItem.reduce((acc: number, curr: number, numContentItemsIndex: number) => {
      if (numContentItemsIndex < index) {
        const item = module.collection[numContentItemsIndex];
        if (item.type === 'Article') {
          const articleItem = item as ArticleItem;
          const parsedContent = safelyParseContent<AllessehContent>(articleItem.content);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!parsedContent.data?.attributes?.content_status) {
            return acc + curr;
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const itemStatus = parsedContent.data?.attributes?.content_status;
          return itemStatus === 'embargo' ? acc : acc + curr;
        }
        if (item.type === 'Query') {
          const queryItem = item as QueryItem;
          if (queryItem.attributes.isFeed) {
            return acc + curr;
          }
        }
      }
      return numContentItemsIndex < index ? acc + curr : acc;
    }, 0);

  const numTotalItemsUsed = getNumTotalItemsBeforeIndex(module.collection.length);

  const droppablePrefix = isHistory ? DROPPABLE_ID_PREFIXES.HISTORY : DROPPABLE_ID_PREFIXES.BANNER_MODULE_ITEMS;
  const actionsType = isHistory ? MenuActionsKeys.HistoryModule : MenuActionsKeys.DraftModule;
  const hasMenuActions = hasRenderActions(actionsType, {
    entity: module,
    hierarchyId,
    index,
    extraProperties: { numTotalItemsUsed }
  });

  const isAvailableLayoutModuleType =
    currentLayout?.availableLayoutModules
      .map((m) => m.uiModuleType)
      .includes((module.attributes.pageModule as PageModule).uiModuleType) ?? false;

  const isDropDisabled = isHistory;

  return (
    <Wrapper
      margin={{ bottom: !isAvailableLayoutModuleType && !isHistory ? 'mlg' : 'none' }}
      data-model-hierarchy-id={hierarchyId}
      className={classnames(styles.moduleWrapper)}
      data-testid="banner-page-container"
    >
      <Group margin={{ bottom: 'xs' }} align="space-between" data-testid="banner-treatment-header">
        <Group gap="none" data-testid="banner-treatment-type-name">
          <TreatmentTypeDropdown
            hierarchyId={hierarchyId}
            pageModule={module.attributes.pageModule as PageModule}
            module={module}
            pageTypeSetting={pageTypeSetting}
            showCurrentTreatmentTypeAtTheTop
            viewOnly={isHistory}
          />
        </Group>
        {hasMenuActions && (
          <Dropdown padding={{ all: 'none' }} position="right" trigger={<IconThreeDotsVer color="asphalt" />}>
            {renderMenuActions(actionsType, {
              entity: module,
              hierarchyId,
              index,
              extraProperties: { numTotalItemsUsed }
            })}
          </Dropdown>
        )}
      </Group>
      <UiModuleFieldsHeader pageModule={module.attributes.pageModule as PageModule} />
      <Wrapper
        padding={{ all: 'xs' }}
        className={classnames(
          styles.moduleBox,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          { [styles.moduleDashedArea]: !isHistory }
        )}
        data-error={moduleHasValidationError ? 'true' : 'false'}
      >
        <Droppable droppableId={droppablePrefix + hierarchyId} isDropDisabled={isDropDisabled}>
          {(droppableProvided) => (
            <div
              data-testid="page-module-item"
              className={styles.moduleDroppableArea}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {module.collection.map((item, i) => {
                const availableContentSlots = treatmentTypeSetting?.maxRequiredContentItems
                  ? treatmentTypeSetting.maxRequiredContentItems - getNumTotalItemsBeforeIndex(i)
                  : treatmentTypeSetting?.minRequiredContentItems
                  ? treatmentTypeSetting.minRequiredContentItems - getNumTotalItemsBeforeIndex(i)
                  : undefined;
                const articleHierarchyId = generateHierarchyId(item, hierarchyId, i);
                const articleActionsType = isHistory ? MenuActionsKeys.HistoryArticle : MenuActionsKeys.DraftArticle;

                return (
                  <div key={i}>
                    {renderEntity(item, {
                      hierarchyId,
                      index: i,
                      isHistory,
                      extraProps: {
                        content: { availableContentSlots, onContentLoaded: handleContentLoad, isDraft: !isHistory },
                        article: {
                          renderActions: () =>
                            renderMenuActions(articleActionsType, {
                              hierarchyId: articleHierarchyId,
                              entity: item,
                              index: i
                            })
                        },
                        module: {
                          treatmentTypeSetting
                        }
                      }
                    })}
                  </div>
                );
              })}
              {droppableProvided.placeholder}
              <ModuleItemCount
                min={treatmentTypeSetting?.minRequiredContentItems}
                max={treatmentTypeSetting?.maxRequiredContentItems}
                showMaxInDenominator
                count={numTotalItemsUsed}
                contentTypes={treatmentTypeSetting?.contentTypes}
                considerZeroMin
                data-testid="page-module-item-count"
              />
            </div>
          )}
        </Droppable>
      </Wrapper>
    </Wrapper>
  );
};
