import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, Group, IconLogIn, IconMinusCircle, Loader, Typography } from '@screentone/core';
import { cloneDeep } from 'lodash';

import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { HIGHLIGHT_GROUPS } from 'contexts/highlight/HighlightContext';
import { useHighlight } from 'contexts/highlight/useHighlight';
import { useItp } from 'contexts/itp/useItp';
import { ItpDto, PageModule, useImportItpDtoContentQuery } from 'data/generated/graphql';
import { useAuraQuery } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AuraEmployeeResponse } from 'hooks/useAuraQuery';
import { formatInTimeZone } from 'utils/dates';

function getUserText(userData: AuraEmployeeResponse | undefined, userId: string) {
  return userData ? `${userData.first_name ?? ''} ${userData.last_name ?? ''}` : userId;
}

const ItpSettings = () => {
  const { alertError } = useAlert();
  const { currentProperty } = useAuth();
  const { getDTO, getAllArticleIds } = useDataModelContext();
  const { addItems, clearItems } = useHighlight();
  const { clearErrorState, updateIssue, defaultItpIssueState, defaultItpIssueStateError } = useItp();

  const itpDto = getDTO(true) as ItpDto;
  const currentIds: string[] = getAllArticleIds();
  const [isFirstImport, setIsFirstImport] = useState(currentIds.length === 0);

  const [shouldUpdateIssue, setShouldUpdateIssue] = useState(false);

  const creatorUser = itpDto.metadata.creatorUser.djUserId;
  const revisorUser = itpDto.metadata.revisorUser?.djUserId ?? '';

  const { data: creatorUserData, isLoading: isFetchingCreatorUserData } = useAuraQuery(creatorUser);
  const { data: revisorUserData, isLoading: isFetchingUserData } = useAuraQuery(revisorUser);
  const { data: publicationSettings } = usePublicationSettings();
  const { data: importedContent, isFetching: isFetchingImportedContent } = useImportItpDtoContentQuery(
    {
      itpDTOImportContentInput: { itpDto },
      publicationKey: currentProperty ?? 'wsj'
    },
    { enabled: shouldUpdateIssue, staleTime: 30 * 1000 }
  );

  const creatorUserDataText = getUserText(creatorUserData, creatorUser);
  const revisorUserText = getUserText(revisorUserData, revisorUser);

  const { lastPublishedDate, lastUpdatedDate, createdDate } = useMemo(() => {
    const result = { lastPublishedDate: '', lastUpdatedDate: '', createdDate: '' };

    result.lastPublishedDate = !itpDto.metadata.publishUtc
      ? ''
      : formatInTimeZone(
          new Date(itpDto.metadata.publishUtc),
          publicationSettings?.publicationSetting.preferredTimezone,
          'MM/dd/yyyy hh:mm aa',
          { showTimezoneAbbreviation: true }
        );
    result.lastUpdatedDate = !itpDto.metadata.updatedUtc
      ? ''
      : formatInTimeZone(
          new Date(itpDto.metadata.updatedUtc),
          publicationSettings?.publicationSetting.preferredTimezone,
          'MM/dd/yyyy hh:mm aa',
          { showTimezoneAbbreviation: true }
        );
    result.createdDate = !itpDto.metadata.createdUtc
      ? ''
      : formatInTimeZone(
          new Date(itpDto.metadata.createdUtc),
          publicationSettings?.publicationSetting.preferredTimezone,
          'MM/dd/yyyy hh:mm aa',
          { showTimezoneAbbreviation: true }
        );
    return result;
  }, [itpDto.metadata, publicationSettings]);

  const handleEmptyAllContent = () => {
    // eslint-disable-next-line
    if (confirm('Are you sure? This will remove all content from the issue.')) {
      if (defaultItpIssueStateError) {
        if (typeof defaultItpIssueStateError === 'string') {
          alertError(`Failed to retrieve default issue state. ${defaultItpIssueStateError}`);
        } else {
          alertError('Failed to retrieve default issue state.');
        }

        return;
      }

      const newItpDto = cloneDeep(defaultItpIssueState!);
      newItpDto.root.attributes = itpDto.root.attributes;
      newItpDto.metadata = { ...itpDto.metadata, refreshedUtc: null };

      clearItems();
      setShouldUpdateIssue(false);
      setIsFirstImport(true);

      clearErrorState();
      updateIssue(newItpDto);
    }
  };

  useEffect(() => {
    if (shouldUpdateIssue && !isFetchingImportedContent) {
      const importedModules = importedContent?.importItpDTOContent as PageModule[][] | undefined;

      if (!importedModules) {
        alertError('Failed to import content');
        setShouldUpdateIssue(false);
        return;
      }

      const { issue: updatedIssue, newIds, staleIds } = importedContent!.importItpDTOContent;

      updateIssue(updatedIssue as ItpDto);

      setShouldUpdateIssue(false);
      setIsFirstImport(false);
      addItems(staleIds, HIGHLIGHT_GROUPS.ITP_STALE_ITEMS);

      if (!isFirstImport) {
        addItems(newIds, HIGHLIGHT_GROUPS.ITP_NEW_ITEMS);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateIssue, isFetchingImportedContent]);

  // check if every page module is empty
  const disableEmptyAll = itpDto.root.collection.every((section) =>
    section.collection.every((page) => page.collection.every((module) => !module.collection.length))
  );

  return (
    <>
      <Box margin={{ bottom: 'md' }}>
        <Box.Title>Data</Box.Title>
        <Box.Content>
          <Group direction="column" gap="mlg">
            <Group direction="column" gap="xs" data-testid="itp-date-time-msg">
              <Typography variant="label3">Date/Time last pulled from ITP Tool</Typography>
              {itpDto.metadata.refreshedUtc && (
                <Typography color="ink" data-testid="itp-published-pull-date">
                  {formatInTimeZone(
                    new Date(itpDto.metadata.refreshedUtc),
                    publicationSettings?.publicationSetting.preferredTimezone,
                    'MM/dd/yyyy hh:mm aa',
                    { showTimezoneAbbreviation: true }
                  )}
                </Typography>
              )}
              {!itpDto.metadata.refreshedUtc && <Typography color="asphalt">N/A</Typography>}
            </Group>

            <Button
              tertiary
              icon={IconLogIn as SvgComponent}
              onClick={() => {
                clearErrorState();
                setShouldUpdateIssue(true);
              }}
              disabled={shouldUpdateIssue}
              data-testid="itp-import-content-button"
            >
              Import content
            </Button>

            <Typography variant="note">
              New items will appear at the bottom of their respective pages/modules
            </Typography>

            {lastPublishedDate && (
              <Group direction="column" gap="xs">
                <Typography variant="label3">Last Published</Typography>
                <Typography color="ink">{lastPublishedDate}</Typography>
              </Group>
            )}

            {lastUpdatedDate && revisorUserText ? (
              <Typography variant="note">
                Last edited by {isFetchingUserData ? <Loader size="md" /> : revisorUserText} on {lastUpdatedDate}
              </Typography>
            ) : (
              <Typography variant="note" data-testid="itp-created-by-date">
                Created by {isFetchingCreatorUserData ? <Loader size="md" /> : creatorUserDataText} on {createdDate}
              </Typography>
            )}
          </Group>
        </Box.Content>
      </Box>
      <Box>
        <Box.Title>Issue</Box.Title>
        <Box.Content>
          <Button
            color="lava"
            disabled={disableEmptyAll}
            icon={IconMinusCircle as SvgComponent}
            onClick={handleEmptyAllContent}
            tertiary
            data-testid="itp-empty-all-content-button"
          >
            Empty all content
          </Button>
        </Box.Content>
      </Box>
    </>
  );
};

export default ItpSettings;
