/* eslint-disable no-param-reassign */
import { ChangeEventHandler, PropsWithChildren, useState } from 'react';
import { Box, Button, Divider, Input, Overlay, Wrapper } from '@screentone/core';

import { useAlert } from 'contexts/alert/useAlert';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ModuleContainer } from 'data/generated/graphql';

interface AllesehModuleIdModal {
  title: string;
  onClose: () => void;
}

const AllesehModuleIdModal = ({ title, children, onClose }: PropsWithChildren<AllesehModuleIdModal>) => (
  <Overlay onDismiss={onClose} status="open">
    <Box>
      <Box.Title>{title}</Box.Title>
      <Box.Content padding={{ all: 'none' }}>{children}</Box.Content>
    </Box>
  </Overlay>
);

interface EditAllesehModuleIdModalProps {
  hierarchyId: string | undefined;
  moduleId: string | null | undefined;
}

const EditAllesehModuleIdModal = ({ hierarchyId, moduleId }: EditAllesehModuleIdModalProps) => {
  const [moduleIdValue, setModuleId] = useState(() => (typeof moduleId === 'string' ? moduleId : ''));
  const { alertSuccess } = useAlert();
  const [error, setError] = useState(false);
  const { setIsModalOpen, setActionModalComponent } = useContextMenuActions();
  const { modifyEntity } = useDataModelContext();

  const isAlphanumericRegex = /^[a-zA-Z0-9]+$/;

  const resetInput = () => {
    setError(false);
    setModuleId('');
  };

  const handleInputValueChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (value.length === 0) {
      resetInput();
      return;
    }

    setError(!isAlphanumericRegex.test(value));
    setModuleId(value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // Once the modal is closed, remove the modal component
    setActionModalComponent(null);
  };

  const handleAdd = () => {
    if (typeof hierarchyId === 'string') {
      modifyEntity(hierarchyId, (entity) => {
        if (moduleIdValue.trim().length === 0) {
          (entity as ModuleContainer).attributes.moduleId = null;
          closeModal();
          alertSuccess('Module ID deleted successfully');
          return;
        }

        (entity as ModuleContainer).attributes.moduleId = moduleIdValue.trim();

        closeModal();

        alertSuccess('Module ID added successfully');
      });
    }
  };

  const hasModuleId = typeof moduleId === 'string';

  const handleDeleteModuleId = () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const confirmDelete = confirm('Are you sure you want to delete the module ID?');

    if (confirmDelete && typeof hierarchyId === 'string') {
      modifyEntity(hierarchyId, (entity) => {
        (entity as ModuleContainer).attributes.moduleId = null;
        setModuleId('');
        closeModal();
        alertSuccess('Module ID deleted successfully');
      });
    }
  };

  return (
    <AllesehModuleIdModal title={hasModuleId ? 'EDIT MODULE ID' : 'ADD MODULE ID'} onClose={closeModal}>
      <Wrapper padding={{ all: 'md' }}>
        <Input
          type="text"
          placeholder="Enter module ID"
          value={moduleIdValue}
          onChange={handleInputValueChange}
          margin={{ right: 'sm' }}
          data-testid="allesseh-module-id-input"
          error={error}
        />
      </Wrapper>
      <Divider />
      <Wrapper padding={{ all: 'md' }}>
        {hasModuleId && (
          <Button onClick={handleDeleteModuleId} tertiary color="lava" data-testid="button-delete-module-id">
            Delete module ID
          </Button>
        )}
        <Button margin={{ left: 'xl' }} secondary onClick={closeModal} data-testid="button-cancel-module-id">
          Cancel
        </Button>
        <Button disabled={error} primary margin={{ left: 'sm' }} onClick={handleAdd} data-testid="button-add-module-id">
          {hasModuleId ? 'Edit' : 'Add'}
        </Button>
      </Wrapper>
    </AllesehModuleIdModal>
  );
};

export default EditAllesehModuleIdModal;
