/* eslint-disable import/no-cycle */
import { useEffect, useLayoutEffect, useState } from 'react';
import { Button, IconArrowLeft, IconArrowRight, Illustration, Typography, Wrapper } from '@screentone/core';
import { isSameDay } from 'date-fns';

import { ResponsiveLoader } from 'components';
import { RevisionSelector } from 'components/revision-selector/RevisionSelector';
import {
  BannerDto,
  OffPlatformDto,
  PageDto,
  ScheduledContentStatus,
  useGetLastNPublishedScheduledContentQuery,
  useGetScheduledContentByAllessehIdQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { NUMBER_OF_PREVIOUS_PUBLISHED_CONTENT_TO_SHOW } from 'utils/constants';
import { HistoryItem } from './HistoryItem';
import styles from './PublishHistory.module.scss';

export type HistoryContent = PageDto | OffPlatformDto | BannerDto;

interface PageHistorySectionProps<T extends HistoryContent> {
  liveVersion: T | undefined;
  publishUtc: number;
  hasRecentlyPublished: boolean;
  originalVersionWhenInHistoryEditMode?: T | null;
  currentlyEditedVersion?: T;
  handleShowHistoryEditMode: (historyItem: HistoryContent) => void;
  scrollableSectionClassName?: string;
  handlePreviewClick?: (selectedVersion: T) => void;
  isPreviewLoading?: boolean;
  // showURL?: boolean;
}

export const PublishHistory = <T extends HistoryContent>({
  liveVersion,
  publishUtc,
  hasRecentlyPublished,
  originalVersionWhenInHistoryEditMode,
  currentlyEditedVersion,
  handleShowHistoryEditMode,
  scrollableSectionClassName,
  handlePreviewClick,
  isPreviewLoading
}: // showURL = true
PageHistorySectionProps<T>) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [history, setHistory] = useState<T[]>([]);
  const [historyForDate, setHistoryForDate] = useState<T[]>([]);
  const [userPaginated, setUserPaginated] = useState(false);
  const [selectedDate, setSelectedDate] = useState<number | null>(Date.now());
  const currentProperty = useConvertedProperty();

  const {
    data: scheduledContents,
    isLoading: isLoadingScheduledContent,
    refetch: refetchScheduledContent,
    fetchStatus: scheduledContentFetchStatus,
    isFetching: isFetchingScheduledContent
  } = useGetScheduledContentByAllessehIdQuery(
    {
      allessehId: liveVersion!.metadata.allessehId,
      publicationKey: currentProperty ?? ''
    },
    { enabled: !!liveVersion }
  );

  const {
    data: lastNScheduledContents,
    refetch: refetchLastNScheduledContent,
    isLoading: isLoadingLastNScheduledContent,
    fetchStatus: lastNScheduledContentFetchStatus,
    isFetching: isFetchingLastNScheduledContent
  } = useGetLastNPublishedScheduledContentQuery(
    {
      allessehId: liveVersion!.metadata.allessehId,
      publishUtc: liveVersion!.metadata.publishUtc,
      status: ScheduledContentStatus.Published,
      n: NUMBER_OF_PREVIOUS_PUBLISHED_CONTENT_TO_SHOW
    },
    { enabled: !!liveVersion }
  );

  const isLoading =
    (isLoadingScheduledContent && scheduledContentFetchStatus !== 'idle') ||
    (isLoadingLastNScheduledContent && lastNScheduledContentFetchStatus !== 'idle');

  const handleReset = () => {
    setUserPaginated(false);
    setHistoryForDate([]);
    setCurrentIndex(0);
    setSelectedDate(Date.now());
  };

  useEffect(() => {
    async function reset() {
      if (hasRecentlyPublished) {
        handleReset();
        await refetchScheduledContent();
      }
    }
    void reset();
  }, [hasRecentlyPublished, refetchScheduledContent]);

  useLayoutEffect(() => {
    if (!liveVersion) return;
    setHistory([liveVersion]);
  }, [liveVersion]);

  useLayoutEffect(() => {
    // Because the past published versions or the scheduled versions might not exist for a specific content,
    // or we might get an error and not retrieve the data, we only require the live version to be defined to set the history.
    if (!liveVersion) return;

    let contentHistory: T[] = [];
    let lastNScheduledContentMap: T[] = [];

    if (scheduledContents) {
      contentHistory = scheduledContents.scheduledContentByAllessehId.map((content) => content.body as T);
    }
    if (lastNScheduledContents) {
      lastNScheduledContentMap = lastNScheduledContents.lastNPublishedScheduledContent.map(
        (content) => content.body as T
      );
    }

    const dedupedAndSortedContent = [
      ...new Map(
        [...lastNScheduledContentMap, liveVersion, ...contentHistory]
          .sort((a, b) => (a.metadata.publishUtc ?? 0) - (b.metadata.publishUtc ?? 0))
          .map((item) => [item.metadata.publishUtc, item]) // Deduplication key
      ).values()
    ];
    setHistory(dedupedAndSortedContent);
  }, [liveVersion, scheduledContents, lastNScheduledContents]);

  // Unless we are looking at a specific date, automatically switch to a specific page in the following order:
  // 1. the page that is currently being edited,
  // 2. the page that matches the `publishUtc` timestamp in the url params,
  // 3. the page that is currently live in history
  useEffect(() => {
    if (historyForDate.length) return;
    if (!userPaginated) {
      if (originalVersionWhenInHistoryEditMode && currentlyEditedVersion) {
        const indexOfEditingItem = history.findIndex(
          (item) => item.metadata.publishUtc === currentlyEditedVersion.metadata.publishUtc
        );
        if (indexOfEditingItem >= 0) {
          setCurrentIndex(indexOfEditingItem);
        }
      } else if (history.length > 0 && publishUtc > 0) {
        const indexOfSelectedVersion = history.findIndex((item) => item.metadata.publishUtc === publishUtc);
        if (indexOfSelectedVersion >= 0) {
          setCurrentIndex(indexOfSelectedVersion);
        }
      } else {
        setCurrentIndex(history.findIndex((item) => item.metadata.publishUtc === liveVersion?.metadata.publishUtc));
      }
    }
  }, [
    currentlyEditedVersion,
    history,
    historyForDate.length,
    originalVersionWhenInHistoryEditMode,
    publishUtc,
    userPaginated,
    liveVersion
  ]);

  useEffect(() => {
    async function refetchContent() {
      await refetchLastNScheduledContent();
    }
    if (liveVersion) {
      void refetchContent();
    }
  }, [liveVersion, refetchLastNScheduledContent]);

  const handleClickPrev = () => {
    if (currentIndex === 0) return;

    setUserPaginated(true);
    setCurrentIndex((curr) => curr - 1);
  };

  const handleClickNext = () => {
    if (currentIndex === history.length - 1) return;

    setUserPaginated(true);
    setCurrentIndex((curr) => curr + 1);
  };

  const handleSelectDate = (dateNum: number) => {
    setSelectedDate(dateNum);
  };

  const handleSelectRevision = (publishUtc?: number) => {
    if (!history.length) return;
    const revisions = history.filter((item) => {
      if (!item.metadata.publishUtc) {
        console.warn('Missing `publishUtc` for item when trying to select specific revision in history', item);
        return false;
      }

      return isSameDay(item.metadata.publishUtc, publishUtc!);
    });
    setHistoryForDate(revisions);
    setCurrentIndex(revisions.findIndex((item) => item.metadata.publishUtc === publishUtc));
  };

  if (isLoading) {
    return (
      <div>
        <Typography margin={{ bottom: 'md' }} variant="header3">
          Publish History
        </Typography>
        <ResponsiveLoader />
      </div>
    );
  }

  if (!history.length)
    return (
      <>
        <Typography variant="header3" margin={{ bottom: 'md' }}>
          Publish History
        </Typography>
        <Illustration name="no-data" className={styles.illustration} />
        <Typography componentEl="p" size="sm" align="center">
          This content has never been published!
        </Typography>
      </>
    );

  const revisions = historyForDate.length > 0 ? historyForDate : history;
  const currentItem = revisions[currentIndex];
  const hasNext = currentIndex < revisions.length - 1;
  const hasPrev = currentIndex > 0;
  const disabledNavigationInEditMode = !!originalVersionWhenInHistoryEditMode;
  const dateGroupsFilteredInit = history.filter((item) => isSameDay(item.metadata.publishUtc!, selectedDate!));
  const dateGroupsForCalendarPublished = dateGroupsFilteredInit
    .filter((item) => item.metadata.publishUtc! < liveVersion!.metadata.publishUtc!)
    .map((item) => item.metadata.publishUtc!);
  const dateGroupsForCalendarScheduled = dateGroupsFilteredInit
    .filter((item) => item.metadata.publishUtc! > liveVersion!.metadata.publishUtc!)
    .map((item) => item.metadata.publishUtc!);

  return (
    <div>
      <Typography margin={{ bottom: 'md' }} variant="header3" data-testid="page-publish-history-title">
        Publish History
      </Typography>
      <Wrapper className={styles.navigator}>
        <Button
          tertiary
          onClick={handleClickPrev}
          disabled={
            !hasPrev || disabledNavigationInEditMode || isFetchingScheduledContent || isFetchingLastNScheduledContent
          }
          data-testid="page-publish-history-previous-button"
        >
          <IconArrowLeft margin={{ right: 'sm' }} />
          Previous
        </Button>
        <RevisionSelector
          selectedDate={selectedDate}
          onSelectRevision={handleSelectRevision}
          onSelectDate={handleSelectDate}
          onReset={handleReset}
          dateGroups={[
            {
              title: 'Scheduled pages',
              type: 'scheduled',
              dates: dateGroupsForCalendarScheduled
            },
            {
              title: 'Published Pages',
              type: 'published',
              dates: dateGroupsForCalendarPublished
            }
          ]}
          disabled={disabledNavigationInEditMode}
        />
        <Button
          tertiary
          onClick={handleClickNext}
          disabled={
            !hasNext || disabledNavigationInEditMode || isFetchingScheduledContent || isFetchingLastNScheduledContent
          }
          data-testid="page-publish-history-next-button"
        >
          Next
          <IconArrowRight margin={{ left: 'sm' }} />
        </Button>
      </Wrapper>
      <HistoryItem
        selectedVersion={currentItem}
        isLive={currentItem.metadata.publishUtc === liveVersion?.metadata.publishUtc}
        isPast={currentItem.metadata.publishUtc! < (liveVersion?.metadata.publishUtc ?? 0)}
        isEditing={!!currentlyEditedVersion}
        handleShowHistoryEditMode={handleShowHistoryEditMode}
        scrollableSectionClassName={scrollableSectionClassName}
        handlePreviewClick={handlePreviewClick}
        isPreviewLoading={isPreviewLoading}
        // showURL={showURL}
      />
    </div>
  );
};
