import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader, WidePageWrapper } from 'components';
import { CopyPublishedURL } from 'components/context-menu-actions/copy-published-url/CopyPublishedURL';
import { EditAltSumm } from 'components/context-menu-actions/edit-alt-summ/EditAltSumm';
import { OpenInNewsGrid } from 'components/context-menu-actions/open-in-newsgrid/OpenInNewsGrid';
import { OpenInNewsPress } from 'components/context-menu-actions/open-in-newspress/OpenInNewsPress';
import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { DataModelProvider, DataModelType } from 'contexts/datamodel/DataModelContext';
import { DragAndDropProvider } from 'contexts/drag-and-drop/DragAndDropContext';
import { PageTypeSettingsProvider } from 'contexts/page-type-settings/PageTypeSettingsContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { ArticleItem, BannerDto, PageType, useBannerByIdQuery } from 'data/generated/graphql';
import { BannerEdit } from 'features/banner-edit/BannerEdit';
import { useConvertedProperty } from 'hooks';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';

const BannerEditPage = () => {
  const { allessehId } = useParams();
  const currentProperty = useConvertedProperty();
  const [fetchError, setFetchError] = useState<string>('');
  const bannerDTO = useRef<BannerDto | null>(null);
  const contextActions = useMemo<MenuActions>(
    () => ({
      [MenuActionsKeys.DraftArticle]: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      [MenuActionsKeys.HistoryArticle]: [
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      [MenuActionsKeys.ArticleSearch]: [
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ]
    }),
    []
  );

  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    error: loadingError
  } = useBannerByIdQuery(
    { id: allessehId!, publicationKey: currentProperty! },
    { enabled: !!allessehId && !!currentProperty }
  );

  const isBannerDTOLoading = !isRefetching && (isLoading || isFetching);

  useEffect(() => {
    if (loadingError) {
      console.error('Fetch error', loadingError);
      let error = '404';
      if (loadingError instanceof Error) {
        if (loadingError.message === 'Failed to fetch') {
          error = '503';
        }
      }
      setFetchError(error);
    }
  }, [loadingError]);

  useEffect(() => {
    if (data && !bannerDTO.current) {
      bannerDTO.current = data.getBannerById as BannerDto;
    }
  }, [data]);

  if (fetchError) {
    return <ErrorPage type={String(fetchError)} />;
  }

  if (isBannerDTOLoading) {
    return <ResponsiveLoader />;
  }

  const bannerDTOResult = data?.getBannerById as BannerDto;

  return (
    <SummarianProvider>
      <DataModelProvider
        type={DataModelType.BannerDTO}
        root={bannerDTO.current?.root ?? bannerDTOResult.root}
        metadata={bannerDTO.current?.metadata ?? bannerDTOResult.metadata}
      >
        <PageTypeSettingsProvider pageType={PageType.Banner}>
          <TrashProvider>
            <DragAndDropProvider>
              <PageRootContainer>
                <WidePageWrapper padding={{ all: 'md' }}>
                  <ContextMenuActionsProvider actions={contextActions}>
                    <BannerEdit />
                  </ContextMenuActionsProvider>
                </WidePageWrapper>
              </PageRootContainer>
            </DragAndDropProvider>
          </TrashProvider>
        </PageTypeSettingsProvider>
      </DataModelProvider>
    </SummarianProvider>
  );
};

export default BannerEditPage;
