import { isEqual } from 'lodash';

import { EntityComponent, EntityType } from 'contexts/datamodel/DataModelContext';
import { ListContainer, QueryItem } from 'data/generated/graphql';
import { AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { QUERY_CONTENT_ID_KEY } from './queryUtils';

export const getAllessehJsonQuery = (listContainer: ListContainer) => {
  const queryItemIndex = listContainer.collection.findIndex((item) => item.type === EntityType.Query);
  const queryItem = queryItemIndex >= 0 ? (listContainer.collection[queryItemIndex] as QueryItem) : undefined;
  return queryItem ? JSON.stringify(queryItem.attributes.query) : null;
};

export const removeExcludedContentFromQuery = (allessehContentId: string, jsonQueryStr: string) => {
  const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
  parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
  parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
  const newTerm = {
    term: {
      key: QUERY_CONTENT_ID_KEY,
      value: allessehContentId
    }
  };
  parsedJsonQuery.query.not = parsedJsonQuery.query.not.filter((rule) => !isEqual(rule, newTerm));

  return parsedJsonQuery;
};

export const addExcludedContentToQuery = (allessehContentId: string, jsonQueryStr: string) => {
  const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
  parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
  parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
  const newTerm = {
    term: {
      key: QUERY_CONTENT_ID_KEY,
      value: allessehContentId
    }
  };

  if (parsedJsonQuery.query.not.findIndex((rule: AllessehQueryRule) => isEqual(rule, newTerm)) < 0) {
    parsedJsonQuery.query.not.push(newTerm);
  }

  return parsedJsonQuery;
};

export const setJsonQueryStr = (
  newQueryStr: string,
  queryItemIndex: number,
  modifyEntity: (hierarchyId: string, callback: (entity: EntityComponent) => void, updateRoot?: boolean) => void
) => {
  const hierarchyId = `0-${queryItemIndex}`;
  modifyEntity(hierarchyId, (entity) => {
    const query = entity as QueryItem;
    query.attributes.query = JSON.parse(newQueryStr) as Record<string, unknown>;
  });
};
