import { createContext } from 'react';
import { DragDropContext, OnDragEndResponder, OnDragStartResponder } from 'react-beautiful-dnd';
import cloneDeep from 'lodash/cloneDeep';

import { useAlert } from 'contexts/alert/useAlert';
import { ContentItem, EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useTrash } from 'contexts/trash/useTrash';
import {
  ArticleAttributes,
  ArticleItem,
  CollectionDto,
  ExternalCollectionItem,
  ListContainer,
  ModuleContainer,
  NewsletterPageContainer,
  PageType,
  SectionAttributes
} from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import {
  addExcludedContentToQuery,
  getAllessehJsonQuery,
  removeExcludedContentFromQuery,
  setJsonQueryStr
} from 'utils/collection';
import { DROPPABLE_IDS } from 'utils/collectionDragUtils';
import { isItemAllowedInModule } from 'utils/modules';
import { safelyParseContent } from 'utils/temp';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES } from './dragUtils';

type SuffixOptions = {
  hierarchyId?: string | undefined;
  content?: AllessehContent | undefined;
  collection?: CollectionDto | undefined;
};

interface DragAndDropContext {
  generateDraggableId: (draggablePrefix: string, suffix: SuffixOptions) => string;
}

const DEFAULT_DRAG_AND_DROP_CONTEXT: DragAndDropContext = {
  generateDraggableId: () => ''
};

export const DragAndDropContext = createContext(DEFAULT_DRAG_AND_DROP_CONTEXT);

export const DragAndDropProvider: FCC = ({ children }) => {
  const { alertError } = useAlert();
  const {
    fromAllessehContent,
    generateHierarchyIdFromDragEvent,
    getEntity,
    insertEntity,
    swapEntities,
    removeEntity,
    isItemInDataModel,
    root,
    setNewRoot,
    modifyEntity
  } = useDataModelContext();
  const { setIsDragging } = useTrash();

  const handleDragStart: OnDragStartResponder = (dragEvent) => {
    // when an item currently in a module is being dragged
    // update the isDragging context so that the droppable trash area becomes active
    const regexDroppable = new RegExp(
      `^${DROPPABLE_ID_PREFIXES.MODULE_ITEMS}|${DROPPABLE_ID_PREFIXES.BANNER_MODULE_ITEMS}|${DROPPABLE_ID_PREFIXES.OFF_PLATFORM}|${DROPPABLE_ID_PREFIXES.COLLECTION_ITEMS}`
    );
    const regexDraggable = new RegExp(`^${DRAGGABLE_PREFIXES.MODULE_ITEM}|${DRAGGABLE_PREFIXES.COLLECTION_ITEM}`);

    if (dragEvent.draggableId.match(regexDraggable) && dragEvent.source.droppableId.match(regexDroppable)) {
      setIsDragging(true);
    }
  };

  const handleReinclude = (sourceIndex: number) => {
    if (root.type === EntityType.List) {
      const rootListContainer = root as ListContainer;
      const articleAttribute = rootListContainer.collection[sourceIndex].attributes as ArticleAttributes;
      const allessehContentId = articleAttribute.id;

      const jsonQueryStr = getAllessehJsonQuery(rootListContainer);
      const queryItemIndex = rootListContainer.collection.findIndex((item) => item.type === EntityType.Query);

      if (jsonQueryStr) {
        const parsedJsonQuery = removeExcludedContentFromQuery(allessehContentId, jsonQueryStr);
        setJsonQueryStr(JSON.stringify(parsedJsonQuery), queryItemIndex, modifyEntity);
      }
    }
  };

  const handleExclude = (allessehContentId: string) => {
    if (root.type === EntityType.List) {
      const rootListContainer = root as ListContainer;
      const jsonQueryStr = getAllessehJsonQuery(rootListContainer);
      const queryItemIndex = rootListContainer.collection.findIndex((item) => item.type === EntityType.Query);

      if (jsonQueryStr) {
        const parsedJsonQuery = addExcludedContentToQuery(allessehContentId, jsonQueryStr);
        setJsonQueryStr(JSON.stringify(parsedJsonQuery), queryItemIndex, modifyEntity);
      }
    }
  };

  const handleDragEnd: OnDragEndResponder = (dragEvent) => {
    setIsDragging(false);

    if (!dragEvent.destination) {
      return;
    }

    const {
      destination: { droppableId: destDroppableId, index: destIndex },
      source: { droppableId: sourceDroppableId, index: sourceIndex },
      draggableId
    } = dragEvent;

    if (destDroppableId.startsWith(DROPPABLE_ID_PREFIXES.MODULES)) {
      swapEntities(
        generateHierarchyIdFromDragEvent(sourceDroppableId, sourceIndex),
        generateHierarchyIdFromDragEvent(destDroppableId, destIndex)
      );
    } else if (destDroppableId.startsWith(DROPPABLE_ID_PREFIXES.MODULE_ITEMS)) {
      const destModule = getEntity<ModuleContainer>(destDroppableId)!;

      if (sourceDroppableId.startsWith(DROPPABLE_IDS.RESULTS)) {
        const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));

        if (!isItemAllowedInModule(destModule, item, alertError)) return;

        const pageType = (root.attributes as SectionAttributes).pageType as PageType;

        if (pageType === PageType.Mobile && item.type === EntityType.Article) {
          item.subtype = 'MobileArticle';
        }

        insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, destIndex), item);
      } else if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.COLLECTION)) {
        const item = safelyParseContent<CollectionDto>(draggableId.replace(/.+?#/, ''));
        const externalCollectionItem: ExternalCollectionItem = {
          type: EntityType.Collection,
          attributes: { id: item.metadata.allessehId, base_doc_id: item.metadata.allessehId, repo: 'Allesseh' },
          metadata: {
            id: item.metadata.allessehId,
            name: item.metadata.name,
            createdUtc: item.metadata.createdUtc,
            creatorUser: item.metadata.creatorUser,
            isArchived: item.metadata.isArchived,
            notes: item.metadata.notes ?? '',
            publicationKey: item.metadata.publicationKey,
            revisorUser: item.metadata.revisorUser ?? { djUserId: '' },
            updatedUtc: item.metadata.updatedUtc ?? 0
          },
          contentItems: item.root.collection
        };

        if (!isItemAllowedInModule(destModule, externalCollectionItem, alertError)) return;

        insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, destIndex), externalCollectionItem);
      } else if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.MODULE_ITEMS)) {
        const sourceItemHierarchyId = generateHierarchyIdFromDragEvent(sourceDroppableId, sourceIndex);
        const sourceItem = getEntity<ArticleItem>(sourceItemHierarchyId)!;

        if (!isItemAllowedInModule(destModule, sourceItem, alertError)) return;
        swapEntities(sourceItemHierarchyId, generateHierarchyIdFromDragEvent(destDroppableId, destIndex));
      } else if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.HISTORY)) {
        const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));

        if (!isItemAllowedInModule(destModule, item, alertError)) return;

        insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, destIndex), item);
      }
    } else if (destDroppableId.startsWith(DROPPABLE_ID_PREFIXES.COLLECTION_ITEMS)) {
      if (sourceDroppableId.startsWith(DROPPABLE_IDS.RESULTS)) {
        const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));
        const articleAttributes = item.attributes as ArticleAttributes;
        handleExclude(articleAttributes.id);
        insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, destIndex), item);
      } else if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.COLLECTION_ITEMS)) {
        swapEntities(
          generateHierarchyIdFromDragEvent(sourceDroppableId, sourceIndex),
          generateHierarchyIdFromDragEvent(destDroppableId, destIndex)
        );
      }
    } else if (destDroppableId.startsWith(DROPPABLE_ID_PREFIXES.OFF_PLATFORM)) {
      if (sourceDroppableId.startsWith(DROPPABLE_IDS.RESULTS)) {
        const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));

        insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, destIndex), item);
      }
      if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.OFF_PLATFORM)) {
        const sourceItemHierarchyId = generateHierarchyIdFromDragEvent(sourceDroppableId, sourceIndex);
        swapEntities(sourceItemHierarchyId, generateHierarchyIdFromDragEvent(destDroppableId, destIndex));
      }

      if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.HISTORY)) {
        const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));

        if (!isItemInDataModel(item)) {
          insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, destIndex), item);
        } else {
          alertError('The content was not added to the collection. It already exists in this collection.');
        }
      }
    } else if (destDroppableId.startsWith(DROPPABLE_ID_PREFIXES.NEWSLETTER)) {
      const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));

      if (sourceDroppableId.startsWith(DROPPABLE_IDS.RESULTS)) {
        const newsletter = cloneDeep(root) as NewsletterPageContainer;

        newsletter.collection[0].collection = [item];
        setNewRoot(newsletter);
      }
    } else if (destDroppableId.startsWith(DROPPABLE_ID_PREFIXES.BANNER_MODULE_ITEMS)) {
      const item = safelyParseContent<ContentItem>(draggableId.replace(/.+?#/, ''));

      if (sourceDroppableId.startsWith(DROPPABLE_IDS.RESULTS)) {
        // Remove the only article in the banner module, and insert the new one.
        // Effectively allowing only one article to be added to it.
        removeEntity(generateHierarchyIdFromDragEvent(destDroppableId, 0));
        insertEntity(generateHierarchyIdFromDragEvent(destDroppableId, 0), item);
      }
    } else if (destDroppableId.startsWith(DROPPABLE_IDS.TRASH)) {
      if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.NEWSLETTER)) {
        const newsletter = cloneDeep(root) as NewsletterPageContainer;

        newsletter.collection[0].collection = [];
        setNewRoot(newsletter);
      } else if (!sourceDroppableId.startsWith(DROPPABLE_IDS.RESULTS)) {
        if (sourceDroppableId.startsWith(DROPPABLE_ID_PREFIXES.COLLECTION_ITEMS)) {
          handleReinclude(dragEvent.source.index);
        }
        removeEntity(generateHierarchyIdFromDragEvent(sourceDroppableId, sourceIndex));
      }
    }
  };

  const generateDraggableId = (draggablePrefix: string, { hierarchyId, content, collection }: SuffixOptions) => {
    if (hierarchyId) {
      return draggablePrefix + hierarchyId;
    }

    if (content) {
      const articleItem = fromAllessehContent(content);
      return draggablePrefix + JSON.stringify(articleItem);
    }

    if (collection) {
      return draggablePrefix + JSON.stringify(collection);
    }

    throw new Error('Either a hierarchy ID or content or collection must be provided to generate a draggable ID');
  };

  const value = {
    generateDraggableId
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <DragAndDropContext.Provider value={value}>{children}</DragAndDropContext.Provider>
    </DragDropContext>
  );
};
