import { useState } from 'react';
import { Box, Button, FormHelperText, FormLabel, Group, Input, Overlay, useModalPortal } from '@screentone/core';

import { ResponsiveLoader } from 'components';

export type AddSectionPageInput = {
  routeKey: string;
  name: string;
};

interface AddSectionPageModalProps {
  sectionPageData: AddSectionPageInput;
  setSectionPageData: React.Dispatch<React.SetStateAction<AddSectionPageInput>>;
  isCreatingSection: boolean;
  onCreate: () => void;
  onDismiss: () => void;
}

export const AddSectionPageModal = ({
  sectionPageData,
  setSectionPageData,
  isCreatingSection,
  onCreate,
  onDismiss
}: AddSectionPageModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const [errors, setErrors] = useState<Record<keyof AddSectionPageInput, string>>({
    routeKey: '',
    name: ''
  });

  const setInputError = (key: string, message: string) => {
    setErrors({
      ...errors,
      [key]: message
    });
  };

  const updateInput = (key: keyof AddSectionPageInput, value: string) => {
    setSectionPageData((prev) => ({ ...prev, [key]: value }));

    if (!value) {
      setInputError(key, 'This field is required');
    } else {
      setInputError(key, '');
    }

    if (key === 'routeKey') {
      if (!/^\/[a-z]/i.test(value)) {
        setInputError(key, 'Route key must start with a single "/"');
      } else {
        setInputError(key, '');
      }
    }
  };

  const handleCreate = () => {
    onCreate();
    onDismiss();
  };

  const hasErrors = Object.values(errors).some((error) => error);
  const someEmpty = Object.values(sectionPageData).some((input) => !input);

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Overlay status="open" renderNode={renderNode}>
      <Box>
        <Box.Title>Add new section page</Box.Title>
        <Box.Content>
          <Group direction="column" gap="md">
            <FormLabel label="Route key (including trailing '/')" labelPosition="top" required>
              <Input
                value={sectionPageData.routeKey}
                error={!!errors.routeKey}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput('routeKey', e.target.value)}
                placeholder="/route-key"
              />
              <FormHelperText error>{errors.routeKey}</FormHelperText>
            </FormLabel>
            <FormLabel label="Name" labelPosition="top" required>
              <Input
                value={sectionPageData.name}
                error={!!errors.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput('name', e.target.value)}
                placeholder="Name of the page"
              />
              <FormHelperText error>{errors.name}</FormHelperText>
            </FormLabel>
          </Group>
          <Group gap="sm" align="stretch" margin={{ top: 'sm' }}>
            <Group.Item flex>
              <Button secondary fullWidth onClick={onDismiss}>
                Cancel
              </Button>
            </Group.Item>
            <Group.Item flex>
              {isCreatingSection ? (
                <ResponsiveLoader size="md" />
              ) : (
                <Button primary fullWidth onClick={handleCreate} disabled={hasErrors || someEmpty}>
                  Create
                </Button>
              )}
            </Group.Item>
          </Group>
        </Box.Content>
      </Box>
    </Overlay>
  );
};
