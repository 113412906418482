import { Route } from 'react-router-dom';
import '@screentone/core/css/index.css';
import '@screentone/addon-auth-wrapper/css/index.css';
import 'theme/global.scss';
import { ProtectedRoute } from '@screentone/addon-auth-wrapper';

import { AlertProvider } from 'contexts/alert/AlertContext';
import { AppQueryProvider } from 'data/query-client';
import { OktaRouter } from 'features/auth';
import BannerEditPage from 'pages/BannerEditPage';
import BannersPage from 'pages/BannersPage';
import CollectionEditPage from 'pages/CollectionEditPage';
import CollectionsPage from 'pages/CollectionsPage';
import HealthPage from 'pages/HealthPage';
import ItpEditPage from 'pages/ItpEditPage';
import ItpFeedPage from 'pages/ItpFeedPage';
import NewsletterEditPage from 'pages/NewsletterEditPage';
import NewsletterPage from 'pages/NewsletterPage';
import OffPlatformEditPage from 'pages/OffPlatformEditPage';
import OffPlatformPage from 'pages/OffPlatformPage';
import PagesPage from 'pages/PagesPage';
import PublicationSettingsPage from 'pages/PublicationSettingsPage';
import SavedNewsletterPage from 'pages/SavedNewsletterPage';
import ScheduledNewsletterPage from 'pages/ScheduledNewsletterPage';
import SectionPageEditPage from 'pages/SectionPageEditPage';

const Routing = () => (
  <OktaRouter>
    <Route path="/_health" element={<HealthPage />} />
    <Route
      path="/:property/settings"
      element={
        <ProtectedRoute path="/:property/settings" restricted>
          <PublicationSettingsPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/collections"
      element={
        <ProtectedRoute path="/:property/collections">
          <CollectionsPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/collections/:idCreatedUtc"
      element={
        <ProtectedRoute path="/:property/collections/:idCreatedUtc">
          <CollectionEditPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/issues"
      element={
        <ProtectedRoute path="/:property/issues">
          <ItpFeedPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/issues/:issueDate"
      element={
        <ProtectedRoute path="/:property/issues/:issueDate">
          <ItpEditPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/off-platform"
      element={
        <ProtectedRoute path="/:property/off-platform">
          <OffPlatformPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/off-platform/:idCreatedUtc"
      element={
        <ProtectedRoute path="/:property/off-platform/:idCreatedUtc">
          <OffPlatformEditPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/pages/:allessehIdOrSlug"
      element={
        <ProtectedRoute path="/:property/pages/:allessehIdOrSlug">
          <SectionPageEditPage />
        </ProtectedRoute>
      }
    >
      <Route path="version/:publishUtc" element={<></>} />
    </Route>
    <Route
      path="/:property"
      element={
        <ProtectedRoute path="/:property">
          <PagesPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/newsletters"
      element={
        <ProtectedRoute path="/:property">
          <NewsletterPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/newsletters/:campaignId"
      element={
        <ProtectedRoute path="/:property">
          <SavedNewsletterPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/newsletters/scheduled/:campaignId/:publishUtc"
      element={
        <ProtectedRoute path="/:property">
          <ScheduledNewsletterPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/newsletters/new"
      element={
        <ProtectedRoute path="/:property">
          <NewsletterEditPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/banners"
      element={
        <ProtectedRoute path="/:property/banners">
          <BannersPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/:property/banners/:allessehId"
      element={
        <ProtectedRoute path="/:property/banners/:allessehId">
          <BannerEditPage />
        </ProtectedRoute>
      }
    />
  </OktaRouter>
);

const App = () => (
  <AppQueryProvider>
    <AlertProvider>
      <Routing />
    </AlertProvider>
  </AppQueryProvider>
);

export default App;
