/* eslint-disable import/no-cycle */
import { Button, Divider, IconPlusCircle, Tooltip } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { AvailableLayoutModule, ModuleContainer, PageContainer } from 'data/generated/graphql';
import styles from './AddITPPageButton.module.scss';

interface AddITPPageButtonProps {
  addableUiModules?: AvailableLayoutModule[];
  availableTreatmentTypes?: string[];
  nextPageIndex: number;
  sectionIndex?: number;
}

export const AddITPPageButton = ({
  addableUiModules,
  availableTreatmentTypes,
  nextPageIndex,
  sectionIndex
}: AddITPPageButtonProps) => {
  const { insertEntity } = useDataModelContext();

  const handleAddPage = () => {
    if (!addableUiModules || (addableUiModules.length === 0 && !availableTreatmentTypes?.length)) {
      return;
    }

    const defaultModule =
      addableUiModules.find((module) => {
        const itpAllowedTreatmentTypes = [
          'ITP_12_STORIES',
          'ITP_2_STORIES',
          'ITP_2_STORIES_WITH_FEATURE_SPOT',
          'ITP_3_STORIES_WITH_FEATURE_SPOT',
          'ITP_5_STORIES_WITH_FEATURE_SPOT',
          'ITP_6_STORIES',
          'ITP_6_STORIES_WITH_FEATURE_SPOT'
        ];
        return module.defaultModule.uiModuleFields.basicModule?.allowedTreatmentTypes?.every((tt) =>
          itpAllowedTreatmentTypes.includes(tt)
        );
      })?.defaultModule ?? null;

    if (!defaultModule) return;

    const newModule: ModuleContainer = {
      type: 'Module',
      attributes: { pageModule: defaultModule },
      collection: []
    };

    const newPage: PageContainer = {
      type: 'Page',
      // The following layoutId comes from this backend file: src/services/pageTypeSetting/settings/wsj/itp.ts
      attributes: { layoutId: 'c38abc64-a954-4045-9c7c-0ef935f22563' },
      collection: [newModule]
    };

    insertEntity(`0-${sectionIndex}-${nextPageIndex}`, newPage);
  };

  if (!addableUiModules || (addableUiModules.length === 0 && !availableTreatmentTypes?.length)) {
    return null;
  }

  return (
    <div className={styles.blockDivider}>
      <div className={styles.blockDividerContent}>
        <Divider className={styles.blockDividerDivider} />
        <Tooltip>
          <Tooltip.Content position={nextPageIndex === 0 ? 'bottom' : 'top'}>Add a Module Here</Tooltip.Content>
          <Tooltip.Trigger>
            <Button
              tertiary
              icon={IconPlusCircle as SvgComponent}
              onClick={handleAddPage}
              data-testid="add-module-button"
            />
          </Tooltip.Trigger>
        </Tooltip>
        <Divider className={styles.blockDividerDivider} />
      </div>
    </div>
  );
};
