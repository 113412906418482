import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { Button, FormHelperText, Grid, Loader, Token, Typography, Wrapper } from '@screentone/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { WidePageWrapper } from 'components';
import CollectionArticle from 'components/datamodel/content/Article/subtypes/CollectionArticle/CollectionArticle';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { useDragAndDrop } from 'contexts/drag-and-drop/useDragAndDrop';
import {
  ListContainer,
  PublicationSettingSearchableContentType,
  QueryItem,
  usePagesDtoForCollectionIdQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes } from 'hooks/useAllessehContentQuery';
import { setJsonQueryStr } from 'utils/collection';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import styles from './CollectionEdit.module.scss';
import { CollectionCurateSection } from './components/collection-curate-section/CollectionCurateSection';
import { CollectionInfoBoxesSection } from './components/collection-info-boxes-section/CollectionInfoBoxesSection';
import { useCollectionPublish } from './hooks/useCollectionPublish';
import { SearchParams } from '../../components/search-content/SearchContent';

export const CollectionEdit = () => {
  const { idCreatedUtc = '' } = useParams();

  const { root, metadata, getAllArticleIds, fromAllessehContent, renderEntity, modifyEntity } =
    useDataModelContext<ListContainer>();
  const { generateDraggableId } = useDragAndDrop();
  const {
    hasCollectionChanged,
    notificationText,
    handleSave,
    isUpdating,
    handleCopy,
    isCopying,
    handleArchive,
    isArchiving,
    handleOffPlatformConvert,
    isConvertingToOffPlatform,
    waitingForAllesseh
  } = useCollectionPublish();
  const { renderActions } = useContextMenuActions();

  const currentProperty = useConvertedProperty();
  const { data: publicationSettingsResp } = usePublicationSettings();

  // search
  const articleIds = getAllArticleIds();
  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: articleIds
    }),
    [articleIds, publicationSettingsResp?.publicationSetting.defaultContentTypes]
  );
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: AllessehQuerySortKeyTypes.LiveDate
  });

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (publicationSettingsResp) {
      newSearchParams.filters.contentTypes = publicationSettingsResp.publicationSetting.defaultContentTypes;
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationSettingsResp]);

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (
      newSearchParams.filters.contentTypes.length > 0 && // ensure contentTypes list has loaded and has at least 1 item
      !isEqual(newSearchParams.filters.excludedContentIds, articleIds)
    ) {
      newSearchParams.filters.excludedContentIds = articleIds;
      setSearchParams(newSearchParams);
    }
  }, [articleIds, searchParams]);

  const handleSearchParamsChange = (newSearchParams: SearchParams) => {
    setSearchParams(newSearchParams);
  };

  const {
    data: pagesForCollectionData,
    isLoading: isLoad,
    fetchStatus
  } = usePagesDtoForCollectionIdQuery(
    { publicationKey: currentProperty!, collectionId: idCreatedUtc.replace(/_\d{13}$/, '') },
    { enabled: !!currentProperty && !!idCreatedUtc }
  );
  const pagesAreLoading = isLoad && fetchStatus !== 'idle';

  const queryItemIndex = root.collection.findIndex((item) => item.type === EntityType.Query);
  const queryItem = queryItemIndex !== -1 ? (root.collection[queryItemIndex] as QueryItem) : undefined;
  const queryBody = queryItem?.attributes.query;
  let allessehJsonQuery: string | null = null;
  if (queryBody) {
    allessehJsonQuery = JSON.stringify(queryBody);
  }

  const isArchived = 'isArchived' in metadata && metadata.isArchived;
  const draftSection = renderEntity(root, { hierarchyId: '' });

  const handleJsonQueryStr = (newQueryStr: string) => {
    setJsonQueryStr(newQueryStr, queryItemIndex, modifyEntity);
  };

  return (
    <PageRootContainer>
      <WidePageWrapper data-testid="collection-edit-container" padding={{ all: 'md' }}>
        <>
          <Wrapper
            data-testid="collection-edit-header"
            padding={{ top: 'md', bottom: 'md' }}
            className={styles.secondHeader}
          >
            <div>
              <Typography data-testid="collection-edit-title" variant="header2" margin={{ bottom: 'none' }}>
                {metadata.name}
              </Typography>
              {pagesForCollectionData && pagesForCollectionData.pagesDTOForCollectionId.length > 0 && (
                <Token data-testid="collection-edit-status" margin={{ left: 'sm' }} color="emerald">
                  In Use
                </Token>
              )}
              {notificationText && (
                <Typography data-testid="collection-edit-update" color="asphalt" margin={{ left: 'md' }}>
                  {notificationText}
                </Typography>
              )}
            </div>
            <div className={styles.saveButton}>
              <Button
                primary
                onClick={() => handleSave()}
                disabled={isArchived || isUpdating || !hasCollectionChanged}
                data-testid="collection-save-button"
              >
                {isUpdating && <Loader size="md" margin={{ right: 'md' }} />}
                Save
              </Button>
              {isArchived && !isUpdating && hasCollectionChanged && (
                <FormHelperText>You cannot update a collection when archived.</FormHelperText>
              )}
            </div>
          </Wrapper>
          <Wrapper data-testid="collection-edit-details" margin={{ top: 'sm' }}>
            <Grid>
              <Grid.Row xsCols={12}>
                <Grid.Col xs={3}>
                  <CollectionInfoBoxesSection
                    pagesForCollection={pagesForCollectionData}
                    pagesAreLoading={pagesAreLoading}
                    hasCollectionChanged={hasCollectionChanged}
                    isUpdating={isUpdating}
                    handleCopy={handleCopy}
                    isCopying={isCopying}
                    handleArchive={handleArchive}
                    isArchiving={isArchiving}
                    handleOffPlatformConvert={handleOffPlatformConvert}
                    isConvertingToOffPlatform={isConvertingToOffPlatform}
                    waitingForAllesseh={waitingForAllesseh}
                  />
                </Grid.Col>
                <Grid.Col xs={4}>{draftSection}</Grid.Col>
                <Grid.Col xs={5}>
                  <CollectionCurateSection
                    searchProps={{
                      defaultFilterState,
                      searchParams,
                      onSearchParamsChange: handleSearchParamsChange,
                      renderContentCard: (content: AllessehContent, index: number) => (
                        <Draggable
                          draggableId={generateDraggableId(DRAGGABLE_PREFIXES.COLLECTION_ITEM, { content })}
                          index={index}
                        >
                          {(draggableProvided, snapshot) => {
                            const articleItem = fromAllessehContent(content);
                            return (
                              <div
                                data-testid="collection-edit-card-content"
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                <CollectionArticle
                                  data={articleItem}
                                  isDragging={snapshot.isDragging}
                                  renderActions={() =>
                                    renderActions(MenuActionsKeys.ArticleSearch, {
                                      entity: articleItem,
                                      hierarchyId: `0-${root.collection.length - 1}`,
                                      extraProperties: {
                                        allessehContent: content,
                                        jsonQueryStr: allessehJsonQuery ?? undefined,
                                        setJsonQueryStr: handleJsonQueryStr
                                      }
                                    })
                                  }
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      )
                    }}
                    queryProps={{
                      allessehJsonQuery,
                      queryItemIndex,
                      totalItemsLength: root.collection.length
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Wrapper>
        </>
      </WidePageWrapper>
    </PageRootContainer>
  );
};
