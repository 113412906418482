import { Button, IconRepeat, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ExternalCollectionItem } from 'data/generated/graphql';

interface DetachFromCollectionProps {
  hierarchyId: string;
  collection: ExternalCollectionItem;
}

export const DetachFromCollection = ({ hierarchyId, collection }: DetachFromCollectionProps) => {
  const { insertEntity, removeEntity } = useDataModelContext();

  const handleDetachFromCollection = () => {
    const articleItems = collection.contentItems;

    removeEntity(hierarchyId);
    insertEntity(hierarchyId, articleItems);
  };

  return (
    <Wrapper>
      <Button
        tertiary
        icon={IconRepeat as SvgComponent}
        onClick={handleDetachFromCollection}
        disabled={collection.contentItems.length === 0}
        data-testid="collection-detach-from-collection"
      >
        Detach from Collection
      </Button>
    </Wrapper>
  );
};
