import { FormLabel, Switch, Typography } from '@screentone/core';
import cloneDeep from 'lodash.clonedeep';

import {
  AvailableRequiredLayoutModule,
  ModuleContainer,
  PageContainer,
  PageModule,
  UiBasicOptionalModule,
  UiBasicOptionalTreatmentType,
  UiModule,
  UiTitlePinnedModule,
  UiTitlePinnedTreatmentType
} from 'data/generated/graphql';
import { getModuleFieldKey } from 'utils/modules';
import styles from './TopOptionalToggle.module.scss';

interface TopOptionalToggleProps {
  page: PageContainer;
  availableRequiredLayoutModules: AvailableRequiredLayoutModule[] | undefined;
  insertEntity: (hierarchyId: string, entity: ModuleContainer) => void;
  removeEntity: (hierarchyId: string) => void;
}

type ModuleFieldKey = Exclude<keyof UiModule, '__typename'>;

type OptionalModule =
  | { moduleField: 'titlePinnedModule'; optionalModule: UiTitlePinnedModule }
  | { moduleField: 'basicOptionalModule'; optionalModule: UiBasicOptionalModule }
  | null;

const OPTIONAL_TOP_ORDER = {
  pen: [UiBasicOptionalTreatmentType.AboveSuperHero, UiBasicOptionalTreatmentType.SuperHero],
  barrons: []
};

const OptionalFieldKey = {
  TitlePinnedModule: 'titlePinnedModule',
  BasicOptionalModule: 'basicOptionalModule'
} as const;

const getModule = (pageModule: PageModule): OptionalModule => {
  const { uiModuleType } = pageModule;
  const moduleField = getModuleFieldKey(uiModuleType);
  if (moduleField === OptionalFieldKey.BasicOptionalModule) {
    return { moduleField, optionalModule: pageModule.uiModuleFields.basicOptionalModule as UiBasicOptionalModule };
  }

  if (moduleField === OptionalFieldKey.TitlePinnedModule) {
    return {
      moduleField,
      optionalModule: pageModule.uiModuleFields.titlePinnedModule as UiTitlePinnedModule
    };
  }

  return null;
};

function getNewIndex(
  pageModules: ModuleContainer[],
  treatmentType: UiBasicOptionalTreatmentType | UiTitlePinnedTreatmentType
): number {
  const orderIdx = OPTIONAL_TOP_ORDER.pen.findIndex(
    (type: UiBasicOptionalTreatmentType | UiTitlePinnedModule) => type === treatmentType
  );
  const orderArray = OPTIONAL_TOP_ORDER.pen.map((type: UiBasicOptionalTreatmentType | UiTitlePinnedModule) => {
    const index = pageModules.findIndex((module) => {
      const pageModule = module.attributes.pageModule as PageModule;
      return pageModule.uiModuleFields.basicOptionalModule?.treatmentType === type;
    });
    return index;
  });

  if (orderArray.every((value) => value === -1)) return 0;
  if (orderArray.includes(orderIdx)) return orderIdx;
  return orderArray.findIndex((num) => num === -1);
}

const findTretmentType = (
  module: PageModule,
  treatmentType: UiBasicOptionalTreatmentType | UiTitlePinnedTreatmentType
) => {
  const optionalModule = getModule(module);
  if (optionalModule) {
    if (optionalModule.moduleField === OptionalFieldKey.BasicOptionalModule) {
      return optionalModule.optionalModule.allowedTreatmentTypes?.includes(
        treatmentType as UiBasicOptionalTreatmentType
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (optionalModule.moduleField === OptionalFieldKey.TitlePinnedModule) {
      return optionalModule.optionalModule.allowedTreatmentTypes?.includes(treatmentType as UiTitlePinnedTreatmentType);
    }
  }
  return false;
};

const TopOptionalToggle = ({
  page,
  availableRequiredLayoutModules,
  insertEntity,
  removeEntity
}: TopOptionalToggleProps) => {
  const handleAddModule = (
    event: { target: { checked: boolean } },
    moduleField: ModuleFieldKey,
    treatmentType: UiBasicOptionalTreatmentType | UiTitlePinnedTreatmentType
  ) => {
    const defaultModule = cloneDeep(
      availableRequiredLayoutModules?.find(
        (module) => (module.defaultModule.uiModuleFields[moduleField]?.treatmentType ?? '') === treatmentType
      )?.defaultModule
    );
    if (event.target.checked) {
      const moduleIndex = getNewIndex(page.collection, treatmentType);
      const newModule: ModuleContainer = {
        type: 'Module',
        attributes: { pageModule: defaultModule },
        collection: []
      };

      insertEntity(`0-0-${moduleIndex}`, newModule);
    } else {
      const index = page.collection.findIndex((module) =>
        findTretmentType(module.attributes.pageModule as PageModule, treatmentType)
      );
      removeEntity(`0-0-${index}`);
    }
  };

  return (
    <div className={styles.moduleList}>
      <div className={styles.container}>
        {availableRequiredLayoutModules?.map((module, i) => {
          const defaultOptionalModule = getModule(module.defaultModule);

          if (defaultOptionalModule) {
            const { optionalModule: optionalDefaultModule, moduleField } = defaultOptionalModule;
            const { treatmentType } = optionalDefaultModule;

            return (
              <div className={styles.moduleContainer} key={i} data-testid="page-enable-toggle-container">
                <Typography variant="label2" className={styles.text} data-testid="page-enable-toggle-label">
                  {module.moduleName.toUpperCase()}
                </Typography>
                <FormLabel
                  key="Enable"
                  label="Enable"
                  labelPosition="right"
                  className={styles.text}
                  data-testid="page-enable-toggle"
                >
                  <Switch
                    data-testid="page-enable-toggle-switch"
                    name={module.moduleName}
                    id={module.moduleName}
                    onChange={(e: { target: { checked: boolean } }) => {
                      handleAddModule(e, moduleField, treatmentType);
                    }}
                    checked={page.collection.some((module) =>
                      findTretmentType(module.attributes.pageModule as PageModule, treatmentType)
                    )}
                  />
                </FormLabel>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default TopOptionalToggle;
