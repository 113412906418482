import { Box, FormLabel, Input, Typography } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { Metadata, PageModule, UiTitleModule, UiTitlePinnedModule } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { getModuleFieldKey } from 'utils/modules';
import styles from './PageModuleTitle.module.scss';

interface PageModuleTitleProps {
  pageModule: PageModule;
  onChange: (newPageModule: PageModule) => void;
  viewOnly?: boolean;
}

const allowedModuleFields = ['titleModule', 'titlePinnedModule'];

const getModule = (pageModule: PageModule): UiTitlePinnedModule | UiTitleModule | null => {
  const { uiModuleType } = pageModule;
  const property = getModuleFieldKey(uiModuleType);
  if (allowedModuleFields.includes(property)) {
    return pageModule.uiModuleFields[property] as UiTitlePinnedModule | UiTitleModule;
  }

  return null;
};

export const PageModuleTitle = ({ pageModule, onChange, viewOnly }: PageModuleTitleProps) => {
  const { data: publicationSettingsResp } = usePublicationSettings();

  const module = getModule(pageModule);

  if (!module) return null;

  const allowedMeta = module.allowedMeta ?? [Metadata.Title, Metadata.Subtitle, Metadata.SubtitleUrl];

  const handleChangeStringField =
    (fieldKey: 'title' | 'titleUrl' | 'subtitle' | 'subtitleUrl') => (e: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = e.target;
      if (['titleUrl', 'subtitleUrl'].includes(fieldKey)) {
        const firstChar = value[0];
        const isEmpty = (firstChar === '/' && !value[1]) || value === '';
        if (isEmpty) {
          value = '';
        } else if (firstChar !== '/') {
          value = `/${value}`;
        }
      }
      const newPageModule = clonedeep(pageModule);
      const module = getModule(newPageModule);
      if (module) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        module[fieldKey] = value;
      }
      onChange(newPageModule);
    };

  return (
    <>
      {(allowedMeta.includes(Metadata.Title) || allowedMeta.includes('title' as Metadata)) && (
        <FormLabel key="title" label="Title" fullWidth>
          <Input
            placeholder="Enter title"
            value={module.title}
            margin={{ bottom: 'sm' }}
            onChange={handleChangeStringField('title')}
            disabled={viewOnly}
            data-testid="page-enter-title"
          />
        </FormLabel>
      )}
      {(allowedMeta.includes(Metadata.TitleUrl) || allowedMeta.includes('titleUrl' as Metadata)) && (
        <FormLabel key="titleUrl" label="Title URL Path" fullWidth margin={{ top: 'sm' }}>
          <div className={styles.subtitleUrlForm}>
            <Box className={styles.baseRouteBox} padding={{ left: 'xs', right: 'xs' }}>
              <Typography>{publicationSettingsResp?.publicationSetting.baseRoute}/</Typography>
            </Box>
            <Input
              placeholder="Enter title url path"
              value={module.titleUrl?.slice(1)}
              margin={{ bottom: 'sm' }}
              onChange={handleChangeStringField('titleUrl')}
              disabled={viewOnly}
              data-testid="page-enter-title-url"
            />
          </div>
        </FormLabel>
      )}
      {(allowedMeta.includes(Metadata.Subtitle) || allowedMeta.includes('subtitle' as Metadata)) &&
        'subtitle' in module && (
          <FormLabel key="subtitle" label="Subtitle" fullWidth margin={{ top: 'sm' }}>
            <Input
              placeholder="Enter subtitle"
              value={module.subtitle}
              margin={{ bottom: 'sm' }}
              onChange={handleChangeStringField('subtitle')}
              disabled={viewOnly}
              data-testid="page-enter-subtitle"
            />
          </FormLabel>
        )}
      {(allowedMeta.includes(Metadata.SubtitleUrl) || allowedMeta.includes('subtitleUrl' as Metadata)) &&
        'subtitleUrl' in module && (
          <FormLabel key="subtitleUrl" label="Subtitle URL Path" fullWidth margin={{ top: 'sm' }}>
            <div className={styles.subtitleUrlForm}>
              <Box className={styles.baseRouteBox} padding={{ left: 'xs', right: 'xs' }}>
                <Typography>{publicationSettingsResp?.publicationSetting.baseRoute}/</Typography>
              </Box>
              <Input
                placeholder="Enter subtitle url path"
                value={module.subtitleUrl?.slice(1)}
                margin={{ bottom: 'sm' }}
                onChange={handleChangeStringField('subtitleUrl')}
                disabled={viewOnly}
                data-testid="page-enter-subtitle-url"
              />
            </div>
          </FormLabel>
        )}
    </>
  );
};

// value={module.subtitleUrl ? module.subtitleUrl.slice(1) : ''}
