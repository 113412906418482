import { Button, IconCode2, Wrapper } from '@screentone/core';

import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { QueryItem } from 'data/generated/graphql';
import { DEFAULT_QUERY_JSON } from 'hooks/useAllessehContentQuery';

interface AddQueryProps {
  hierarchyId: string;
  maxAllowedItems: number;
  numTotalItemsUsed: number;
}

export const AddQuery = ({ hierarchyId, maxAllowedItems, numTotalItemsUsed }: AddQueryProps) => {
  const { insertEntity } = useDataModelContext();

  const allowAddContent = numTotalItemsUsed < maxAllowedItems;

  const handleAddQuery = () => {
    const queryItem: QueryItem = {
      type: EntityType.Query,
      attributes: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: JSON.parse(DEFAULT_QUERY_JSON)
      }
    };
    insertEntity(hierarchyId, queryItem);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconCode2 as SvgComponent} onClick={handleAddQuery} disabled={!allowAddContent}>
        Add query
      </Button>
    </Wrapper>
  );
};
