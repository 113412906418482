import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Tabs as TabsComponent, Wrapper } from '@screentone/core';
import isEqual from 'lodash.isequal';

import { ResetDefault } from 'components/artifact-settings/actions/ResetDefault';
import { Settings } from 'components/artifact-settings/Settings';
import { Article } from 'components/datamodel/content/Article/Article';
import { PublishHistory } from 'components/publish-history/PublishHistory';
import { SearchParams } from 'components/search-content/SearchContent';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { useDragAndDrop } from 'contexts/drag-and-drop/useDragAndDrop';
import {
  BannerDto,
  BannerModuleContainer,
  PublicationSettingSearchableContentType,
  useBannerByIdQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes } from 'hooks/useAllessehContentQuery';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import styles from '../../BannerEdit.module.scss';
import ContentSearch from '../content-search/ContentSearch';

enum Tabs {
  ContentSearch,
  PublishHistory,
  Settings
}

export { Tabs as BannerTabs };

interface MultiTabSectionProps {
  hasRecentlyPublishedScheduledContent: boolean;
  setTabToChangeTo: React.Dispatch<React.SetStateAction<Tabs>>;
  tabToChangeTo: Tabs;

  isInEditMode: boolean;
  isRecentlyPublished: boolean;
  isRecentlyUpdated: boolean;
  handleShowHistoryEditMode: (historyItem: BannerDto) => void;
  savedForScheduleEdit: BannerDto | null;
  handlePreview: (banner?: BannerDto | undefined) => Promise<void>;
  isPerformingAction: boolean;
}

const BannerMultitabSection = ({
  hasRecentlyPublishedScheduledContent,
  setTabToChangeTo,
  tabToChangeTo,

  isInEditMode,
  isRecentlyPublished,
  isRecentlyUpdated,
  handleShowHistoryEditMode,
  savedForScheduleEdit,
  handlePreview,
  isPerformingAction
}: MultiTabSectionProps) => {
  const { metadata, fromAllessehContent, getDTO, getAllArticleIds, removeAllByType } =
    useDataModelContext<BannerModuleContainer>();
  const currentProperty = useConvertedProperty();
  const banner = getDTO() as BannerDto;
  const [liveVersion, setLiveVersion] = useState(banner);
  const [tabIndex, setTabIndex] = useState(Tabs.ContentSearch);

  const {
    data,
    isFetching,
    refetch: refetchLiveBanner
  } = useBannerByIdQuery({ id: metadata.allessehId, publicationKey: currentProperty! }, { enabled: false });

  const setTabIndexWrapper = (index: Tabs) => {
    setTabToChangeTo(Tabs.ContentSearch);
    setTabIndex(index);
  };

  useEffect(() => {
    if (!isFetching && data) {
      setLiveVersion(data.getBannerById as BannerDto);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (isRecentlyPublished || hasRecentlyPublishedScheduledContent) {
      void refetchLiveBanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecentlyPublished, hasRecentlyPublishedScheduledContent, refetchLiveBanner]);

  const { generateDraggableId } = useDragAndDrop();
  const { renderActions } = useContextMenuActions();
  const { data: publicationSettingsResp } = usePublicationSettings();

  const usedIds = getAllArticleIds();

  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: usedIds
    }),
    [usedIds, publicationSettingsResp]
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: AllessehQuerySortKeyTypes.LiveDate
  });

  useEffect(() => {
    if (publicationSettingsResp) {
      setSearchParams((currentParams) => ({
        ...currentParams,
        filters: {
          ...currentParams.filters,
          contentTypes: publicationSettingsResp.publicationSetting.defaultContentTypes
        }
      }));
    }
  }, [publicationSettingsResp]);

  useEffect(() => {
    if (searchParams.filters.contentTypes.length > 0 && !isEqual(searchParams.filters.excludedContentIds, usedIds)) {
      setSearchParams((currentParams) => ({
        ...currentParams,
        filters: {
          ...currentParams.filters,
          excludedContentIds: usedIds
        }
      }));
    }
  }, [usedIds, searchParams]);

  useEffect(() => {
    if (tabToChangeTo && tabToChangeTo !== tabIndex) {
      setTabIndex(tabToChangeTo);
    }
  }, [tabToChangeTo, tabIndex]);

  const handleResetToDefault = () => {
    removeAllByType(EntityType.Article);
  };

  return (
    <Wrapper>
      <TabsComponent
        role="tablist"
        onChange={setTabIndexWrapper}
        value={tabIndex}
        margin={{ bottom: 'md' }}
        data-testid="banner-tab"
      >
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="banner-page-content-search-tab"
        >
          Content Search
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.PublishHistory ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          data-testid="banner-publish-history-tab"
        >
          Publish History
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
          data-testid="page-settings-tab"
        >
          Settings
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.ContentSearch && (
        <Wrapper
          id="tabpanel-id0"
          role="tabpanel"
          aria-labelledby="tab-id0"
          padding={{ top: 'sm' }}
          data-testid="banner-content-header"
        >
          <ContentSearch
            searchProps={{
              defaultFilterState,
              searchParams,
              resultListClassName: styles.curateScrollable,
              onSearchParamsChange: setSearchParams,
              renderContentCard: (content: AllessehContent, index: number) => (
                <Draggable draggableId={generateDraggableId(DRAGGABLE_PREFIXES.MODULE_ITEM, { content })} index={index}>
                  {(draggableProvided) => {
                    const articleItem = fromAllessehContent(content);

                    return (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <Article
                          data={articleItem}
                          renderActions={() =>
                            renderActions(MenuActionsKeys.ArticleSearch, {
                              entity: articleItem
                            })
                          }
                        />
                      </div>
                    );
                  }}
                </Draggable>
              )
            }}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.PublishHistory && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <PublishHistory<BannerDto>
            liveVersion={liveVersion}
            publishUtc={0}
            hasRecentlyPublished={isRecentlyUpdated}
            originalVersionWhenInHistoryEditMode={savedForScheduleEdit}
            currentlyEditedVersion={isInEditMode ? banner : undefined}
            handleShowHistoryEditMode={(historyItem) => handleShowHistoryEditMode(historyItem as BannerDto)}
            scrollableSectionClassName={styles.historyScrollable}
            handlePreviewClick={(selectedVersion) => handlePreview(selectedVersion)}
            isPreviewLoading={isPerformingAction}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.Settings && (
        <Wrapper id="tabpanel-id3" role="tabpanel" aria-labelledby="tab-id3" padding={{ top: 'sm' }}>
          <Settings>
            <ResetDefault handleResetToDefault={handleResetToDefault} />
          </Settings>
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default BannerMultitabSection;
