import { Button, IconPlus, Wrapper } from '@screentone/core';

import { EntityComponent } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { addExcludedContentToQuery } from 'utils/collection';

interface AddEntityProps {
  hierarchyId: string;
  entity: EntityComponent;
  textDisplay?: string;
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const AddEntity = ({
  hierarchyId,
  entity,
  textDisplay = 'Add to collection',
  allessehContent,
  jsonQueryStr,
  setJsonQueryStr
}: AddEntityProps) => {
  const { insertEntity } = useDataModelContext();

  const handleExclude = () => {
    if (jsonQueryStr) {
      const parsedJsonQuery = addExcludedContentToQuery(allessehContent.data.id, jsonQueryStr);
      setJsonQueryStr(JSON.stringify(parsedJsonQuery));
    }
  };

  const handleMove = () => {
    handleExclude();
    insertEntity(hierarchyId, entity);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconPlus as SvgComponent} onClick={handleMove} data-testid="page-add-collection">
        {textDisplay}
      </Button>
    </Wrapper>
  );
};
