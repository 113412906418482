import { Button, IconCode } from '@screentone/core';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import EditAllesehModuleIdModal from './EditAllesehModuleIdModal';

interface EditAllesehModuleIdButtonProps {
  hierarchyId: string | undefined;
  moduleId: string | null | undefined;
}

const EditAllesehModuleIdButton = ({ hierarchyId, moduleId }: EditAllesehModuleIdButtonProps) => {
  const hasModuleId = typeof moduleId === 'string';

  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();

  const handleAddModuleId = () => {
    setIsModalOpen(true);

    setActionModalComponent(<EditAllesehModuleIdModal hierarchyId={hierarchyId} moduleId={moduleId} />);
  };

  return (
    <Button tertiary icon={IconCode as SvgComponent} onClick={handleAddModuleId} data-testid="page-draft-add-module-id">
      {hasModuleId ? 'Edit module ID' : 'Add module ID'}
    </Button>
  );
};

export default EditAllesehModuleIdButton;
