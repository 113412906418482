import { createContext, ReactNode, useEffect, useState } from 'react';

import { PageModule, UiModuleType } from 'data/generated/graphql';
import noop from 'utils/noop';

export interface PageModuleValidationError {
  uiModuleType: UiModuleType;
  numUsedItems: number;
  minRequiredItems: number;
  pathToModule: string;
}

export interface PageValidationError {
  moduleErrors: PageModuleValidationError[];
  modulesWithValidationError: PageModule[];
  pageName: string;
  pagePublishUtc: number;
}

export interface ITPIssueValidationError {
  issueDate: string;
  pageErrors: PageValidationError[];
}

export interface PageError {
  message: ReactNode;
  validationError?: PageValidationError;
}

export interface IssueError {
  message: ReactNode;
  validationError?: ITPIssueValidationError;
}

interface AlertState {
  message: ReactNode | null;
  type: string | null;
  autoDismiss?: boolean;
  wrapNewlines?: boolean;
  pageValidationError?: PageValidationError;
  issueValidationError?: ITPIssueValidationError;
}

export interface AlertOptions {
  autoDismiss?: boolean;
  wrapNewlines?: boolean;
}

interface AlertContext {
  alertState: AlertState;
  removeAlert: () => void;
  alertSuccess: (message: ReactNode, options?: AlertOptions) => void;
  alertWarning: (message: ReactNode, options?: AlertOptions) => void;
  alertError: (message: ReactNode, options?: AlertOptions) => void;
  alertModules: (pageValidationError: PageValidationError) => void;
  alertIssueModules: (issueValidationError: ITPIssueValidationError) => void;
  moduleHasValidationError: (moduleHierarchyId: string) => boolean;
}

const noopAlert: (message: ReactNode) => void = () => {
  /* do nothing */
};

const DEFAULT_ALERT_STATE: AlertState = {
  message: null,
  type: null,
  wrapNewlines: false
};

const DEFAULT_ALERT_CONTEXT: AlertContext = {
  alertState: DEFAULT_ALERT_STATE,
  removeAlert: noop,
  alertSuccess: noopAlert,
  alertWarning: noopAlert,
  alertError: noopAlert,
  alertModules: noop,
  alertIssueModules: noop,
  moduleHasValidationError: () => false
};

export const AlertContext = createContext(DEFAULT_ALERT_CONTEXT);

export const AlertProvider: FCC = ({ children }) => {
  const [alertState, setAlertState] = useState(DEFAULT_ALERT_STATE);
  const handleRemoveAlert = () => {
    setAlertState(DEFAULT_ALERT_STATE);
  };

  const alert = (type: string) => (message: ReactNode, options?: AlertOptions) => {
    setAlertState({
      message,
      type,
      ...options
    });
  };

  const alertModules = (pageValidationError: PageValidationError) => {
    setAlertState((prev) => ({ ...prev, pageValidationError }));
  };

  const alertIssueModules = (issueValidationError: ITPIssueValidationError) => {
    setAlertState((prev) => ({ ...prev, issueValidationError }));
  };

  const moduleHasValidationError = (moduleHierarchyId: string) => {
    const hasErrorFromPageValidation = alertState.pageValidationError?.moduleErrors.some(
      (error) => error.pathToModule === moduleHierarchyId
    );
    const hasErrorFromITPIssueValidation = alertState.issueValidationError?.pageErrors.some((error) =>
      error.moduleErrors.some((moduleError) => moduleError.pathToModule === moduleHierarchyId)
    );

    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return !!hasErrorFromPageValidation || !!hasErrorFromITPIssueValidation;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (alertState.type === 'success' && alertState.autoDismiss) {
      timeout = setTimeout(() => {
        handleRemoveAlert();
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [alertState]);

  const value = {
    alertState,
    removeAlert: handleRemoveAlert,
    alertSuccess: alert('success'),
    alertWarning: alert('warning'),
    alertError: alert('error'),
    alertModules,
    alertIssueModules,
    moduleHasValidationError
  };

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};
