import { Button, IconArrowUp, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ArticleItem } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { addExcludedContentToQuery } from 'utils/collection';

interface MoveQueryArticleToManualArticleProps {
  article: ArticleItem;
  hierarchyId: string;
  jsonQueryStr: string;
  allessehContent: AllessehContent;
  setJsonQueryStr: (newQueryStr: string) => void;
}

export const MoveQueryArticleToManualArticle = ({
  article,
  hierarchyId,
  jsonQueryStr,
  allessehContent,
  setJsonQueryStr
}: MoveQueryArticleToManualArticleProps) => {
  const { insertEntity } = useDataModelContext();

  const handleExclude = () => {
    if (jsonQueryStr) {
      const parsedJsonQuery = addExcludedContentToQuery(allessehContent.data.id, jsonQueryStr);
      setJsonQueryStr(JSON.stringify(parsedJsonQuery));
    }
  };

  const handleMove = () => {
    handleExclude();
    insertEntity(hierarchyId, article);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconArrowUp as SvgComponent} onClick={handleMove}>
        Move to Top of List
      </Button>
    </Wrapper>
  );
};
