import { Box, FormLabel, Group, Switch } from '@screentone/core';
import cloneDeep from 'lodash.clonedeep';

import { ResponsiveLoader } from 'components';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useItp } from 'contexts/itp/useItp';
import { IssueContainer, IssueSectionContainer, ItpIssuePageType } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';

const ItpPagesList = () => {
  const { root } = useDataModelContext<IssueContainer>();
  const { updateIssue } = useItp();
  const { data: publicationSettings, isLoading, fetchStatus } = usePublicationSettings();
  const requiredPageTypes = new Set(publicationSettings?.publicationSetting.itpRequiredPageTypes);

  const handlePageHiddenCheckBox = (checked: boolean, pageIndex: number) => {
    const updatedRoot = cloneDeep(root);

    updatedRoot.collection[pageIndex].attributes.isHidden = !checked;

    updateIssue({ root: updatedRoot });
  };

  if (isLoading && fetchStatus === 'idle') return <ResponsiveLoader />;

  return (
    <Box data-testid="itp-section-header">
      <Box.Title>Sections</Box.Title>
      <Box.Content>
        {root.collection.map((section: IssueSectionContainer, pageIndex: number) => (
          <Group key={section.attributes.sectionLabel} data-testid="itp-sections-items">
            <FormLabel
              labelPosition="right"
              label={section.attributes.sectionLabel}
              margin={{ bottom: 'md' }}
              data-testid="itp-sections-item-labels"
            >
              <Switch
                disabled={requiredPageTypes.has(section.attributes.pageType as ItpIssuePageType)}
                checked={!section.attributes.isHidden}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePageHiddenCheckBox(e.target.checked, pageIndex)
                }
              />
            </FormLabel>
          </Group>
        ))}
      </Box.Content>
    </Box>
  );
};

export default ItpPagesList;
