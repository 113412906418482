/* eslint-disable import/no-cycle */
import { useEffect, useMemo } from 'react';
import { useHeaderData } from '@screentone/addon-auth-wrapper';
import { Box, Dropdown, IconThreeDotsVer, List, Typography, Wrapper } from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { DuplicateItemType } from 'contexts/content-id-tracking/ContentIdTrackingContext';
import { useContentIdTracking } from 'contexts/content-id-tracking/useContentIdTracking';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { PublicationSettingSearchableContentType, QueryItem } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, useAllessehContentQuery } from 'hooks/useAllessehContentQuery';
import { BaseQueryOrigin, getBaseQuery } from 'utils/contentType';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import { decodeHtmlEntities } from 'utils/text';
import styles from './Query.module.scss';
import { CommonContentProps } from '../commonContentProps';

export interface QueryProps extends CommonEntityProps, CommonContentProps {
  data: QueryItem;
}

export const Query = ({
  data: query,
  onContentLoaded,
  isDraft,
  availableContentSlots,
  parentHierarchyId,
  index,
  isHistory
}: QueryProps) => {
  const { state } = useHeaderData();
  const { generateHierarchyId, modifyEntity } = useDataModelContext();
  const { trackContentIds, ignoreContentIds, getDuplicateClassName } = useContentIdTracking();
  const { renderActions } = useContextMenuActions();
  const hierarchyId = generateHierarchyId(query, parentHierarchyId, index);
  const { trackContent, getAltSummFields } = useSummarianContext();
  const actionsType = isHistory ? MenuActionsKeys.HistoryQuery : MenuActionsKeys.DraftQuery;

  let numItemsToShow = availableContentSlots ?? 0;
  numItemsToShow = numItemsToShow >= 0 ? numItemsToShow : 0;

  const { data: publicationSettingsResp } = usePublicationSettings();
  const baseQuery = getBaseQuery({
    contentTypes: [PublicationSettingSearchableContentType.Article],
    publicationSettings: publicationSettingsResp?.publicationSetting,
    origin: BaseQueryOrigin.QUERY_BUILDER
  });
  const fullAllessehQuery = mergeAllessehQueryBodies(
    baseQuery,
    JSON.stringify(query.attributes.query),
    JSON.stringify({ count: numItemsToShow })
  );

  const {
    data,
    isLoading: isLoad,
    fetchStatus
  } = useAllessehContentQuery(fullAllessehQuery, {
    enabled: numItemsToShow > 0
  });
  const isLoading = isLoad && fetchStatus !== 'idle';

  const initContents = useMemo(
    () =>
      data
        ? data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x): x is AllessehContent => !!x)
        : [],
    [data]
  );

  useEffect(() => {
    initContents.forEach((content) => {
      trackContent(content);
    });
  }, [initContents, trackContent]);

  useEffect(() => {
    if (isDraft) {
      trackContentIds(
        hierarchyId,
        initContents.map((allessehContent) => allessehContent.data.id)
      );

      return () => {
        ignoreContentIds(
          hierarchyId,
          initContents.map((allessehContent) => allessehContent.data.id)
        );
      };
    }

    return () => {};
  }, [hierarchyId, ignoreContentIds, initContents, isDraft, trackContentIds]);

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(index!, initContents.length);
    }
  }, [index, initContents, onContentLoaded]);

  useEffect(() => {
    if (!isHistory) {
      modifyEntity(
        hierarchyId,
        (entity) => {
          const query = entity as QueryItem;
          query.attributes.query.count = numItemsToShow;
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchyId, numItemsToShow]);

  return (
    <Box data-model-hierarchy-id={hierarchyId} className={styles.cardContainer}>
      <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader}>
        <Typography size="md" weight="bold">
          Query
        </Typography>
        <Dropdown padding={{ all: 'none' }} position="right" trigger={<IconThreeDotsVer color="asphalt" />}>
          {renderActions(actionsType, {
            entity: query,
            hierarchyId,
            index,
            isHistory,
            extraProperties: { numItemsToShow, baseQuery }
          })}
        </Dropdown>
      </Wrapper>
      {isLoading && <ResponsiveLoader />}
      {!isLoading && (
        <Wrapper padding={{ right: 'sm', left: 'xs', bottom: 'sm' }}>
          {numItemsToShow <= 0 && <Typography size="sm">No items</Typography>}
          {initContents.length > 0 && (
            <List listStyle="bullet">
              {initContents.map((content, index) => {
                const duplicateClassName = isDraft
                  ? getDuplicateClassName(
                      state.headerConfig.currentTheme ?? 'light',
                      DuplicateItemType.QUERY,
                      content.data.id,
                      ['article']
                    )
                  : '';
                return (
                  <List.Item className={duplicateClassName} key={index} data-allesseh-id={content.data.id}>
                    <Typography variant="bodytext" className={styles.headline}>
                      {decodeHtmlEntities(getAltSummFields(content).headline)}
                    </Typography>
                  </List.Item>
                );
              })}
            </List>
          )}
          {!availableContentSlots && initContents.length > 0 && (
            <Typography variant="note" margin={{ left: 'sm' }}>
              + {(data?.pages[0].links?.total ?? 0) - numItemsToShow} more
            </Typography>
          )}
        </Wrapper>
      )}
    </Box>
  );
};
