import { useMemo } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';

import ContentTypeToken from 'components/content-type-token/ContentTypeToken';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';

const isOpinion = (content: AllessehContent) => content.data.attributes.section_name === 'Opinion';

const getType = (content: AllessehContent) => {
  if (content.data.attributes.product.includes('Live Coverage')) {
    return PublicationSettingSearchableContentType.LiveCoverage.toLowerCase();
  }
  if (content.data.attributes.product === 'Interactive Media') {
    return PublicationSettingSearchableContentType.Tappable.toLowerCase();
  }

  return content.data.type;
};

const getBrandType = (content: AllessehContent, publication: string): string | null => {
  if (isOpinion(content)) {
    return PublicationSettingSearchableContentType.Opinion.toLowerCase();
  }

  switch (content.data.attributes.product) {
    case 'MarketWatch.com':
      if (publication !== 'marketwatch') {
        return PublicationSettingSearchableContentType.Marketwatch.toLowerCase();
      }
      return null;

    case 'Barrons Blogs':
      if (publication !== 'penta') {
        return PublicationSettingSearchableContentType.Penta.toLowerCase();
      }
      return null;

    case 'Mansion Global':
      return PublicationSettingSearchableContentType.MansionGlobal.toLowerCase();

    case 'Agence France Presse':
      return PublicationSettingSearchableContentType.Afp.toLowerCase();

    case 'WSJ.com':
      if (publication !== 'wsj') {
        return PublicationSettingSearchableContentType.Wsj.toLowerCase();
      }
      return null;

    default:
      return null;
  }
};

const TypeAndBrandToken = ({ allessehContent }: { allessehContent: AllessehContent }) => {
  const { currentProperty } = useAuth();

  const mappedBrand = useMemo(
    () => getBrandType(allessehContent, currentProperty ?? ''),
    [allessehContent, currentProperty]
  );

  return (
    <>
      <ContentTypeToken type={getType(allessehContent)} size="mlg" margin={{ right: 'xs' }} color="gray" />
      {mappedBrand && (
        <ContentTypeToken
          type={mappedBrand}
          size="mlg"
          margin={{ right: 'xs' }}
          color="gray"
          data-testid="content-type-list"
        />
      )}
    </>
  );
};

export default TypeAndBrandToken;
