import { Button, Divider, Group, IconEye, Typography, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { BannerModuleContainer } from 'data/generated/graphql';
import styles from './BannerEdit.module.scss';
import BannerMultitabSection from './components/banner-multitab-section/BannerMultitabSection';
import PublishingButtons from './components/publishing-buttons/PublishingButtons';
import { useBannerPublish } from './hooks/useBannerPublish';

export const BannerEdit = () => {
  const { root, metadata, renderEntity, countContent, resetModelChanged } =
    useDataModelContext<BannerModuleContainer>();

  const {
    hasBannerChanged,
    isMutationInProgress,
    handlePublish,
    handlePreview,
    handleSchedule,
    handleReschedule,
    handleUpdateSchedule,
    handleCancelSchedule,
    showEditSchedule,
    hideEditSchedule,
    isInEditMode,
    savedForScheduleEdit,
    isRecentlyUpdated,
    isRecentlyPublish,
    hasRecentlyPublishedScheduledContent,
    setTabToChangeTo,
    soonToBePublishedScheduledContentPublishUtc,
    tabToChangeTo
  } = useBannerPublish();

  const handleScheduleClick = async (publishUtc: number) => {
    if (isInEditMode) {
      await handleReschedule(publishUtc);
    } else {
      await handleSchedule(publishUtc);
    }
  };

  const handleClickPublish = async () => {
    if (isInEditMode) {
      await handleUpdateSchedule();
    } else {
      await handlePublish();
    }

    resetModelChanged();
  };

  return (
    <>
      <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.topPageHeader}>
        <div>
          <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="banner-header-name">
            {metadata.name}
          </Typography>
        </div>
        <PublishingButtons
          soonToBePublishedScheduledContentPublishUtc={soonToBePublishedScheduledContentPublishUtc}
          hasBannerChanged={hasBannerChanged}
          isPerformingAction={isMutationInProgress}
          isInEditMode={isInEditMode}
          onPublish={handleClickPublish}
          onSchedule={handleScheduleClick}
          onCancelEdit={hideEditSchedule}
          onDelete={handleCancelSchedule}
        />
      </Wrapper>
      <Divider className={styles.horizontalDivider} />
      <Wrapper margin={{ top: 'sm' }}>
        <Group wrap={false} valign="start" gap="md" fullWidth fullHeight>
          <Group.Item flex>
            <Wrapper margin={{ bottom: 'sm' }} className={styles.moduleHeader}>
              <Wrapper>
                <Typography
                  variant="header3"
                  margin={{ bottom: 'none', top: 'mlg' }}
                  data-testid="banner-title-item-count"
                >
                  {countContent(root)}
                </Typography>
              </Wrapper>
              <Wrapper>
                <Button
                  tertiary
                  icon={IconEye as SvgComponent}
                  onClick={() => handlePreview()}
                  disabled={isMutationInProgress}
                  data-testid="banner-page-preview-button"
                >
                  Preview
                </Button>
              </Wrapper>
            </Wrapper>
            <Wrapper className={styles.moduleBackground}>{renderEntity(root, { hierarchyId: '' })}</Wrapper>
          </Group.Item>
          <div className={styles.verticalDivider} />
          <Group.Item flex margin={{ top: 'mlg' }}>
            <BannerMultitabSection
              hasRecentlyPublishedScheduledContent={hasRecentlyPublishedScheduledContent}
              setTabToChangeTo={setTabToChangeTo}
              tabToChangeTo={tabToChangeTo}
              isInEditMode={isInEditMode}
              handleShowHistoryEditMode={showEditSchedule}
              isRecentlyUpdated={isRecentlyUpdated}
              isRecentlyPublished={isRecentlyPublish}
              savedForScheduleEdit={savedForScheduleEdit}
              handlePreview={handlePreview}
              isPerformingAction={isMutationInProgress}
            />
          </Group.Item>
        </Group>
      </Wrapper>
    </>
  );
};
