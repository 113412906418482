/* eslint-disable import/no-cycle */
import { ReactNode, useState } from 'react';
import {
  Button,
  Dropdown,
  Group,
  IconThreeDotsVer,
  Typography,
  useDropdownState,
  useModalState,
  Wrapper
} from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { IssueSectionContainer } from 'data/generated/graphql';
import styles from './IssueSection.module.scss';
import { NameChangeDialog } from './NameChangeDialog';
import { IssuePage } from '../../Page/subtypes/IssuePage';

interface SectionProps extends CommonEntityProps {
  data: IssueSectionContainer;
}

export const IssueSection = ({ data: section, parentHierarchyId, index }: SectionProps) => {
  const [sectionLabel, setSectionLabel] = useState<string | undefined | null>(section.attributes.sectionLabel);

  const { generateHierarchyId } = useDataModelContext();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isDropdownOpen,
    setOpen: setIsDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen(isOpen: boolean): void; componentRef: ReactNode } = useDropdownState(); // eslint-disable-line @typescript-eslint/no-unsafe-call

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { open, openModal, closeModal }: { open: boolean; openModal(): void; closeModal(): void } = useModalState(); // eslint-disable-line @typescript-eslint/no-unsafe-call

  const hierarchyId = generateHierarchyId(section, parentHierarchyId, index);

  return (
    <Wrapper data-model-hierarchy-id={hierarchyId} margin={{ bottom: 'md' }}>
      <Wrapper className={styles.wrapper} margin={{ bottom: 'xs' }}>
        <Typography
          variant="header3"
          margin={{ bottom: 'none' }}
          className={styles.displayName}
          data-testid="itp-page-section-header"
        >
          {section.attributes.sectionLabel}
        </Typography>
        <Dropdown
          open={isDropdownOpen}
          onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
          componentRef={dropdownRef}
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
          margin={{ right: 'sm' }}
          data-testid="itp-page-ellipsis-dropdown"
        >
          <Group margin={{ all: 'md' }} gap="xs">
            <Button tertiary onClick={openModal} data-testid="itp-change-display-name">
              Change Display Name
            </Button>
          </Group>
        </Dropdown>
        {open && (
          <NameChangeDialog
            closeModal={closeModal}
            open={open}
            section={section}
            setSectionLabel={setSectionLabel}
            sectionLabel={sectionLabel}
          />
        )}
      </Wrapper>
      {section.collection.map((page, i) => (
        <IssuePage
          key={i}
          data={page}
          parentHierarchyId={hierarchyId}
          index={i}
          isFirst={i === 0}
          isLast={i === section.collection.length - 1}
          sectionIndex={index}
        />
      ))}
    </Wrapper>
  );
};
