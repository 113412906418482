import { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button, Dialog, FormHelperText, IconCode, IconCopy, IconPlus, IconTrash, Wrapper } from '@screentone/core';

import { QueryRuleForm } from 'components/query-rules-form/components/query-rule-form/QueryRuleForm';
import RuleBox from 'components/query-rules-form/components/rule-box/RuleBox';
import SortRule from 'components/query-rules-form/components/sort-rule/SortRule';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import {
  AllessehContent,
  AllessehContentQueryBody,
  AllessehQuerySortKeyTypes,
  AllessehQuerySortOrderType,
  AllessehQuerySortRule
} from 'hooks/useAllessehContentQuery';
import { DRAGGABLE_PREFIXES, DROPPABLE_IDS } from 'utils/collectionDragUtils';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import styles from './QueryRulesForm.module.scss';
import {
  DEFAULT_QUERY_RULES,
  getAllessehQueryTermsFromQueryRules,
  getDefaultQueryRule,
  getQueryRulesFromAllessehQueryTerms,
  QueryRule,
  QueryRuleArrayNameType,
  QueryRuleFilterKey,
  QueryRuleFlags,
  QueryRules
} from './queryRulesFormUtils';

export interface QueryRulesFormProps {
  allessehJsonQuery?: string | null;
  fullAllessehJsonQueryOverride?: AllessehContentQueryBody | null;
  excludedData?: AllessehContent[] | undefined;
  onAllessehJsonQueryChange: ((newAllessehJsonQuery: string) => void) | null;
  queryRuleDisabledFlags?: QueryRuleFlags;
  queryRuleHiddenFlags?: QueryRuleFlags;
  queryRuleFilterKey?: QueryRuleFilterKey;
}

export const QueryRulesForm = ({
  allessehJsonQuery,
  onAllessehJsonQueryChange,
  excludedData,
  fullAllessehJsonQueryOverride,
  queryRuleDisabledFlags,
  queryRuleHiddenFlags,
  queryRuleFilterKey
}: QueryRulesFormProps) => {
  const [isQueryCodeDialogOpen, setIsQueryCodeDialogOpen] = useState(false);
  const { data: publicationSettingsResp } = usePublicationSettings();
  const currentProperty = useConvertedProperty();

  const canEdit = !!onAllessehJsonQueryChange;

  const queryRules = getQueryRulesFromAllessehQueryTerms(allessehJsonQuery);
  const fullAllessehQuery: AllessehContentQueryBody =
    fullAllessehJsonQueryOverride ??
    mergeAllessehQueryBodies(publicationSettingsResp?.publicationSetting.baseAllessehQuery, allessehJsonQuery);

  const usedSortKeys = queryRules.sort.map((sortRule) => sortRule.key);
  const availableSortKeys = Object.values(AllessehQuerySortKeyTypes).filter((key) => !usedSortKeys.includes(key));

  const handleContentQueryChange = (newQueryRules: QueryRules) => {
    const jsonQuery = getAllessehQueryTermsFromQueryRules(newQueryRules);
    onAllessehJsonQueryChange?.(jsonQuery);
  };

  const handleQueryRuleChange = (arrayName: QueryRuleArrayNameType, newRule: QueryRule, index: number) => {
    const newQueryRules = { ...queryRules };
    newQueryRules[arrayName] = [...newQueryRules[arrayName]];
    newQueryRules[arrayName][index] = newRule;
    handleContentQueryChange(newQueryRules);
  };

  const handleSortRuleChange = (newRule: AllessehQuerySortRule, index: number) => {
    const newQueryRules = { ...queryRules };
    newQueryRules.sort = [...newQueryRules.sort];
    newQueryRules.sort[index] = newRule;
    handleContentQueryChange(newQueryRules);
  };

  const handleAddQueryRule = (arrayName: QueryRuleArrayNameType) => () => {
    const newQueryRules = { ...queryRules };
    newQueryRules[arrayName] = [...newQueryRules[arrayName]];
    newQueryRules[arrayName].push(getDefaultQueryRule(currentProperty, arrayName, queryRuleFilterKey));
    handleContentQueryChange(newQueryRules);
  };

  const handleAddSortRule = () => {
    if (!availableSortKeys.length) {
      return;
    }
    const newQueryRules = { ...queryRules };
    newQueryRules.sort = [...newQueryRules.sort];
    newQueryRules.sort.push({
      key: availableSortKeys[0],
      order: AllessehQuerySortOrderType.DESC
    });
    handleContentQueryChange(newQueryRules);
  };

  const handleRemoveQueryRule = (arrayName: QueryRuleArrayNameType, index: number) => {
    const newQueryRules = { ...queryRules };
    newQueryRules[arrayName] = [...newQueryRules[arrayName]];
    newQueryRules[arrayName].splice(index, 1);
    handleContentQueryChange(newQueryRules);
  };

  const handleSortRemoveRule = (index: number) => {
    const newQueryRules = { ...queryRules };
    newQueryRules.sort = [...newQueryRules.sort];
    newQueryRules.sort.splice(index, 1);
    handleContentQueryChange(newQueryRules);
  };

  const handleClearQueryRules = () => {
    let newQueyRules = { ...DEFAULT_QUERY_RULES };

    newQueyRules = Object.keys(newQueyRules).reduce<QueryRules>((rules, ruleType) => {
      const key = ruleType as keyof QueryRules;
      if (queryRuleDisabledFlags?.[key]) {
        return { ...rules, [key]: queryRules[key] };
      }
      return rules;
    }, newQueyRules);

    handleContentQueryChange(newQueyRules);
  };

  const handleSortDragEnd = (result: DropResult) => {
    const newSortRules = [...queryRules.sort];
    const [removed] = newSortRules.splice(result.source.index, 1);
    if (result.destination?.index !== undefined) {
      newSortRules.splice(result.destination.index, 0, removed);
      handleContentQueryChange({ ...queryRules, sort: newSortRules });
    }
  };

  const ruleBoxes: { title: string; queryArrayType: QueryRuleArrayNameType }[] = [
    { title: 'Match ALL of these rules:', queryArrayType: QueryRuleArrayNameType.ALL },
    { title: 'Match ANY of these rules:', queryArrayType: QueryRuleArrayNameType.ANY },
    { title: 'EXCLUDE with the following rules:', queryArrayType: QueryRuleArrayNameType.NOT }
  ];

  const handleAllessehQueryCodeCopy = async () => {
    const copyInput = document.createElement('input');
    copyInput.value = JSON.stringify(fullAllessehQuery);
    copyInput.select();
    copyInput.setSelectionRange(0, 99999); // For mobile devices
    await navigator.clipboard.writeText(copyInput.value);
  };

  return (
    <Wrapper data-testid="query-rules-form-container" margin={{ top: 'sm' }} className={styles.wrapper}>
      {ruleBoxes.map((ruleBox) => {
        if (queryRuleHiddenFlags?.[ruleBox.queryArrayType] ?? false) {
          return <></>;
        }

        const canEditRule = canEdit && !(queryRuleDisabledFlags?.[ruleBox.queryArrayType] ?? false);

        return (
          <RuleBox key={ruleBox.queryArrayType} title={ruleBox.title}>
            {queryRules[ruleBox.queryArrayType].map((rule, index) => (
              <QueryRuleForm
                rule={rule}
                key={index}
                excludedData={excludedData}
                queryArrayType={ruleBox.queryArrayType}
                onChange={
                  canEditRule ? (newRule) => handleQueryRuleChange(ruleBox.queryArrayType, newRule, index) : null
                }
                onRemove={canEditRule ? () => handleRemoveQueryRule(ruleBox.queryArrayType, index) : null}
                fullAllessehQuery={fullAllessehQuery}
                queryRuleFilter={queryRuleFilterKey?.[ruleBox.queryArrayType]}
              />
            ))}
            {canEditRule && (
              <Button
                tertiary
                icon={IconPlus as SvgComponent}
                onClick={handleAddQueryRule(ruleBox.queryArrayType)}
                data-testid="query-rules-form-add-rule-button"
              >
                Add rule
              </Button>
            )}
          </RuleBox>
        );
      })}
      {!(queryRuleHiddenFlags?.sort ?? false) && (
        <RuleBox title="Sort results based on the following order:">
          {queryRules.sort.length > 0 && (
            <DragDropContext onDragEnd={handleSortDragEnd}>
              <Droppable droppableId={DROPPABLE_IDS.QUERY_SORTS}>
                {(droppableProvided) => (
                  <div
                    data-testid="query-rules-form-sort-order"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={styles.sortList}
                  >
                    {queryRules.sort.map((sortRule, index) => (
                      <Draggable
                        key={sortRule.key}
                        draggableId={`${DRAGGABLE_PREFIXES.QUERY_SORTS}_${sortRule.key}`}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            data-testid="query-rules-form-sort-order-container"
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <SortRule
                              rule={sortRule}
                              onChange={canEdit ? (newRule) => handleSortRuleChange(newRule, index) : null}
                              availableSortKeys={availableSortKeys}
                              onRemoveRule={canEdit ? () => handleSortRemoveRule(index) : null}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {canEdit && availableSortKeys.length > 0 && (
            <Button
              data-testid="query-rules-form-add-sorting-button"
              tertiary
              icon={IconPlus as SvgComponent}
              onClick={handleAddSortRule}
            >
              Add sorting condition
            </Button>
          )}
        </RuleBox>
      )}
      <Wrapper data-testid="query-rules-form-buttons" className={styles.buttonGroup}>
        <Button
          data-testid="query-rules-form-show-query-button"
          tertiary
          icon={IconCode as SvgComponent}
          onClick={() => setIsQueryCodeDialogOpen(true)}
        >
          Show query code
        </Button>
        {canEdit && (
          <Button
            data-testid="query-rules-form-clear-query-button"
            tertiary
            icon={IconTrash as SvgComponent}
            color="lava"
            onClick={handleClearQueryRules}
          >
            Clear query rules
          </Button>
        )}
      </Wrapper>
      <Dialog
        data-testid="query-rules-form-modal"
        status={isQueryCodeDialogOpen ? 'open' : 'closed'}
        onDismiss={() => setIsQueryCodeDialogOpen(false)}
      >
        <Dialog.Title data-testid="query-rules-form-modal-title">Query Code</Dialog.Title>
        <Dialog.Content data-testid="query-rules-form-modal-content">
          <FormHelperText data-testid="query-rules-form-alert-message">
            Any additional queries are from the base Allesseh queries for this publication.
          </FormHelperText>
          <pre data-testid="query-rules-form-json-parameters">{JSON.stringify(fullAllessehQuery, null, 2)}</pre>
          <Button
            data-testid="query-rules-form-copy-code-button"
            margin={{ top: 'md' }}
            tertiary
            icon={IconCopy as SvgComponent}
            onClick={handleAllessehQueryCodeCopy}
          >
            Copy Code
          </Button>
        </Dialog.Content>
      </Dialog>
    </Wrapper>
  );
};
