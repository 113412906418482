/**
 * Used to keep track of articles/items to highlight in an ITP issue
 * Creates a React context that provides the list of IDs
 * Has no logic beyond "add/remove/clear list of article IDs to highlight"
 */
import { createContext, useCallback, useState } from 'react';

export type HighlightState = string[];

export const HIGHLIGHT_GROUPS = {
  DEFAULT: 'default',
  ITP_NEW_ITEMS: 'itpNewItems',
  ITP_STALE_ITEMS: 'itpStaleItems'
};

export interface HighlightContextType {
  removeItem: (id: string, group?: string) => void; // remove single item by id
  addItem: (id: string, group?: string) => void; // add single item by id
  clearItems: (group?: string) => void; // remove all items
  addItems: (ids: string[], group?: string) => void;
  shouldHighlight: (id: string, group?: string) => boolean; // whether this id should be highlighted
}

const DEFAULT_HIGHLIGHT_CONTEXT: HighlightContextType = {
  addItem: () => [],
  addItems: () => [],
  removeItem: () => [],
  clearItems: () => [],
  shouldHighlight: () => false
};

export const HighlightContext = createContext<HighlightContextType>(DEFAULT_HIGHLIGHT_CONTEXT);

export const HighlightProvider: FCC = ({ children }) => {
  const [highlightState, setHighlightState] = useState<Map<string, Set<string>>>(new Map([['default', new Set()]]));

  const handleAddItem = useCallback((id: string, group = 'default') => {
    setHighlightState((prevHighlightState) => {
      const newState = new Map(prevHighlightState);
      newState.get(group)?.add(id);
      return newState;
    });
  }, []);

  const handleAddItems = useCallback((ids: string[], group = 'default') => {
    setHighlightState((prevHighlightState) => {
      const newState = new Map(prevHighlightState);
      const newSet = new Set([...(prevHighlightState.get(group) ?? []), ...ids]);
      newState.set(group, newSet);
      return newState;
    });
  }, []);

  const handleRemoveItem = useCallback((id: string, group = 'default') => {
    setHighlightState((prevHighlightState) => {
      const newState = new Map(prevHighlightState);
      newState.get(group)?.delete(id);
      return newState;
    });
  }, []);

  const handleClearItems = useCallback((group = 'default') => {
    setHighlightState((prevHighlightState) => {
      const newState = new Map(prevHighlightState);
      newState.set(group, new Set());
      return newState;
    });
  }, []);

  const handleShouldHighlight = useCallback(
    (id: string, group = 'default') => highlightState.get(group)?.has(id) ?? false,
    [highlightState]
  );

  const value = {
    removeItem: handleRemoveItem,
    addItem: handleAddItem,
    clearItems: handleClearItems,
    addItems: handleAddItems,
    shouldHighlight: handleShouldHighlight
  };

  return <HighlightContext.Provider value={value as HighlightContextType}>{children}</HighlightContext.Provider>;
};
