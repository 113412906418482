import { PublicationSetting } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { AltSummVariant } from './summarianReducer';

import { GUID_PATTERN } from 'components/altsumm-modal/components/media-input/MediaInput.utils';

export const createVideoUrl = (videoGuid: string, publicationSetting?: PublicationSetting) => {
  if (!GUID_PATTERN.test(videoGuid)) {
    return '';
  }
  return `${
    publicationSetting?.snippetyVideoDomain ?? 'https://video-api.wsj.com/api-video/player/v3/iframe.html?guid='
  }${videoGuid}`;
};

export const getAltSummForVariant = (content: AllessehContent, altSummVariant: AltSummVariant) =>
  content.data.attributes.alt_summaries?.find((altSummary: { variant?: string }) => {
    const variant = altSummary.variant !== 'Images' ? `|${altSummVariant}|` : altSummVariant;
    return altSummary.variant?.includes(variant);
  });

export const getDefaultHeadline = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const paragraphObjects = altSummary?.content?.filter((content) => content.type === 'paragraph');
  const mainTextContentObject = paragraphObjects?.find(
    (paragraphObject) =>
      Array.isArray(paragraphObject.content) &&
      paragraphObject.content.length > 1 &&
      paragraphObject.content.find((textContent) => textContent.type === 'headline')
  );
  const mainHeadlineObject = mainTextContentObject?.content?.find((content) => content.type === 'headline');
  return mainHeadlineObject?.content?.[0].text ?? content.data.attributes.headline?.text ?? '';
};

export const getDefaultBody = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const paragraphObjects = altSummary?.content?.filter((content) => content.type === 'paragraph');
  const mainParagraphContentObject = paragraphObjects?.find(
    (paragraphObject) =>
      Array.isArray(paragraphObject.content) &&
      paragraphObject.content.length === 1 &&
      paragraphObject.content.find((textContent) => !textContent.type && textContent.text)
  );
  return mainParagraphContentObject?.content?.[0]?.text ?? content.data.attributes.standfirst?.content?.[0].text ?? '';
};

export const getDefaultSubhead = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const subheadObject = altSummary?.content?.find(
    (content) => content.type === 'hed' && content.hed_type === 'subhead'
  );
  return subheadObject?.content?.[0].text ?? '';
};

export const getDefaultImage = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const summaryImageRef = altSummary?.content?.find((content) => content.type === 'image')?.ref;

  const summaryImageUrl = content.links?.related?.find((link) => link.id === summaryImageRef)?.properties?.location;
  const defaultSummaryImage = getAltSummForVariant(content, 'Images');
  const defaultSummaryImageRef = defaultSummaryImage?.content?.find((content) => content.type === 'image')?.ref;
  const defaultSummaryImageUrl = content.links?.related?.find((link) => link.id === defaultSummaryImageRef)?.properties
    ?.location;
  let fallbackAltSummImageUrl = '';
  if ((content.data.attributes.alt_summaries?.length ?? 0) < 1) {
    fallbackAltSummImageUrl = content.links?.related?.find((link) => link.type === 'image')?.properties?.location ?? '';
  }

  return summaryImageUrl ?? defaultSummaryImageUrl ?? fallbackAltSummImageUrl;
};

export const getVideoLink = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const containsAltSummaryWithVideo = !!altSummary?.content?.find((content) => content.type === 'video');
  return containsAltSummaryWithVideo
    ? content.links?.related?.find((linkData) => linkData.type === 'video')
    : undefined;
};

export const getDefaultVideoGuid = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const videoLink = getVideoLink(content, altSummVariant);
  return videoLink?.name ?? '';
};

export const getDefaultVideo = (
  content: AllessehContent,
  altSummVariant: AltSummVariant,
  publicationSettings?: PublicationSetting
) => {
  const guid = getVideoLink(content, altSummVariant)?.name ?? '';

  return publicationSettings?.snippetyVideoDomain && guid ? createVideoUrl(guid, publicationSettings) : '';
};

export const getDefaultBullets = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const bulletListObject = altSummary?.content?.find((content) => content.type === 'list');
  const bulletIds = bulletListObject?.content?.map((bulletContent) => bulletContent.content?.[0]?.ref) ?? [];
  const bullets: string[] = [];
  bulletIds.forEach((bulletId) => {
    content.links?.related
      ?.filter((link) => link.type === 'link')
      .forEach((bulletLink) => {
        const isBulletObjectInRelatedLinks = bulletId === bulletLink.id;
        if (isBulletObjectInRelatedLinks) {
          bullets.push(bulletLink.uri ?? '');
        } else {
          bullets.push(bulletId ?? '');
        }
      });
  });
  if (bullets.length > 0) {
    return bullets;
  }
  return content.links?.related?.filter((link) => link.type === 'link').map((link) => link.uri ?? '') ?? [];
};

export const getDefaultChartId = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const containsAltSummaryWithChart = !!altSummary?.content?.find(
    (content) => content.type === 'media' && content.ref?.includes('chart')
  );
  const chartLink = content.links?.related?.find(
    (linkData) => linkData.type === 'media' && linkData.media_type?.includes('chart')
  );
  return (containsAltSummaryWithChart && chartLink?.name) || '';
};

export const getDefaultChartMedia = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const chartAltSummObject = altSummary?.content?.find(
    (content) => content.type === 'media' && content.ref?.includes('chart')
  );
  const media = chartAltSummObject?.properties?.fallback_image?.display_variants.narrow.url;
  if (media) {
    return media;
  }
  const chartLink = content.links?.related?.find(
    (linkData) => linkData.type === 'media' && linkData.media_type?.includes('chart')
  );
  return chartLink?.properties?.fallback_image?.display_variants.narrow.url ?? '';
};

export const getDefaultIframeImage = (content: AllessehContent, altSummVariant: AltSummVariant) => {
  const altSummary = getAltSummForVariant(content, altSummVariant);
  const containsAltSummaryWithIframe = !!altSummary?.content?.find(
    (content) => content.type === 'inset' && content.inset_type === 'iframe'
  );
  if (containsAltSummaryWithIframe) {
    const iframeData = content.links?.related?.find(
      (linkData) => linkData.type === 'inset' && linkData.inset_type === 'iframe'
    );
    return iframeData?.properties?.fallback?.url ?? '';
  }
  return '';
};
