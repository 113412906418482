import { useMemo } from 'react';
import { Button, IconExternalLink, Tooltip } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { appEnv, buildLiveCoverageUrl, buildNewspressUrl, getLCArticleEnv } from 'utils/cms';

interface OpenInNewsPressProps {
  content: AllessehContent;
}

const OpenInCMS = ({ onClick, dataTestId }: { onClick: () => void; dataTestId?: string }) => (
  <Button
    data-testid={dataTestId ?? 'newspress-button-open-cms-button'}
    tertiary
    icon={IconExternalLink as SvgComponent}
    iconPosition="right"
    onClick={onClick}
  >
    Open in CMS
  </Button>
);

const OpenInCMSTooltip = ({
  onClick,
  dataTestId,
  disabled,
  message
}: {
  onClick: () => void;
  dataTestId?: string;
  message?: string;
  disabled?: boolean;
}) => (
  <Tooltip data-testid="edit-alt-summ-option-tooltip">
    <Tooltip.Content
      data-testid="edit-alt-summ-option-tooltip-content"
      id="altSumModalTooltip"
      position="bottom"
      size="lg"
    >
      {disabled && message}
    </Tooltip.Content>
    <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
      <Button
        data-testid={dataTestId ?? 'newspress-button-open-cms-button'}
        tertiary
        icon={IconExternalLink as SvgComponent}
        iconPosition="right"
        onClick={onClick}
        disabled={disabled}
      >
        Open in CMS
      </Button>
    </Tooltip.Trigger>
  </Tooltip>
);

export const OpenInNewsPress = ({ content }: OpenInNewsPressProps) => {
  const newspressUrl = useMemo(
    () => buildNewspressUrl(content.data.id, content.data.attributes.type),
    [content.data.id, content.data.attributes.type]
  );

  const liveCoverageUrl = useMemo(
    () => buildLiveCoverageUrl(content.data.attributes.source_url, content.data.attributes.product),
    [content.data.attributes.source_url, content.data.attributes.product]
  );

  const handleViewNewsPressUrl = () => {
    if (!newspressUrl) return;
    window.open(newspressUrl, '_blank');
  };

  const handleViewLiveCoverageUrl = () => {
    if (!liveCoverageUrl) return;
    window.open(liveCoverageUrl, '_blank');
  };

  const isLiveCoverage = content.data.attributes.product.includes('Live Coverage');
  const tooltipMessage = `Disabled: The article belongs to the ${getLCArticleEnv(
    content.data.attributes.product
  )} environment, which does not match the current ${appEnv} environment.`;

  return newspressUrl ? (
    // If has newspress URL, open in CMS
    <OpenInCMS onClick={handleViewNewsPressUrl} dataTestId="newspress-button-open-cms-button" />
  ) : isLiveCoverage ? (
    <OpenInCMSTooltip
      onClick={handleViewLiveCoverageUrl}
      dataTestId={
        !liveCoverageUrl ? 'livecoverage-button-open-cms-button-disabled' : 'livecoverage-button-open-cms-button'
      }
      disabled={!liveCoverageUrl}
      message={tooltipMessage}
    />
  ) : null;
};
