import { useContext } from 'react';

import { ItpContext } from './ItpContext';

export const useItp = () => {
  const context = useContext(ItpContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error('The `useItp` hook must be used within the context of the `ItpContextProvider`!');
  }

  return context;
};
