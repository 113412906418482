import { useRef } from 'react';
import { Dropdown, Group, IconEye } from '@screentone/core';

import { useItp } from 'contexts/itp/useItp';
import { DropdownContent } from './DropdownContent';

export const ItpPreviewDropdown = () => {
  const isFirstRender = useRef(true);

  const { handlePreviewPage, setPreviewIds } = useItp();

  return (
    <Dropdown
      trigger={
        <Group gap="sm">
          <IconEye />
          Preview
        </Group>
      }
      position="right"
      onClick={() => {
        if (isFirstRender.current) {
          handlePreviewPage();
          isFirstRender.current = false;
        } else {
          setPreviewIds([]);
          handlePreviewPage();
        }
      }}
    >
      <DropdownContent />
    </Dropdown>
  );
};

export default ItpPreviewDropdown;
