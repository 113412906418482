/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Typography, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';

import { ResponsiveLoader } from 'components';
import CollectionArticle from 'components/datamodel/content/Article/subtypes/CollectionArticle/CollectionArticle';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePublicationSettings } from 'hooks/publication-settings';
import {
  AllessehContent,
  AllessehContentQueryBody,
  AllessehQueryRule,
  useAllessehContentQuery
} from 'hooks/useAllessehContentQuery';
import { isNonEmptyQueryForAllessehQueryRules, mergeAllessehQueryBodies, validateValue } from 'utils/queryUtils';
import styles from './QueryRulesItems.module.scss';

interface QueryRulesItemsProps {
  allessehJsonQuery: string | null;
  lastArticleItemIndex: number;
  setJsonQueryStr: (newQueryStr: string) => void;
  onNumItemsLoaded: (numItemsFromQuery: number) => void;
}
export const QueryRulesItems = ({
  allessehJsonQuery,
  lastArticleItemIndex,
  setJsonQueryStr,
  onNumItemsLoaded
}: QueryRulesItemsProps) => {
  const { fromAllessehContent } = useDataModelContext();
  const { renderActions } = useContextMenuActions();
  const [debouncedQueryBody, setDebouncedQueryBody] = useState<AllessehContentQueryBody>({});
  const [hasQuery, setHasQuery] = useState(false);
  const { data: publicationSettingsResp } = usePublicationSettings();

  const debouncedUpdate = debounce((allessehJsonQuery?: string | null) => {
    const parsedAllessehQuery = allessehJsonQuery ? (JSON.parse(allessehJsonQuery) as AllessehContentQueryBody) : null;
    const fullAllessehQuery = mergeAllessehQueryBodies(
      JSON.stringify({ count: 10 }),
      publicationSettingsResp?.publicationSetting.baseAllessehQuery,
      allessehJsonQuery
    );
    setDebouncedQueryBody(fullAllessehQuery);
    setHasQuery(isNonEmptyQueryForAllessehQueryRules(parsedAllessehQuery));
  }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDebouncedQueryBody = useCallback(debouncedUpdate, [publicationSettingsResp]);

  useEffect(() => {
    updateDebouncedQueryBody(allessehJsonQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allessehJsonQuery]);

  const { data, isLoading: isLoad, fetchStatus } = useAllessehContentQuery(debouncedQueryBody, { enabled: hasQuery });
  const isLoading = isLoad && fetchStatus !== 'idle';
  const contents = useMemo(
    () =>
      data
        ? (data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x) => !!x) as AllessehContent[])
        : [],
    [data]
  );

  const { currentProperty } = useAuth();

  useEffect(() => {
    onNumItemsLoaded(contents.length);
  }, [contents, onNumItemsLoaded]);

  const parsedAllessehQuery = allessehJsonQuery ? (JSON.parse(allessehJsonQuery) as AllessehContentQueryBody) : null;

  const noResultsMessage = validateValue(
    currentProperty,
    parsedAllessehQuery?.query as { [key: string]: AllessehQueryRule[] }
  );

  if (!hasQuery) {
    return (
      <Wrapper margin={{ bottom: 'sm' }} data-testid="collection-no-query-rule">
        <Typography variant="note">No query rules set</Typography>
      </Wrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper padding={{ bottom: 'sm' }}>
        <ResponsiveLoader />
      </Wrapper>
    );
  }

  if (contents.length === 0) return <NoSearchResults title="Results" bodyText={noResultsMessage} />;

  return (
    <Wrapper className={styles.contentList} data-testid="query-content-list">
      {contents.map((content, index) => {
        const article = fromAllessehContent(content);
        return (
          <CollectionArticle
            key={article.attributes.id}
            data={article}
            index={index}
            renderActions={() =>
              renderActions(MenuActionsKeys.CollectionDraftQueryArticle, {
                entity: article,
                hierarchyId: `0-${lastArticleItemIndex + 1}`,
                index,
                extraProperties: {
                  allessehContent: content,
                  jsonQueryStr: allessehJsonQuery ?? undefined,
                  setJsonQueryStr
                }
              })
            }
          />
        );
      })}
    </Wrapper>
  );
};
