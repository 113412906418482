/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ModuleContainer, PublicationSettingSearchableContentType, UiBasicTreatmentType } from 'data/generated/graphql';

export const defaultNewsletterModuleContainer: ModuleContainer = {
  collection: [],
  subtype: null,
  type: 'Module',
  attributes: {
    pageModule: {
      uiModuleFields: {
        basicModule: {
          treatmentType: UiBasicTreatmentType.StandardNewsletter,
          contentTypes: [PublicationSettingSearchableContentType.Article],
          allowedTreatmentTypes: null
        }
      },
      // @ts-ignore
      uiModuleType: 'UiBasicModuleType'
    }
  }
};

export const DEBOUNCE_TIMEOUT = 500;
