import { List, Loader, Typography } from '@screentone/core';

import { useItp } from 'contexts/itp/useItp';
import { useIsAdmin } from 'hooks/roles';
import env from 'utils/env';

export const DropdownContent = () => {
  const isAdmin = useIsAdmin();
  const { isPreviewLoading, previewIds } = useItp();

  switch (true) {
    case previewIds.length && isAdmin:
      return (
        <>
          <Typography variant="header4" margin={{ left: 'sm' }}>
            Preview collection IDs:
          </Typography>
          {previewIds.map((previewId) => (
            <List.Item key={previewId} padding={{ all: 'sm' }}>
              <Typography variant="link" href={`${env.ALLESSEH_PREVIEW_URL}${previewId}`} target="_blank">
                {previewId}
              </Typography>
            </List.Item>
          ))}
        </>
      );

    case previewIds.length && !isAdmin:
      return <Typography style={{ width: '150px' }}>Please check the Proofing App to see the preview.</Typography>;

    // is this a real case??
    case !previewIds.length && isPreviewLoading:
      return <Loader size="md" />;

    default:
      return <Typography>No preview available</Typography>;
  }
};
