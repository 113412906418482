import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ResponsiveLoader } from 'components';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { DataModelProvider, DataModelType } from 'contexts/datamodel/DataModelContext';
import { DragAndDropProvider } from 'contexts/drag-and-drop/DragAndDropContext';
import { HighlightProvider } from 'contexts/highlight/HighlightContext';
import { DISPLAY_KEY, ItpProvider } from 'contexts/itp/ItpContext';
import { PageTypeSettingsProvider } from 'contexts/page-type-settings/PageTypeSettingsContext';
import { AltSummVariant, SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { BaseMetadata, ItpDto, PageType, useItpDtoByDateQuery } from 'data/generated/graphql';
import { ItpEdit } from 'features/itp/ItpEdit';
import { useConvertedProperty } from 'hooks';

const ItpEditPage = () => {
  const [hasItpDTOChanged, setHasItpDTOChanged] = useState(false); // used to disable/enable publishing buttons
  const [issue, setIssue] = useState<ItpDto | null>(null);
  const [error, setError] = useState<number | null>(null);

  const currentProperty = useConvertedProperty();
  const { issueDate } = useParams();

  const {
    isLoading,
    data,
    error: queryError,
    fetchStatus
  } = useItpDtoByDateQuery(
    {
      issueDate: issueDate!,
      publicationKey: currentProperty!
    },
    { enabled: !!issueDate && !!currentProperty }
  );

  useEffect(() => {
    if (!isLoading && fetchStatus === 'idle') {
      if (queryError || !data?.itpDTOByDate) {
        if (!error) {
          const err = queryError as { message?: string };
          const errorMessage = err.message ?? JSON.stringify(err);

          if (errorMessage.includes('404')) {
            setError(404);
          } else {
            setError(500);
          }
        }
      } else if (!issue) {
        const { itpDTOByDate } = data;

        itpDTOByDate.root.collection.sort((a, b) => {
          const aOrder = a.attributes.sectionKey ? DISPLAY_KEY[a.attributes.sectionKey].order : -1;
          const bOrder = b.attributes.sectionKey ? DISPLAY_KEY[b.attributes.sectionKey].order : -1;
          return aOrder - bOrder;
        });

        setIssue(itpDTOByDate as ItpDto);
      }
    }
  }, [error, isLoading, issue, fetchStatus, data, queryError]);

  if (error) {
    if (error === 404) {
      return <NoSearchResults title="Issue" bodyText="No issue is available for the date requested." />;
    }

    return <NoSearchResults title="Issue" bodyText="An error has occurred while trying to load ITP issue." />;
  }

  // url path params are invalid
  if (!issueDate || !currentProperty || currentProperty !== 'wsj') {
    return (
      <NoSearchResults
        title="ITP Issues"
        bodyText="This is a WSJ feature that is unavailable for other publications."
      />
    );
  }

  // don't render `children` until after `issue` is finished loading
  if (!issue || isLoading) return <ResponsiveLoader />;

  const { pageType } = issue.root.collection[0].attributes;

  return (
    <SummarianProvider initialAltSummVariant={pageType as AltSummVariant}>
      <TrashProvider>
        <DataModelProvider
          metadata={issue.metadata as BaseMetadata}
          root={issue.root}
          setHasItpDTOChanged={setHasItpDTOChanged}
          type={DataModelType.ItpDTO}
        >
          <ItpProvider hasItpDTOChanged={hasItpDTOChanged} setHasItpDTOChanged={setHasItpDTOChanged}>
            <DragAndDropProvider>
              <HighlightProvider>
                <PageTypeSettingsProvider pageType={pageType as PageType}>
                  <ItpEdit />
                </PageTypeSettingsProvider>
              </HighlightProvider>
            </DragAndDropProvider>
          </ItpProvider>
        </DataModelProvider>
      </TrashProvider>
    </SummarianProvider>
  );
};

export default ItpEditPage;
