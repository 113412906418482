import { useContext } from 'react';

import { HighlightContext, HighlightContextType } from './HighlightContext';

export const useHighlight = (): HighlightContextType => {
  const context = useContext(HighlightContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error('The `useHighlight` hook must be used within the context of the `HighlightContextProvider`!');
  }

  return context;
};
