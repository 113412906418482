import { useNavigate } from 'react-router-dom';
import { Box, Token, Typography } from '@screentone/core';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import { ResponsiveLoader } from 'components';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import {
  GetNewslettersQuery,
  GetScheduledContentByPublicationKeyQuery,
  NewsletterDto,
  PublicationSetting
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { formatInTimeZone } from 'utils/dates';
import styles from '../../NewsletterEdit.module.scss';

type NewslettersListProps = {
  newsletters?: GetNewslettersQuery[];
  scheduledCampaigns?: GetScheduledContentByPublicationKeyQuery[];
  publicationSetting: PublicationSetting | undefined;
  nextPageProps: {
    fetchNextPage: (
      options?: FetchNextPageOptions | undefined
    ) =>
      | Promise<InfiniteQueryObserverResult<GetNewslettersQuery>>
      | Promise<InfiniteQueryObserverResult<GetScheduledContentByPublicationKeyQuery>>;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
};

export const NewslettersList = ({
  newsletters,
  publicationSetting,
  nextPageProps,
  scheduledCampaigns
}: NewslettersListProps) => {
  const isSchedule: boolean = !newsletters;

  const campaigns: NewsletterDto[] = newsletters
    ? newsletters.reduce((acc: NewsletterDto[], curr: GetNewslettersQuery) => {
        const campaignsToAdd = curr.getNewsletters.edges.map((newsletterEdge) => {
          const { node: newsletter } = newsletterEdge;
          return newsletter;
        });
        return acc.concat(campaignsToAdd as NewsletterDto[]);
      }, [])
    : (scheduledCampaigns ?? []).reduce((acc: NewsletterDto[], curr: GetScheduledContentByPublicationKeyQuery) => {
        const campaignsToAdd = curr.scheduledContentByPublicationKey.edges.map((newsletterEdge) => {
          const {
            node: { body }
          } = newsletterEdge;
          return body;
        });
        return acc.concat(campaignsToAdd as NewsletterDto[]);
      }, []);

  const navigate = useNavigate();
  const currentProperty = useConvertedProperty();

  const handleCampaignClick = (campaignId: string) => {
    navigate(`/${currentProperty}/newsletters/${campaignId}`);
  };

  const handleScheduledCampaignClick = (campaignId: string, publishUtc: string) => {
    navigate(`/${currentProperty}/newsletters/scheduled/${campaignId}/${publishUtc}`);
  };

  if (campaigns.length < 1) {
    return <NoSearchResults title="Campaigns" bodyText="Try creating a new one." />;
  }

  return (
    <div data-testid="newsletter-list">
      {campaigns.map((campaign: NewsletterDto) => (
        <Box
          data-testid="newsletter-content"
          className={styles.newsletterListBox}
          padding={{ all: 'md' }}
          margin={{ top: 'sm', bottom: 'sm' }}
          key={isSchedule ? campaign.metadata.allessehId : campaign.metadata.allessehId + campaign.metadata.publishUtc}
          onClick={() =>
            isSchedule
              ? handleScheduledCampaignClick(campaign.metadata.allessehId, `${campaign.metadata.publishUtc}`)
              : handleCampaignClick(campaign.metadata.allessehId)
          }
        >
          <div className={styles.newsletterListText}>
            <Typography inline weight="med" data-testid="newsletters-name">
              {campaign.metadata.name}
            </Typography>
            <div data-testid="newsletters-timezone">
              {!isSchedule && (
                <>
                  <Typography inline variant="note">
                    {formatInTimeZone(
                      campaign.metadata.updatedUtc ?? 0,
                      publicationSetting?.preferredTimezone,
                      'MMMM dd, yyyy',
                      {
                        showTimezoneAbbreviation: false
                      }
                    )}{' '}
                    at{' '}
                    {formatInTimeZone(
                      campaign.metadata.updatedUtc ?? 0,
                      publicationSetting?.preferredTimezone,
                      'h:mm aaaa',
                      {
                        showTimezoneAbbreviation: true
                      }
                    )}
                  </Typography>
                  <Token color="emerald" margin={{ left: 'sm' }} data-testid="newsletter-sent-status">
                    Sent
                  </Token>
                </>
              )}

              {campaign.metadata.publishUtc && (
                <>
                  <Typography inline variant="note">
                    {formatInTimeZone(
                      campaign.metadata.publishUtc,
                      publicationSetting?.preferredTimezone,
                      'MMMM dd, yyyy',
                      {
                        showTimezoneAbbreviation: false
                      }
                    )}{' '}
                    at{' '}
                    {formatInTimeZone(
                      campaign.metadata.publishUtc,
                      publicationSetting?.preferredTimezone,
                      'h:mm aaaa',
                      {
                        showTimezoneAbbreviation: true
                      }
                    )}
                  </Typography>
                  <Token data-testid="newsletter-scheduled-status" color="blurple" margin={{ left: 'sm' }}>
                    Scheduled
                  </Token>
                </>
              )}
            </div>
          </div>
          <Typography size="md" margin={{ top: 'sm' }} data-testid="newsletter-list-headline">
            {campaign.root.attributes.subject}
          </Typography>
        </Box>
      ))}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
      {nextPageProps.isFetchingNextPage && <ResponsiveLoader />}
    </div>
  );
};
