import { createRef, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, IconEdit, IconExternalLink, IconTrash, Input, List, Wrapper } from '@screentone/core';

import PortalDropdown from 'components/portal-dropdown/PortalDropdown';
import { SnippetyResponse, useSnippetyQuery } from 'hooks/useSnippetyQuery';
import { decodeHtmlEntities } from 'utils/text';
import { formatBulletLink, isValidURL } from 'utils/url';
import styles from './BulletInput.module.scss';

interface BulletInputProps {
  currentBullets: string[];
  displayNewBulletInput: boolean;
  onDisplayNewBulletInput: (displayNewBulletInput: boolean) => void;
  onExecuteUpdate: (bullets: string[]) => void;
}

export const reorderBullets = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SCRIPT_TAG_REGEX = /<a[^>]*href=["']([^"']*)["']/g;

export const BulletInput = ({
  currentBullets,
  onExecuteUpdate,
  displayNewBulletInput,
  onDisplayNewBulletInput
}: BulletInputProps) => {
  const [controlledBullets, setControlledBullets] = useState<string[]>(() => currentBullets);

  const [newBullet, setNewBullet] = useState('');
  const [editInputIndex, setEditInputIndex] = useState<number | undefined>(undefined);
  const { refetch: refetchSnippetyData, fetchStatus } = useSnippetyQuery(newBullet, { enabled: false });

  const bulletRefs = useMemo(
    () => Array.from({ length: controlledBullets.length }).map(() => createRef<HTMLTextAreaElement>()),
    [controlledBullets.length]
  );

  const isFetchingFromSnippety = fetchStatus === 'fetching';

  const handleNewBulletInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBullet(e.target.value);
  };

  const handleNewBulletEnter = async (e: React.KeyboardEvent) => {
    const { key, type } = e;

    const executeUpdate = (bulletLink: string) => {
      setControlledBullets([...controlledBullets, bulletLink]);
      onExecuteUpdate([...controlledBullets, bulletLink]);
      setNewBullet('');
      onDisplayNewBulletInput(false);
    };

    if (key !== 'Enter' && type !== 'blur') {
      return;
    }

    if (!isValidURL(newBullet)) {
      executeUpdate(formatBulletLink(newBullet));
      return;
    }

    try {
      const fetchedArticle = await refetchSnippetyData();

      const dataFromSnippety = fetchedArticle.data as SnippetyResponse | undefined;

      if (!dataFromSnippety) {
        executeUpdate(formatBulletLink(newBullet));

        return;
      }

      executeUpdate(formatBulletLink(newBullet, dataFromSnippety.title));
    } catch (error) {
      console.error('Error fetching article data:', error);
    }
  };

  useEffect(() => {
    setControlledBullets(() => [...currentBullets]);
  }, [currentBullets]);

  /**
   * Updates the bullet style to match the height of the content
   */
  useEffect(() => {
    if (bulletRefs.length > 0) {
      bulletRefs.forEach((_bulletRefItem, bulletIndex) => {
        if (bulletRefs[bulletIndex].current) {
          bulletRefs[bulletIndex].current!.style.height = '0';
          bulletRefs[bulletIndex].current!.style.height = `${bulletRefs[bulletIndex].current!.scrollHeight}px`;
        }
      });
    }
  }, [bulletRefs]);

  const handleRemoveBullet = (index: number) => {
    const newBullets = [...controlledBullets];
    newBullets.splice(index, 1);

    setControlledBullets(newBullets);
    onExecuteUpdate(newBullets);
  };

  const handleEditBulletIndex = (index: number) => {
    setEditInputIndex(index);
  };

  const handleEditBulletInput = (
    e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>,
    index: number,
    bulletHeadline?: boolean
  ) => {
    if (!bulletHeadline) {
      const newBullets = [...controlledBullets];

      const headline = newBullets[index].split('">')[1].slice(0, -4);

      if (headline) {
        newBullets[index] = formatBulletLink(e.target.value, decodeHtmlEntities(headline));
      }

      setControlledBullets(newBullets);

      return;
    }

    if (bulletRefs[index]) {
      bulletRefs[index].current!.style.height = '0';
      bulletRefs[index].current!.style.height = `${bulletRefs[index].current!.scrollHeight}px`;
    }

    const newBullets = [...controlledBullets];
    const bulletLinkRegex = SCRIPT_TAG_REGEX.exec(newBullets[index]);
    const bulletLink = newBullets[index].split('">')[0].slice(9);

    if (bulletLinkRegex) {
      newBullets[index] = formatBulletLink(bulletLinkRegex[1], e.target.value);
      setControlledBullets(newBullets);
      return;
    }

    newBullets[index] = formatBulletLink(bulletLink, e.target.value);
    setControlledBullets(newBullets);
  };

  const handleEditBulletEnter = (e: React.KeyboardEvent) => {
    const { key, type } = e;

    if (key === 'Enter' || type === 'blur') {
      setEditInputIndex(undefined);
      onExecuteUpdate(controlledBullets);
    }
  };

  const handleViewBulletLink = (bulletLink: string) => {
    window.open(bulletLink, '_blank');
  };

  const handleDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
    if (!result.destination) {
      return;
    }

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }
    const items = reorderBullets(controlledBullets, result.source.index, result.destination.index);
    onExecuteUpdate(items);
    setControlledBullets(items);

    // Keep focus on the dragged bullet
    // if (document.activeElement === bulletRefs[result.source.index].current) {
    //   bulletRefs[result.destination.index].current!.focus();

    //   return;
    // }

    // if (document.activeElement === bulletRefs[result.destination.index].current) {
    //   bulletRefs[result.source.index].current!.focus();

    //   return;
    // }

    if (typeof editInputIndex !== 'number') {
      return;
    }

    // Maintain the current edit input when dropping to new location
    if (result.destination.index === editInputIndex && result.destination.index < result.source.index) {
      handleEditBulletIndex(result.destination.index + 1);

      return;
    }

    // Maintain the current edit input when dropping to new location
    if (result.destination.index === editInputIndex && result.destination.index > result.source.index) {
      handleEditBulletIndex(result.destination.index - 1);

      return;
    }

    // Maintain the current edit input when dropping to new location
    if (editInputIndex === result.source.index) {
      handleEditBulletIndex(result.destination.index);

      return;
    }

    // Maintain the current edit input when dropping to new location
    if (editInputIndex === result.destination.index) {
      handleEditBulletIndex(result.source.index);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="bullets-droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <List listStyle="bullet">
              {controlledBullets.map((bullet: string, index: number) => {
                if (!bullet) return null;
                const link = bullet.split('">')[0].slice(9);
                const headline = bullet.split('">')[1].slice(0, -4);
                const bulletString = decodeHtmlEntities(headline);
                return (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided) => (
                      <li
                        className={`st_listitem st_listitem--bullet st_m-ver-sm ${styles.bulletItemDragHandle}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Wrapper className={styles.bulletItemWrapper}>
                          <Wrapper className={styles.bulletGroup}>
                            {editInputIndex === index && (
                              <Input
                                data-testid="edit-bullet-input"
                                tabIndex={0}
                                value={link}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  handleEditBulletInput(e, index);
                                }}
                                onKeyDown={handleEditBulletEnter}
                                onBlur={handleEditBulletEnter}
                              />
                            )}
                            <textarea
                              hidden={editInputIndex === index && !headline.length}
                              value={bulletString}
                              placeholder="Add article link"
                              className={styles.bulletItem}
                              ref={bulletRefs[index]}
                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                handleEditBulletInput(e, index, true);
                              }}
                              onBlur={() => {
                                onExecuteUpdate(controlledBullets);
                              }}
                            />
                          </Wrapper>
                          <PortalDropdown>
                            <Button
                              onMouseDown={() => {
                                handleViewBulletLink(link);
                              }}
                              margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                              tertiary
                              icon={IconExternalLink as SvgComponent}
                            >
                              Open Link
                            </Button>
                            <Button
                              onMouseDown={() => {
                                handleEditBulletIndex(index);
                              }}
                              margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                              tertiary
                              icon={IconEdit as SvgComponent}
                            >
                              Edit Link
                            </Button>
                            <Button
                              onMouseDown={() => {
                                handleRemoveBullet(index);
                              }}
                              margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                              tertiary
                              color="lava"
                              icon={IconTrash as SvgComponent}
                            >
                              Remove Bullet
                            </Button>
                          </PortalDropdown>
                        </Wrapper>
                      </li>
                    )}
                  </Draggable>
                );
              })}
            </List>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {displayNewBulletInput && (
        <List listStyle="bullet">
          <List.Item>
            <Input
              data-testid="edit-bullet-input"
              value={newBullet}
              onChange={handleNewBulletInput}
              onKeyDown={handleNewBulletEnter}
              onBlur={handleNewBulletEnter}
              disabled={isFetchingFromSnippety}
              placeholder="Add article URL …"
            />
            <PortalDropdown>
              <Button
                margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                tertiary
                icon={IconTrash as SvgComponent}
                color="lava"
                onMouseDown={() => {
                  setNewBullet('');
                  onDisplayNewBulletInput(false);
                }}
              >
                Remove Bullet
              </Button>
            </PortalDropdown>
          </List.Item>
        </List>
      )}
    </DragDropContext>
  );
};
