import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, Input, Loader, Textarea, Typography, Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { PagesDtoForCollectionIdQuery } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { useAuraQuery } from 'hooks/useAuraQuery';
import styles from './CollectionInfoBoxesSection.module.scss';
import collectionEditStyles from '../../CollectionEdit.module.scss';
import { TOffPlatformInput } from '../collection-off-platform-overlay/CollectionOffPlatformOverlay';
import { CollectionPublishingButtons } from '../collection-publishing-buttons/CollectionPublishingButtons';

interface CollectionInfoBoxesSectionProps {
  pagesForCollection?: PagesDtoForCollectionIdQuery;
  pagesAreLoading: boolean;
  hasCollectionChanged: boolean;
  isUpdating: boolean;
  handleCopy: () => Promise<void>;
  isCopying: boolean;
  handleArchive: () => Promise<void>;
  isArchiving: boolean;
  handleOffPlatformConvert: (input: TOffPlatformInput) => Promise<void>;
  isConvertingToOffPlatform: boolean;
  waitingForAllesseh: boolean;
}

export const CollectionInfoBoxesSection = ({
  pagesForCollection,
  pagesAreLoading,
  hasCollectionChanged,
  isUpdating,
  handleCopy,
  isCopying,
  handleArchive,
  isArchiving,
  handleOffPlatformConvert,
  isConvertingToOffPlatform,
  waitingForAllesseh
}: CollectionInfoBoxesSectionProps) => {
  const navigate = useNavigate();
  const { currentProperty } = useAuth();
  const { metadata, setNewMetadata } = useDataModelContext();
  const { data: publicationSettings } = usePublicationSettings();
  const { data: creatorUserData, isLoading: isFetchingCreatorUserData } = useAuraQuery(metadata.creatorUser.djUserId);
  const creatorUserText = creatorUserData
    ? `${creatorUserData.first_name ?? ''} ${creatorUserData.last_name ?? ''}`
    : metadata.creatorUser.djUserId;
  const { data: revisorUserData, isLoading: isFetchingRevisorUserData } = useAuraQuery(
    metadata.revisorUser?.djUserId ?? ''
  );
  const revisorUserText = revisorUserData
    ? `${revisorUserData.first_name ?? ''} ${revisorUserData.last_name ?? ''}`
    : metadata.revisorUser?.djUserId;

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setNewMetadata({ name: e.target.value });
  };

  const handleNotesChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setNewMetadata({ notes: e.target.value });
  };

  return (
    <div className={collectionEditStyles.infoScrollable} data-testid="collection-info-box-section-container">
      <Box data-testid="collection-info-box-section-conf">
        <Box.Title data-testid="collection-info-box-section-conf-title">Configuration</Box.Title>
        <Box.Content data-testid="collection-info-box-section-conf-content">
          <Wrapper data-testid="collection-info-box-section-conf-name" margin={{ bottom: 'md' }}>
            <Typography
              data-testid="collection-info-box-section-conf-name-label"
              size="sm"
              weight="bold"
              margin={{ bottom: 'sm' }}
            >
              Name
            </Typography>
            <Input
              data-testid="collection-info-box-section-conf-name-input"
              type="text"
              placeholder="Name of collection"
              value={metadata.name}
              onChange={handleNameChange}
            />
          </Wrapper>
          <Wrapper data-testid="collection-info-box-section-conf-notes" margin={{ bottom: 'md' }}>
            <Typography
              data-testid="collection-info-box-section-conf-notes-label"
              size="sm"
              weight="bold"
              margin={{ bottom: 'sm' }}
            >
              Notes
            </Typography>
            <Textarea
              data-testid="collection-info-box-section-conf-notal-textbox"
              value={('notes' in metadata && metadata.notes) ?? ''}
              onChange={handleNotesChange}
            />
          </Wrapper>
          <Wrapper data-testid="collection-info-box-section-conf-id">
            <Typography
              data-testid="collection-info-box-section-conf-id-label"
              size="sm"
              weight="bold"
              margin={{ bottom: 'sm' }}
            >
              Collection ID
            </Typography>
            <Input
              data-testid="collection-info-box-section-conf-id-input"
              type="text"
              placeholder="ID of collection"
              disabled
              value={metadata.allessehId || ''}
              readOnly
            />
          </Wrapper>
        </Box.Content>
      </Box>
      {!!metadata.allessehId && (
        <>
          <Box data-testid="collection-info-box-section-edit" margin={{ top: 'md' }}>
            <Box.Title data-testid="collection-info-box-section-edit-title">Editors</Box.Title>
            <Box.Content data-testid="collection-info-box-section-edit-content">
              <Wrapper data-testid="collection-info-box-section-edit-update" className={styles.editorItem}>
                <Typography data-testid="collection-info-box-section-edit-update-user">
                  {isFetchingRevisorUserData ? <Loader size="md" /> : revisorUserText}
                </Typography>
                <Typography
                  data-testid="collection-info-box-section-edit-update-date"
                  variant="note"
                  margin={{ left: 'sm' }}
                >
                  Last Updated By
                </Typography>
              </Wrapper>
              <Wrapper
                data-testid="collection-info-box-section-edit-creator"
                className={styles.editorItem}
                margin={{ top: 'sm' }}
              >
                <Typography data-testid="collection-info-box-section-edit-creator-user">
                  {isFetchingCreatorUserData ? <Loader size="md" /> : creatorUserText}
                </Typography>
                <Typography
                  data-testid="collection-info-box-section-edit-creator-label"
                  variant="note"
                  margin={{ left: 'sm' }}
                >
                  Creator
                </Typography>
              </Wrapper>
            </Box.Content>
          </Box>
          <Box data-testid="collection-info-box-section-pages" margin={{ top: 'md' }}>
            <Box.Title data-testid="collection-info-box-section-pages-title">Pages</Box.Title>
            <Box.Content data-testid="collection-info-box-section-pages-content">
              {pagesAreLoading && <ResponsiveLoader />}
              {!pagesAreLoading && (
                <>
                  {pagesForCollection?.pagesDTOForCollectionId.length === 0 ? (
                    <Typography data-testid="collection-info-box-section-pages-none-label" variant="note">
                      No pages linked
                    </Typography>
                  ) : (
                    pagesForCollection?.pagesDTOForCollectionId.map((pageForCollection, index) => {
                      const {
                        metadata: { allessehId, publishUtc }
                      } = pageForCollection.latestPage;
                      const slugMappings = publicationSettings?.publicationSetting.slugMappings;
                      const [slug] =
                        Object.entries(slugMappings ?? {}).find(([, groupKey]) => groupKey === allessehId) ?? [];
                      const pageId = slug ?? allessehId;
                      const linkUrl = `/${currentProperty ?? ''}/pages/${pageId}/version/${publishUtc!}`;
                      return (
                        <Button
                          data-testid="collection-info-box-section-pages-page-button"
                          className={styles.pageButton}
                          margin={{ bottom: 'xs' }}
                          key={index}
                          tertiary
                          fullWidth
                          onClick={() => navigate(linkUrl)}
                        >
                          {pageForCollection.latestPage.metadata.name} ({pageForCollection.numRevisions})
                        </Button>
                      );
                    })
                  )}
                </>
              )}
            </Box.Content>
          </Box>
          <CollectionPublishingButtons
            hasCollectionChanged={hasCollectionChanged}
            pagesForCollection={pagesForCollection}
            isUpdating={isUpdating}
            handleCopy={handleCopy}
            isCopying={isCopying}
            handleArchive={handleArchive}
            isArchiving={isArchiving}
            handleOffPlatformConvert={handleOffPlatformConvert}
            isConvertingToOffPlatform={isConvertingToOffPlatform}
            waitingForAllesseh={waitingForAllesseh}
          />
        </>
      )}
    </div>
  );
};
