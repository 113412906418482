import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader, WidePageWrapper } from 'components';
import { AddEntity } from 'components/context-menu-actions/add-entity/AddEntity';
import { AddQuery } from 'components/context-menu-actions/add-query/AddQuery';
import { CopyPublishedURL } from 'components/context-menu-actions/copy-published-url/CopyPublishedURL';
import { EditAltSumm } from 'components/context-menu-actions/edit-alt-summ/EditAltSumm';
import { EditQuery } from 'components/context-menu-actions/edit-query/EditQuery';
import { ExcludeFromQuery } from 'components/context-menu-actions/exclude-from-query/ExcludeFromQuery';
import ExternalLink, { ExternalLinkContentType } from 'components/context-menu-actions/external-link/ExternalLink';
import { OpenInNewsGrid } from 'components/context-menu-actions/open-in-newsgrid/OpenInNewsGrid';
import { OpenInNewsPress } from 'components/context-menu-actions/open-in-newspress/OpenInNewsPress';
import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { DataModelProvider, DataModelType } from 'contexts/datamodel/DataModelContext';
import { DragAndDropProvider } from 'contexts/drag-and-drop/DragAndDropContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import {
  ArticleItem,
  OffPlatformDto,
  OffPlatformListContainer,
  QueryItem,
  useOffPlatformByIdQuery
} from 'data/generated/graphql';
import { OffPlatformEdit } from 'features/off-platform-edit/OffPlatformEdit';
import { useConvertedProperty } from 'hooks';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';

const contextActions: MenuActions = {
  [MenuActionsKeys.DraftArticle]: [
    {
      component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from collection" />,
      withDivider: 'bottom'
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    }
  ],
  [MenuActionsKeys.HistoryArticle]: [
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    }
  ],
  [MenuActionsKeys.ArticleSearch]: [
    {
      component: ({ hierarchyId, entity, extraProperties }) => (
        <AddEntity
          entity={entity!}
          hierarchyId={hierarchyId!}
          jsonQueryStr={extraProperties!.jsonQueryStr!}
          allessehContent={extraProperties!.allessehContent!}
          setJsonQueryStr={extraProperties!.setJsonQueryStr!}
        />
      ),
      withDivider: 'bottom'
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        const content = safelyParseContent<AllessehContent>(article.content);

        return (
          <ExternalLink
            contentId={content.data.id}
            isPublished={!!content.data.attributes.published_datetime}
            type={ExternalLinkContentType.ARTICLE}
          />
        );
      }
    }
  ],
  [MenuActionsKeys.DraftModule]: [
    {
      component: ({ entity, extraProperties }) => {
        const collectionLength = (entity as OffPlatformListContainer).collection.length;

        return (
          <AddQuery
            hierarchyId={`0--${collectionLength}`}
            maxAllowedItems={extraProperties!.maxAllowedItems!}
            numTotalItemsUsed={extraProperties!.numTotalItemsUsed!}
          />
        );
      }
    }
  ],
  [MenuActionsKeys.DraftQuery]: [
    {
      component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from module" />,
      withDivider: 'bottom'
    },
    {
      component: ({ hierarchyId, entity, extraProperties }) => {
        const query = entity as QueryItem;
        return (
          <EditQuery
            hierarchyId={hierarchyId!}
            query={query}
            numItemsToShow={extraProperties!.numItemsToShow!}
            baseQuery={extraProperties!.baseQuery!}
          />
        );
      }
    }
  ],
  [MenuActionsKeys.HistoryQuery]: [
    {
      component: ({ hierarchyId, entity, extraProperties }) => {
        const query = entity as QueryItem;
        return (
          <EditQuery
            hierarchyId={hierarchyId!}
            query={query}
            numItemsToShow={extraProperties!.numItemsToShow!}
            baseQuery={extraProperties!.baseQuery!}
            viewOnly
          />
        );
      }
    }
  ],
  [MenuActionsKeys.ArticleQueryModal]: [
    {
      component: ({ extraProperties }) => (
        <ExcludeFromQuery
          jsonQueryStr={extraProperties!.jsonQueryStr!}
          allessehContent={extraProperties!.allessehContent!}
          setJsonQueryStr={extraProperties!.setJsonQueryStr!}
        />
      ),
      showIf: ({ isHistory }) => !isHistory,
      withDivider: 'bottom'
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    },
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    }
  ]
};

const getCollectionIdAndUtcNum = (idCreatedUtc?: string) => {
  if (!idCreatedUtc) {
    return { id: '', createdUtcNum: 0 };
  }
  const regex = /(\d{13})$/;
  const timeStamp = idCreatedUtc.match(regex);
  if (!timeStamp) {
    return { id: idCreatedUtc, createdUtcNum: 0 };
  }

  const createdUtcNum = parseInt(timeStamp[0], 10);
  const id = idCreatedUtc.split('_').slice(0, -1).join('_');

  return { id, createdUtcNum };
};

const OffPlatformEditPage = () => {
  const [offPlatformData, setOffPlatformData] = useState<OffPlatformDto | null>(null);

  const { idCreatedUtc } = useParams();

  const { id } = getCollectionIdAndUtcNum(idCreatedUtc);
  const currentProperty = useConvertedProperty();

  const { data, isLoading, fetchStatus, refetch } = useOffPlatformByIdQuery(
    { id, publicationKey: currentProperty ?? '' },
    { enabled: !!id }
  );
  const isActiveLoading = isLoading && fetchStatus !== 'idle';

  const handleOnUpdate = useCallback(() => {
    if (!isActiveLoading) {
      void refetch();
    }
  }, [isActiveLoading, refetch]);

  useEffect(() => {
    if (!isActiveLoading && data) {
      setOffPlatformData(data.offPlatformById as OffPlatformDto);
    }
  }, [isActiveLoading, data, offPlatformData]);

  useEffect(() => {
    handleOnUpdate();
  }, [handleOnUpdate]);

  if (isActiveLoading) {
    return (
      <PageRootContainer>
        <WidePageWrapper padding={{ all: 'md' }}>
          <ResponsiveLoader />
        </WidePageWrapper>
      </PageRootContainer>
    );
  }

  if (!offPlatformData) {
    return <ErrorPage type="404" />;
  }

  return (
    <SummarianProvider>
      <DataModelProvider
        type={DataModelType.OffPlatformDTO}
        root={offPlatformData.root}
        metadata={offPlatformData.metadata}
      >
        <TrashProvider>
          <DragAndDropProvider>
            <PageRootContainer>
              <WidePageWrapper padding={{ all: 'md' }}>
                <ContextMenuActionsProvider actions={contextActions}>
                  <OffPlatformEdit onUpdate={handleOnUpdate} />
                </ContextMenuActionsProvider>
              </WidePageWrapper>
            </PageRootContainer>
          </DragAndDropProvider>
        </TrashProvider>
      </DataModelProvider>
    </SummarianProvider>
  );
};

export default OffPlatformEditPage;
