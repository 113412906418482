/* eslint-disable import/no-cycle */
import { Droppable } from 'react-beautiful-dnd';
import { Wrapper } from '@screentone/core';
import classNames from 'classnames';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES, DROPPABLE_TYPES } from 'contexts/drag-and-drop/dragUtils';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { ItpDto, PageContainer } from 'data/generated/graphql';
import { AddITPPageButton } from 'features/section-page-edit/components/add-itp-page-button/AddITPPageButton';
import styles from './IssuePage.module.scss';

interface IssuePageProps extends CommonEntityProps {
  data: PageContainer;
  isFirst: boolean;
  isLast: boolean;
  sectionIndex?: number;
}

export const IssuePage = ({
  data: page,
  parentHierarchyId,
  index,
  isFirst,
  isLast,
  isHistory,
  sectionIndex
}: IssuePageProps) => {
  const { generateHierarchyId, renderEntity, root } = useDataModelContext();
  const { pageTypeSetting, addableUiModules } = usePageTypeSettingsContext();

  const hierarchyId = generateHierarchyId(page, parentHierarchyId, index);
  const currentLayout = pageTypeSetting?.layouts.find((l) => l.id === page.attributes.layoutId);

  const childrenElements = page.collection.map((collection, i) => (
    <div key={i}>
      {renderEntity(collection, {
        hierarchyId,
        index: i,
        isHistory,
        extraProps: {
          module: {
            layout: currentLayout
          }
        }
      })}
    </div>
  ));

  // `sectionIndex` ignores hidden values, check if `"Page One"` is not hidden, then check if the
  // current `sectionIndex` is `0` (`"Page One"` should always be the 0th index for ITP).
  const itpRoot = root as ItpDto['root'];
  const isITPPageOne = !itpRoot.collection[0].attributes.isHidden && sectionIndex === 0;

  return (
    <Wrapper
      padding={{ top: 'sm', right: 'md', bottom: isLast ? 'md' : 'none', left: 'md' }}
      className={styles.pageWrapper}
      data-model-hierarchy-id={hierarchyId}
    >
      <Wrapper
        className={classNames(
          styles.pageBackground,
          { [styles.roundBorderStart]: isFirst },
          { [styles.roundBorderEnd]: isLast }
        )}
      />

      {!isHistory ? (
        /* Wrap children in Droppable only for draft section */
        <Droppable droppableId={DROPPABLE_ID_PREFIXES.MODULES + hierarchyId} type={DROPPABLE_TYPES.MODULE}>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              data-testid="page-draft-modules"
            >
              {childrenElements}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        childrenElements
      )}

      {index !== undefined && !isITPPageOne && (
        <AddITPPageButton
          availableTreatmentTypes={currentLayout?.availableTreatmentTypes as string[]}
          addableUiModules={addableUiModules}
          nextPageIndex={index + 1}
          sectionIndex={sectionIndex}
        />
      )}
    </Wrapper>
  );
};
